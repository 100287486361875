export default {
  api_server: "/api",
  localStoragePrefix: "gpdq_portal__",
  postcoder_api_key: "PCWKN-SRZBC-9ZXD9-JL4SE",
  postcoder_identifier: "Patient_Navigator",
  auth: {
    clientId: "6315b8d571595fa5c8a8f960",
    loginServer: "https://portal.gpdq.health",
    sessionTimeout: 15
  },
};
