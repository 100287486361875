import React, { Component } from "react";
import {
  Segment,
  Header,
  Grid,
  Table,
  Divider,
  Form,
  Input,
  Button,
  Modal,
  Message,
  Checkbox,
  Icon,
  Statistic,
  Menu,
  Select,
  Label,
  Confirm,
  Dropdown,
  Card,
  Loader,
  Radio,
  Image,
  TableHeaderCell,
} from "semantic-ui-react";
import { DateInput, DateTimeInput } from "semantic-ui-calendar-react";
import moment from "moment";
import api from "../../../actions/api";
import "react-phone-input-2/lib/semantic-ui.css";
import PaginateWrapper from "../common/OLD_PaginateWrapper";
import SystemLegalDocsList from "../common/lists/systemLegalDocsList";
import SettingsListAssessmentStatuses from "../common/lists/settingListAssessmentStatuses";
import { toast } from "react-semantic-toasts";
import { Link } from "react-router-dom";
import NoticeForm from "./forms/noticeForm";
import AddForm from "../settings/system_legal_docs/forms/add";
import { HexAlphaColorPicker, HexColorInput } from "react-colorful";

//Move to component
import { Editor } from "react-draft-wysiwyg";
import { convertToRaw, EditorState, ContentState, Modifier } from "draft-js";
import PropTypes from "prop-types";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import AppConfig from "../../../_appConfig";
import _appConfig from "../../../_appConfig";
import UploadFileForm from "../common/forms/uploadFile";
class ShortcodeDropdown extends Component {
  static propTypes = {
    onChange: PropTypes.func,
    editorState: PropTypes.object,
  };

  state = {
    open: false,
  };

  openPlaceholderDropdown = () => this.setState({ open: !this.state.open });

  addPlaceholder = (placeholder) => {
    const { editorState, onChange } = this.props;
    const contentState = Modifier.replaceText(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      placeholder,
      editorState.getCurrentInlineStyle()
    );
    onChange(EditorState.push(editorState, contentState, "insert-characters"));
  };

  placeholderOptions = [
    { key: "to", value: "{TO}", text: "To Email" },
    {
      key: "employee_firstname",
      value: "{EMP_FIRSTNAME}",
      text: "Employee FirstName",
    },
    {
      key: "employee_lastname",
      value: "{EMP_LASTNAME}",
      text: "Employee LastName",
    },
    { key: "employee_ID", value: "{EMP_ID}", text: "Employee ID" },
    { key: "url_portal", value: "{PORTAL_LINK}", text: "URL Portal" },
    { key: "url_app", value: "{APP_LINK}", text: "URL App" },
    { key: "username", value: "{USERNAME}", text: "Username" },
    { key: "password", value: "{PASSWORD}", text: "Password" },
    {
      key: "password_reset_link",
      value: "{PASSWORD_RESET_LINK}",
      text: "Password Reset Link",
    },
    { key: "2FA", value: "{2FA}", text: "2FA" },
    {
      key: "ref_name",
      value: "{REFERRAL_MAN_NAME}",
      text: "Referral Manager Name",
    },
    {
      key: "ref_email",
      value: "{REFERRAL_MAN_EMAIL}",
      text: "Referral Manager Email",
    },
    {
      key: "ca_email",
      value: "{COMPANY_ADMINS}",
      text: "Company Admins Name & Email",
    },
    // {key: "ca_name", value: "{COMPANY_ADMIN_NAME}", text: "Company Admin Name"},
    // {key: "ca_email", value: "{COMPANY_ADMIN_EMAIL}", text: "Company Admin Email"},
    {
      key: "ncm_name",
      value: "{NURSE_CASEMAN_NAME}",
      text: "Nurse Case Manager Name",
    },
    {
      key: "ncm_email",
      value: "{NURSE_CASEMAN_EMAIL}",
      text: "Nurse Case Manager Email",
    },
    { key: "company_name", value: "{COMPANY_NAME}", text: "Company Name" },
    { key: "c_number", value: "{CASE_NUMBER}", text: "Case Number" },
    { key: "r_number", value: "{REFERRAL_NUMBER}", text: "Referral Number" },
    {
      key: "case_note_type",
      value: "{CASE_NOTE_TYPE}",
      text: "Case Note Type",
    },
    // {key: "case_note_subject", value: "{CASE_NOTE_SUBJECT}", text: "Case Note Subject"},
    {
      key: "case_note_message",
      value: "{CASE_NOTE_MESSAGE}",
      text: "Case Note Message",
    },
    {
      key: "case_note_date",
      value: "{CASE_NOTE_DATE}",
      text: "Case Note Due Date",
    },
    {
      key: "case_note_files",
      value: "{CASE_NOTE_FILES}",
      text: "Num. of Case Note Files",
    },
    //ASSESSMENT
    {
      key: "clinician_decision",
      value: "{CLINICIAN_DECISION}",
      text: "Clinician Decision Selected",
    },
    {
      key: "clinician_decision_date",
      value: "{CLINICIAN_DECISION_DATE}",
      text: "Date of Clinician Decision ",
    },
    {
      key: "health_assessment_date",
      value: "{HEALTH_ASSESSMENT_DATE}",
      text: "Health Assessment Booking Date",
    },
    {
      key: "followup_consultation_date",
      value: "{FOLLOWUP_CONSULTATION_DATE}",
      text: "Follow Up Consultation Date",
    },
    {
      key: "assessment_test_date",
      value: "{ASSESSMENT_TEST_DATE}",
      text: "Date Test Result Returned",
    },
    {
      key: "clinician_decision_notes",
      value: "{CLINICIAN_DECISION_NOTES}",
      text: "Clinician Decision Notes",
    },
    {
      key: "clinician_name",
      value: "{CLINICIAN_NAME}",
      text: "Clinician Name",
    },
    {
      key: "health_assessment_clinician_name",
      value: "{HEALTH_ASSESSMENT_CLINICIAN_NAME}",
      text: "Health Assessment Clinician Name ",
    },
  ];

  listItem = this.placeholderOptions.map((item) => (
    <li
      onClick={this.addPlaceholder.bind(this, item.value)}
      key={item.key}
      className="rdw-dropdownoption-default placeholder-li"
    >
      {item.text}
    </li>
  ));

  render() {
    return (
      <div
        onClick={this.openPlaceholderDropdown}
        className="rdw-block-wrapper"
        aria-label="rdw-block-control"
      >
        <div
          className="rdw-dropdown-wrapper rdw-block-dropdown"
          aria-label="rdw-dropdown"
          style={{ minWidth: "250px" }}
        >
          <div className="rdw-dropdown-selectedtext" title="Shortcodes">
            <span>Shortcodes </span>
            <div
              className={`rdw-dropdown-caretto${
                this.state.open ? "close" : "open"
              }`}
            ></div>
          </div>
          <ul
            className={`rdw-dropdown-optionwrapper ${
              this.state.open ? "" : "placeholder-ul"
            }`}
          >
            {this.listItem}
          </ul>
        </div>
      </div>
    );
  }
}

export default class CompanySettings extends Component {
  state = {
    activeItem: "dash",
    style_menu: "global",
    step_add: { archived: false, locked: false },
    step_edit: { archived: false },
    automation_templates: {},
    editEmailTemplateModal: false,
    editEmailTemplate: {},
    new_template_name: "",
    data: {},
    branding_data: {},
    styling_data: {},
    notice_data: {},
    splash_data: {},
    style_data: {},
    resource_cat_data: {},
    resource_data: {},
    company: {
      assessmentStatuses: [],
      emails: [],
      automations: [],
      sendEmailEmp: true,
      name: "",
      website: "",
      email: "",
      telephone_number: "",
      company_info: "",
      country: "United Kingdom",
      company_address: {
        address: "",
        addressline1: "",
        addressline2: "",
        street: "",
        posttown: "",
        county: "",
        postcode: "",
      },
      contact_types: {
        phone: false,
        email: true,
        post: false,
      },
      pirkx: {
        connectionId: "",
      },
      archived: false,
    },
    errors: {},
    show_archived: false,
    show_archived_emails: false,
    show_archived_forms: false,
    show_archived_steps: false,
    show_archived_res_cats: false,
    show_archived_res: false,
    show_archived_hn: false,
    show_archived_ss: false,
    remove_form_confirm: false,
    loading: false,
    selected_form: null,
    selected_resource: null,
    form_create: "",
    assessments: [],
    APPSteps: [],
    bookingServices: [],
    employees: [],
    field_types: [],
    current_files: {
      privacy_policy: { name: "", file: null },
      terms_and_conditions: { name: "", file: null },
    },
    addModal: false,
    addDoc: false,
    loadingRecent: false,
    refreshList: false,
    view_form: "",
    view_associatedform: "",
  };

  getData() {
    this.setState({ loadingRecent: true });
    api.system_legal_docs
      .getCurrent()
      .then((res) => {
        this.setState({
          loadingRecent: false,
          current_files: {
            privacy_policy: res.privacy_policy,
            terms_and_conditions: res.terms_and_conditions,
          },
        });
      })
      .catch(() => {
        this.setState({ loadingRecent: false });
      });
    api.company.getCompany(this.props.match.params.id).then((data) => {
      this.setState({
        style_data: data.style_data ? data.style_data : {},
        company: {
          ...this.state.company,
          ...data,
        },
      });
      Promise.all([
        api.company.getDash(this.props.match.params.id),
        api.forms.getFieldTypes(),
        api.company.getAssessments(this.props.match.params.id),
        api.company.getAPPSteps(this.props.match.params.id),
        api.company.getCompanyEmployeesDropdown(this.props.match.params.id),
        // api.microsoft_bookings.getCalendarServices(data.microsoft_bussinessId),
        api.microsoft_bookings.getCalendars(),
      ])
        .then((res) => {
          this.setState({
            loading: false,
            dash: {
              emp: res[0].emp,
              enr: res[0].enr,
              activeRef: res[0].activeRef,
              closedRef: res[0].closedRef,
            },
            field_types: res[1],
            assessments: res[2],
            APPSteps: res[3],
            employees: res[4],
            bookingServices: res[5] ? res[5] : [],
          });
        })
        .catch((e) => {
          this.setState({
            loading: false,
            error:
              e.response !== undefined
                ? e.response.data.message
                : "Unknown error while retrieving data from the server has occurred",
          });
        });
    });
  }

  componentDidMount() {
    this.getData();
  }

  handleDropdownChange = (e, data) => {
    this.setState({ data: { ...this.state.data, [data.name]: data.value } });
  };

  handleChange = (event) => {
    this.setState({
      data: { ...this.state.data, [event.target.name]: event.target.value },
    });
  };

  handleSubmit = (data) => {
    return api.company
      .editCompany(data, this.props.match.params.id)
      .then(() => {
        this.getData();
        toast({
          type: "success",
          icon: "pencil",
          title: <h3>Company Updated</h3>,
          description: (
            <span>
              <p>Successfully updated Company</p>
            </span>
          ),
          time: 4000,
        });
      });
  };

  handleDelete = (data) => {
    return api.company
      .editCompany(data, this.props.match.params.id)
      .then(() => {
        this.getData();
      });
  };

  getHtml(content) {
    const bodyFromHtml = htmlToDraft(content);
    const { contentBlocks, entityMap } = bodyFromHtml;
    const bodyState = ContentState.createFromBlockArray(
      contentBlocks,
      entityMap
    );

    return EditorState.createWithContent(bodyState);
  }

  renderEmailModal() {
    const handleClose = () =>
      this.setState({ editEmailTemplateModal: false, editEmailTemplate: {} });
    const handleDropdownChange = (e, data) => {
      this.setState({
        editEmailTemplate: {
          ...this.state.editEmailTemplate,
          [data.name]: data.value,
        },
      });
    };

    const handleChange = (event) => {
      this.setState({
        editEmailTemplate: {
          ...this.state.editEmailTemplate,
          [event.target.name]: event.target.value,
        },
      });
    };

    const validate = (data) => {
      let errors = {};
      if (!data.template_name) errors.template_name = "Can't Be Empty";
      if (!data.subject) errors.subject = "Can't Be Empty";
      if (!data.body_data) errors.body_data = "Can't Be Empty";
      return errors;
    };

    const handleSubmit = () => {
      const errors = validate(this.state.editEmailTemplate);

      this.setState({ errors: errors });

      if (Object.keys(errors).length === 0) {
        let data = {
          archived: this.state.editEmailTemplate.archived,
          template_name: this.state.editEmailTemplate.template_name,
          subject: this.state.editEmailTemplate.subject,
          content: draftToHtml(
            convertToRaw(
              this.state.editEmailTemplate.body_data.getCurrentContent()
            )
          ),
          footer_content: draftToHtml(
            convertToRaw(
              this.state.editEmailTemplate.footer_data.getCurrentContent()
            )
          ),
        };

        api.emails
          .editEmail(data, this.state.editEmailTemplate._id)
          .then((res) => {
            handleClose();
            this.getData();
            toast({
              type: "success",
              icon: "mail",
              title: <h3>Company Email Template Edited</h3>,
              description: (
                <span>
                  <p>Successfully edited the Email Template for Company</p>
                </span>
              ),
              time: 4000,
            });
          });

        // this.props.history.push(`/settings/email_templates/`);
      }
    };

    const handleWYSIWYGUpload = (file) => {
      // return;
      return new Promise((resolve, reject) => {
        api.resources
          .uploadFile(this.props.match.params.id, {
            type: "WYSIWYG",
            file: file,
          })

          .then((res) => {
            resolve({ data: { link: AppConfig.api_server + res } });
          });
      });
    };

    return (
      <Modal
        open={this.state.editEmailTemplateModal}
        onClose={handleClose}
        centered={false}
        closeOnDimmerClick={true}
      >
        <Header
          icon="mail"
          content={`Edit Email Template: ${
            this.state.editEmailTemplate &&
            this.state.editEmailTemplate.template_name
          }`}
        />
        <Modal.Content>
          <Segment>
            <Form>
              <h3>Email</h3>
              <Form.Group widths="equal">
                <Form.Input
                  name="template_name"
                  label="Template Name"
                  value={this.state.editEmailTemplate.template_name}
                  onChange={handleChange}
                  placeholder="Title"
                  error={this.state.errors.template_name}
                />
                <Form.Input
                  name="subject"
                  label="Subject"
                  value={this.state.editEmailTemplate.subject}
                  onChange={handleChange}
                  placeholder="Subject"
                  error={this.state.errors.subject}
                />
              </Form.Group>
              <p>
                <strong>Body</strong>
              </p>
              {this.state.errors.body_data && (
                <Message danger>{this.state.errors.body_data}</Message>
              )}
              <Editor
                editorState={this.state.editEmailTemplate.body_data}
                onEditorStateChange={(editorState) => {
                  this.setState({
                    editEmailTemplate: {
                      ...this.state.editEmailTemplate,
                      body_data: editorState,
                    },
                    temporary_html: "",
                  });
                }}
                editorStyle={{
                  borderLeft: "1px solid #F1F1F1",
                  borderRight: "1px solid #F1F1F1",
                  borderBottom: "1px solid #F1F1F1",
                  height: "300px",
                }}
                toolbarStyle={{ marginBottom: "0px" }}
                toolbarCustomButtons={[<ShortcodeDropdown />]}
                toolbar={{
                  image: {
                    uploadCallback: handleWYSIWYGUpload,
                    previewImage: true,
                    urlEnabled: false,
                    alignmentEnabled: true,
                    defaultSize: { width: "100%", height: "100%" },
                  },
                }}
              />
              <p>
                <strong>Footer </strong>{" "}
                {/*  
                <Checkbox
                  onChange={(e) => {
                    this.setState({
                      editEmailTemplate: {
                        ...this.state.editEmailTemplate,
                        defaultFooter:
                          !this.state.editEmailTemplate.defaultFooter,
                        footer_data: !this.state.editEmailTemplate.defaultFooter
                          ? this.state.defaultFooterContent
                          : this.state.editEmailTemplate.footer_data,
                      },
                    });
                    console.log(this.state.editEmailTemplate);
                  }}
                  checked={this.state.data.defaultFooter}
                  label="Use Settings Default"
                />*/}
              </p>
              {!this.state.data.defaultFooter && (
                <Editor
                  editorState={this.state.editEmailTemplate.footer_data}
                  onEditorStateChange={(editorState) => {
                    this.setState({
                      editEmailTemplate: {
                        ...this.state.editEmailTemplate,
                        footer_data: editorState,
                      },
                      temporary_html: "",
                    });
                  }}
                  editorStyle={{
                    borderLeft: "1px solid #F1F1F1",
                    borderRight: "1px solid #F1F1F1",
                    borderBottom: "1px solid #F1F1F1",
                    height: "300px",
                  }}
                  toolbarStyle={{ marginBottom: "0px" }}
                  toolbar={{
                    image: {
                      uploadCallback: handleWYSIWYGUpload,
                      previewImage: true,
                      urlEnabled: false,
                      alignmentEnabled: true,
                      defaultSize: { width: "100%", height: "100%" },
                    },
                  }}
                />
              )}
              <Form.Group inline>
                <Checkbox
                  label="Archived"
                  name="archived"
                  disabled={
                    this.state.editEmailTemplate.code ===
                    "DEFAULT_EMAIL_TEMPLATE"
                  }
                  checked={this.state.editEmailTemplate.archived}
                  onClick={() => {
                    this.setState({
                      editEmailTemplate: {
                        ...this.state.editEmailTemplate,
                        archived: !this.state.editEmailTemplate.archived,
                      },
                    });
                  }}
                />
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Button floated="left" color="red" onClick={handleClose}>
                  <Icon name="arrow left" />
                  Back
                </Form.Button>
                <Form.Button
                  floated="right"
                  type="submit"
                  color="orange"
                  onClick={handleSubmit}
                >
                  <Icon name="pencil" />
                  Edit
                </Form.Button>
              </Form.Group>
            </Form>
          </Segment>
        </Modal.Content>
      </Modal>
    );
  }

  handleMenuClick = (e, { name }) => this.setState({ activeItem: name });

  handleStyleMenuClick = (e, { name }) => this.setState({ style_menu: name });

  renderHeader() {
    const { activeItem } = this.state;
    return (
      <Segment basic className="no-pad no-marg ">
        {this.renderMenu(activeItem)}
      </Segment>
    );
  }

  renderDash() {
    return (
      <Segment basic style={{ padding: "20px" }}>
        <Grid.Row>
          <Grid.Column computer={16} mobile={16}>
            <Statistic.Group widths={3}>
              <Statistic>
                <Statistic.Value>
                  {this.state.company.assessmentStatuses &&
                    this.state.company.assessmentStatuses.length}
                </Statistic.Value>
                <Statistic.Label>Health Journey Statuses</Statistic.Label>
              </Statistic>
              <Statistic>
                <Statistic.Value>
                  {this.state.company.sampleTypes &&
                    this.state.company.sampleTypes.length}
                </Statistic.Value>
                <Statistic.Label>Sample Types</Statistic.Label>
              </Statistic>
              <Statistic>
                <Statistic.Value>
                  {this.state.company.specifics &&
                    this.state.company.specifics.length}
                </Statistic.Value>
                <Statistic.Label>Specimen Types</Statistic.Label>
              </Statistic>
            </Statistic.Group>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column computer={16} mobile={16}>
            <Statistic.Group widths={2}>
              <Statistic>
                <Statistic.Value>
                  {this.state.company.emails &&
                    this.state.company.emails.length}
                </Statistic.Value>
                <Statistic.Label>Email Templates</Statistic.Label>
              </Statistic>
              <Statistic>
                <Statistic.Value>
                  {this.state.company.automations &&
                    this.state.company.automations.length}
                </Statistic.Value>
                <Statistic.Label>Email Automations</Statistic.Label>
              </Statistic>
            </Statistic.Group>
          </Grid.Column>
        </Grid.Row>
      </Segment>
    );
  }

  renderMenu(activeItem) {
    return (
      <Menu className="subMenu">
        <Menu.Item
          onClick={this.handleMenuClick}
          as={Link}
          to={`/company/${this.props.match.params.id}`}
        >
          <Icon name="backward" />
          Back to Company
        </Menu.Item>
        {/* {!Authentication.can("organisation_case_manager.permission") && ( */}
        <Menu.Item
          name="dash"
          active={activeItem === "dash"}
          onClick={this.handleMenuClick}
        >
          <Icon name="dashboard" />
          Dashboard
        </Menu.Item>
        <Menu.Item
          name="tests"
          active={activeItem === "tests"}
          onClick={this.handleMenuClick}
        >
          <Icon name="cogs" />
          Test Settings
        </Menu.Item>
        <Menu.Item
          name="health_journey"
          active={activeItem === "health_journey"}
          onClick={this.handleMenuClick}
        >
          <Icon name="cogs" />
          Health Journey Settings
        </Menu.Item>
        <Menu.Item
          name="emails"
          active={activeItem === "emails"}
          onClick={this.handleMenuClick}
        >
          <Icon name="mail" />
          Emails
        </Menu.Item>
        <Menu.Item
          name="health_notices"
          active={activeItem === "health_notices"}
          onClick={this.handleMenuClick}
        >
          <Icon name="clipboard" />
          Health Notices
        </Menu.Item>
        <Menu.Item
          name="app_splash"
          active={activeItem === "app_splash"}
          onClick={this.handleMenuClick}
        >
          <Icon name="mobile" />
          App Splash Screens
        </Menu.Item>
        <Menu.Item
          name="resources"
          active={activeItem === "resources"}
          onClick={this.handleMenuClick}
        >
          <Icon name="clipboard" />
          Resources
        </Menu.Item>
        <Menu.Item
          name="resource_category"
          active={activeItem === "resource_category"}
          onClick={this.handleMenuClick}
        >
          <Icon name="clipboard" />
          Resource Categories
        </Menu.Item>
        {/* )} */}
        <Menu.Item
          name="forms"
          active={activeItem === "forms"}
          onClick={this.handleMenuClick}
        >
          <Icon name="file" />
          Company Forms
        </Menu.Item>
        {this.state.company.associated_steps && (
          <Menu.Item
            name="app_actions"
            active={activeItem === "app_actions"}
            onClick={this.handleMenuClick}
          >
            <Icon name="file" />
            Associated Health Journey Stages
          </Menu.Item>
        )}
        {/* {this.state.company.company_manager && ( */}
          <Menu.Item
            name="app_actions"
            active={activeItem === "app_actions"}
            onClick={this.handleMenuClick}
          >
            <Icon name="file" />
            Health Journey Stages
          </Menu.Item>
        {/* )} */ }
        <Menu.Item
          name="policies"
          active={activeItem === "policies"}
          onClick={this.handleMenuClick}
        >
          <Icon name="clipboard list" />
          Policies
        </Menu.Item>
        <Menu.Item
          name="branding"
          active={activeItem === "branding"}
          onClick={this.handleMenuClick}
        >
          <Icon name="paint brush" />
          Branding Typography
        </Menu.Item>
        <Menu.Item
          name="style_global"
          active={activeItem === "styling"}
          onClick={this.handleMenuClick}
        >
          <Icon name="paint brush" />
          APP Styling
        </Menu.Item>
      </Menu>
    );
  }

  renderHealthNotices() {

    return(
      <Segment basic>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Notice</Table.HeaderCell>
              <Table.HeaderCell>Short Description</Table.HeaderCell>
              <Table.HeaderCell>Long Description</Table.HeaderCell>
              <Table.HeaderCell>Start</Table.HeaderCell>
              <Table.HeaderCell>End</Table.HeaderCell>
              <Table.HeaderCell>
                <Button.Group style={{ paddingLeft: "15%", float: "right" }}>
                  <Button
                    content={`Show Archived`}
                    positive={this.state.show_archived_hn === true}
                    onClick={() =>
                      this.setState(
                        {
                          show_archived_hn: true,
                        },
                        () => {
                          this.getData();
                        }
                      )
                    }
                  />
                  <Button
                    content={`Hide Archived`}
                    negative={this.state.show_archived_hn === false}
                    onClick={() =>
                      this.setState(
                        {
                          show_archived_hn: false,
                        },
                        () => {
                          this.getData();
                        }
                      )
                    }
                  />
                </Button.Group>
              </Table.HeaderCell>
            </Table.Row>
            <Table.Row>
              <Table.HeaderCell>
                <Input
                  name="title"
                  placeholder="Enter Notice Title"
                  value={this.state.notice_data.title}
                  onChange={(event) => {
                    this.setState({
                      notice_data: {
                        ...this.state.notice_data,
                        title: event.target.value,
                      }
                    });
                  }}
                />
                {this.state.notice_data.edit &&
                <Checkbox
                    label="Archived"
                    name="archived"
                    checked={this.state.notice_data.archived}
                    onChange={() => {
                      this.setState({
                        notice_data: {
                          ...this.state.notice_data,
                          archived: !this.state.notice_data.archived,
                        },
                      });
                    }}
                  />}
              </Table.HeaderCell>
              <Table.HeaderCell>
                <Input
                  name="short_description"
                  placeholder="Enter Short Description"
                  value={this.state.notice_data.short_description}
                  onChange={(event) => {
                    this.setState({
                      notice_data: {
                        ...this.state.notice_data,
                        short_description: event.target.value,
                      }
                    });
                  }}
                />
              </Table.HeaderCell>
              <Table.HeaderCell>
                <Input
                  name="long_description"
                  placeholder="Enter Long Description"
                  value={this.state.notice_data.long_description}
                  onChange={(event) => {
                    this.setState({
                      notice_data: {
                        ...this.state.notice_data,
                        long_description: event.target.value,
                      }
                    });
                  }}
                />
              </Table.HeaderCell>
              <Table.HeaderCell>
                <DateTimeInput
                  autoComplete="off"
                  name="start_date"
                  dateTimeFormat="YYYY/MM/DD HH:mm"
                  dateFormat="YYYY/MM/DD HH:mm"
                  placeholder="Start Date"
                  value={
                    this.state.notice_data.start_date
                      ? moment(this.state.notice_data.start_date).format("YYYY/MM/DD HH:mm")
                      : ""
                  }
                  iconPosition="left"
                  onChange={(e, data) => {
                    this.setState({ notice_data: { ...this.state.notice_data, [data.name]: data.value } });
                  }}
                />
              </Table.HeaderCell>
              <Table.HeaderCell>
                <DateTimeInput
                  autoComplete="off"
                  name="end_date"
                  dateTimeFormat="YYYY/MM/DD HH:mm"
                  dateFormat="YYYY/MM/DD HH:mm"
                  placeholder="End Date"
                  value={
                    this.state.notice_data.end_date
                      ? moment(this.state.notice_data.end_date).format("YYYY/MM/DD HH:mm")
                      : ""
                  }
                  iconPosition="left"
                  onChange={(e, data) => {
                    this.setState({ notice_data: { ...this.state.notice_data, [data.name]: data.value } });
                  }}
                />
              </Table.HeaderCell>
              <Table.HeaderCell>
                  <Button
                    disabled={
                      !this.state.notice_data.end_date ||
                      !this.state.notice_data.short_description ||
                      !this.state.notice_data.title
                    }
                    content={`${this.state.notice_data.edit ? "Edit" : "Add"} Notice`}
                    color={this.state.notice_data.edit ? "orange" : "green"}
                    icon={this.state.notice_data.edit ? "pencil" :"plus"}
                    onClick={() => {

                      if(this.state.notice_data.edit) {
                        api.health_notices.edit(this.state.notice_data.edit,this.state.notice_data)
                          .then((res) => {
                            this.setState({
                              notice_data: {title: "", short_description : "", long_description: ""}
                            });
                            this.getData();
                            toast({
                              type: "success",
                              icon: "pencil",
                              title: <h3>Notice Edited</h3>,
                              description: (
                                <span>
                                  <p>Successfully edited Notice</p>
                                </span>
                              ),
                              time: 4000,
                            });
                          });
                      }else{
                        api.health_notices.add({
                          ...this.state.notice_data,
                          company: this.state.company._id
                        })
                          .then((res) => {
                            this.setState({
                              notice_data: {}
                            });
                            this.getData();
                            toast({
                              type: "success",
                              icon: "plus",
                              title: <h3>Notice Added</h3>,
                              description: (
                                <span>
                                  <p>Successfully added Notice</p>
                                </span>
                              ),
                              time: 4000,
                            });
                          });
                      }


                      
                    }}
                  />
                </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {this.state.company.health_notices &&
              this.state.company.health_notices
                .filter((f) => {
                  if (f.archived) {
                    if (this.state.show_archived_hn) {
                      return f;
                    }
                  } else {
                    return f;
                  }
                })
                .map((notice) => {
                  return (
                    <Table.Row>
                      <Table.Cell>
                        {notice.title}{" "}
                        {notice.archived && (
                          <Label content="Archived" color="red" />
                        )}
                      </Table.Cell>
                      <Table.Cell>{notice.short_description}</Table.Cell>
                      <Table.Cell>{notice.long_description}</Table.Cell>
                      <Table.Cell>{notice.start_date ? moment(notice.start_date).format("DD/MM/YYYY HH:mm") : ""}</Table.Cell>
                      <Table.Cell>{notice.end_date ? moment(notice.end_date).format("DD/MM/YYYY HH:mm") : ""}</Table.Cell>
                      <Table.Cell>
                        <Button content="Edit" color="orange" icon="pencil" onClick={() => {
                            this.setState({
                              notice_data: {
                                title: notice.title,
                                archived: notice.archived,
                                short_description: notice.short_description,
                                long_description: notice.long_description,
                                start_date: notice.start_date,
                                end_date: notice.end_date,
                                edit: notice._id
                              }
                            });
                          }} />
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
          </Table.Body>
        </Table>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Associated Notice</Table.HeaderCell>
              <Table.HeaderCell>Short Description</Table.HeaderCell>
              <Table.HeaderCell>Long Description</Table.HeaderCell>
              <Table.HeaderCell>Start</Table.HeaderCell>
              <Table.HeaderCell>End</Table.HeaderCell>
              <Table.HeaderCell>
                <Button.Group style={{ paddingLeft: "15%", float: "right" }}>
                  {/*  
                  <Button
                    content={`Show Archived`}
                    positive={this.state.show_archived_forms === true}
                    onClick={() =>
                      this.setState(
                        {
                          show_archived_forms: true,
                        },
                        () => {
                          this.getData();
                        }
                      )
                    }
                  />
                  <Button
                    content={`Hide Archived`}
                    negative={this.state.show_archived_forms === false}
                    onClick={() =>
                      this.setState(
                        {
                          show_archived_forms: false,
                        },
                        () => {
                          this.getData();
                        }
                      )
                    }
                  /> */}
                </Button.Group>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {this.state.company.associated_health_notices &&
              this.state.company.associated_health_notices.map((notice) => {
                return (
                  <Table.Row>
                    <Table.Cell>
                      {notice.title}{" "}
                      {notice.archived && (
                        <Label content="Archived" color="red" />
                      )}
                    </Table.Cell>
                    <Table.Cell>{notice.short_description}</Table.Cell>
                    <Table.Cell>{notice.long_description}</Table.Cell>
                    <Table.Cell>{notice.start_date}</Table.Cell>
                    <Table.Cell>{notice.end_date}</Table.Cell>
                    <Table.Cell>
                      
                    </Table.Cell>
                </Table.Row>
                );
              })}
          </Table.Body>
        </Table>
      </Segment>
    );
  }

  renderResourceCategories() {

    return(
      <Segment basic>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Resource Category</Table.HeaderCell>
              <Table.HeaderCell>
                <Button.Group style={{ paddingLeft: "15%", float: "right" }}>
                  <Button
                    content={`Show Archived`}
                    positive={this.state.show_archived_res_cats === true}
                    onClick={() =>
                      this.setState(
                        {
                          show_archived_res_cats: true,
                        },
                        () => {
                          this.getData();
                        }
                      )
                    }
                  />
                  <Button
                    content={`Hide Archived`}
                    negative={this.state.show_archived_res_cats === false}
                    onClick={() =>
                      this.setState(
                        {
                          show_archived_res_cats: false,
                        },
                        () => {
                          this.getData();
                        }
                      )
                    }
                  />
                </Button.Group>
              </Table.HeaderCell>
            </Table.Row>
            <Table.Row>
              <Table.HeaderCell>
                <Input
                  name="title"
                  placeholder="Enter Category Title"
                  value={this.state.resource_cat_data.title}
                  onChange={(event) => {
                    this.setState({
                      resource_cat_data: {
                        ...this.state.resource_cat_data,
                        title: event.target.value,
                      }
                    });
                  }}
                />
                {this.state.resource_cat_data.edit &&
                <Checkbox
                    label="Archived"
                    name="archived"
                    checked={this.state.resource_cat_data.archived}
                    onChange={() => {
                      this.setState({
                        resource_cat_data: {
                          ...this.state.resource_cat_data,
                          archived: !this.state.resource_cat_data.archived,
                        },
                      });
                    }}
                  />}
              </Table.HeaderCell>
              <Table.HeaderCell>
                  <Button
                    disabled={!this.state.resource_cat_data.title}
                    content={`${this.state.resource_cat_data.edit ? "Edit" : "Add"} Resource Category`}
                    color={this.state.resource_cat_data.edit ? "orange" : "green"}
                    icon={this.state.resource_cat_data.edit ? "pencil" :"plus"}
                    onClick={() => {
                      if(this.state.resource_cat_data.edit) {
                        api.resource_categories.edit(this.state.resource_cat_data.edit, this.state.resource_cat_data)
                          .then((res) => {
                            this.setState({
                              resource_cat_data: {title: ""}
                            });
                            this.getData();
                            toast({
                              type: "success",
                              icon: "pencil",
                              title: <h3>Resource Category Edited</h3>,
                              description: (
                                <span>
                                  <p>Successfully edited Category</p>
                                </span>
                              ),
                              time: 4000,
                            });
                          });
                      }else{
                        api.resource_categories.add({
                          ...this.state.resource_cat_data,
                          company: this.state.company._id
                        })
                          .then((res) => {
                            this.setState({
                              resource_cat_data: {}
                            });
                            this.getData();
                            toast({
                              type: "success",
                              icon: "plus",
                              title: <h3>Resource Category Added</h3>,
                              description: (
                                <span>
                                  <p>Successfully added Category</p>
                                </span>
                              ),
                              time: 4000,
                            });
                          });
                      }
                      
                    }}
                  />
                </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {this.state.company.resource_cats &&
              this.state.company.resource_cats
                .filter((f) => {
                  if (f.archived) {
                    if (this.state.show_archived_res_cats) {
                      return f;
                    }
                  } else {
                    return f;
                  }
                })
                .map((data) => {
                  return (
                    <Table.Row>
                      <Table.Cell>
                        {data.title}{" "}
                        {data.archived && (
                          <Label content="Archived" color="red" />
                        )}
                      </Table.Cell>
                      <Table.Cell>
                        <Button content="Edit" color="orange" icon="pencil" onClick={() => {
                          this.setState({
                            resource_cat_data: {
                              title: data.title,
                              edit: data._id,
                              archived: data.archived
                            }
                          });
                        }} />
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
          </Table.Body>
        </Table>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Associated Resource Categories</Table.HeaderCell>
              <Table.HeaderCell>
                <Button.Group style={{ paddingLeft: "15%", float: "right" }}>
                  {/*  
                  <Button
                    content={`Show Archived`}
                    positive={this.state.show_archived_forms === true}
                    onClick={() =>
                      this.setState(
                        {
                          show_archived_forms: true,
                        },
                        () => {
                          this.getData();
                        }
                      )
                    }
                  />
                  <Button
                    content={`Hide Archived`}
                    negative={this.state.show_archived_forms === false}
                    onClick={() =>
                      this.setState(
                        {
                          show_archived_forms: false,
                        },
                        () => {
                          this.getData();
                        }
                      )
                    }
                  /> */}
                </Button.Group>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {this.state.company.associated_resource_cats &&
              this.state.company.associated_resource_cats.map((notice) => {
                return (
                  <Table.Row>
                    <Table.Cell>
                      {notice.title}{" "}
                      {notice.archived && (
                        <Label content="Archived" color="red" />
                      )}
                    </Table.Cell>
                    <Table.Cell>
                      
                    </Table.Cell>
                </Table.Row>
                );
              })}
          </Table.Body>
        </Table>
      </Segment>
    );
  }

  renderAppStyling(){
    let {style_menu} = this.state;
    return (
      <Segment basic>
        <Menu className="subMenu">
        <Menu.Item
          name="global"
          active={style_menu === "global"}
          onClick={this.handleStyleMenuClick}
        >
          <Icon name="cogs" />
          Global Settings
        </Menu.Item>
        <Menu.Item
          name="dashboard"
          active={style_menu === "dashboard"}
          onClick={this.handleStyleMenuClick}
        >
          <Icon name="mobile" />
          Dashboard
        </Menu.Item>
        <Menu.Item
          name="my_profile"
          active={style_menu === "my_profile"}
          onClick={this.handleStyleMenuClick}
        >
          <Icon name="user" />
          My Profile
        </Menu.Item>
        <Menu.Item
          name="health_journeys"
          active={style_menu === "health_journeys"}
          onClick={this.handleStyleMenuClick}
        >
          <Icon name="mobile" />
          Health Journeys
        </Menu.Item>
        <Menu.Item
          name="referrals"
          active={style_menu === "referrals"}
          onClick={this.handleStyleMenuClick}
        >
          <Icon name="mobile" />
          Referrals
        </Menu.Item>
        <Menu.Item
          name="services"
          active={style_menu === "services"}
          onClick={this.handleStyleMenuClick}
        >
          <Icon name="cogs" />
          Services
        </Menu.Item>
        <Menu.Item
          name="resources"
          active={style_menu === "resources"}
          onClick={this.handleStyleMenuClick}
        >
          <Icon name="clipboard" />
          Resources
        </Menu.Item>
        <Menu.Item
          name="contact"
          active={style_menu === "contact"}
          onClick={this.handleStyleMenuClick}
        >
          <Icon name="mail" />
          Contact
        </Menu.Item>
        </Menu>
        {style_menu === "dashboard" ?
        <>
        <Table>
          <Table.Header>
            <Table.Row>
              <TableHeaderCell><Icon name="clipboard" />My Health Journey</TableHeaderCell>
              <TableHeaderCell>Style</TableHeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.Cell>"My Health Journey" Title</Table.Cell>
              <Table.Cell>
                <Select
                  clearable
                  name="dash_hj_title"
                  options={
                    this.state.company.company_typographys && this.state.company.company_typographys.map((item) => {
                      return {text: item.title, value: item._id};
                    })}
                  value={this.state.style_data.dash_hj_title}
                  onChange={(e, data) => {
                    this.setState({
                      style_data: { ...this.state.style_data, [data.name]: data.value },
                    });
                  }}
                />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>"My Health Journey" See All Button</Table.Cell>
              <Table.Cell>
              <Select
                clearable
                  name="dash_hj_seeAll"
                  options={
                    this.state.company.company_typographys && this.state.company.company_typographys.map((item) => {
                      return {text: item.title, value: item._id};
                    })}
                  value={this.state.style_data.dash_hj_seeAll}
                  onChange={(e, data) => {
                    this.setState({
                      style_data: { ...this.state.style_data, [data.name]: data.value },
                    });
                  }}
                />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>"Health Journey" Box</Table.Cell>
              <Table.Cell>
              <Select
                clearable
                  name="dash_hj_box"
                  options={
                    this.state.company.company_typographys && this.state.company.company_typographys.map((item) => {
                      return {text: item.title, value: item._id};
                    })}
                  value={this.state.style_data.dash_hj_box}
                  onChange={(e, data) => {
                    this.setState({
                      style_data: { ...this.state.style_data, [data.name]: data.value },
                    });
                  }}
                />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>"Health Journey" Box Title</Table.Cell>
              <Table.Cell>
                <Select
                clearable
                  name="dash_hj_box_title"
                  options={
                    this.state.company.company_typographys && this.state.company.company_typographys.map((item) => {
                      return {text: item.title, value: item._id};
                    })}
                  value={this.state.style_data.dash_hj_box_title}
                  onChange={(e, data) => {
                    this.setState({
                      style_data: { ...this.state.style_data, [data.name]: data.value },
                    });
                  }}
                />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>"Health Journey" Box Date</Table.Cell>
              <Table.Cell>
                <Select
                clearable
                  name="dash_hj_box_date"
                  options={
                    this.state.company.company_typographys && this.state.company.company_typographys.map((item) => {
                      return {text: item.title, value: item._id};
                    })}
                  value={this.state.style_data.dash_hj_box_date}
                  onChange={(e, data) => {
                    this.setState({
                      style_data: { ...this.state.style_data, [data.name]: data.value },
                    });
                  }}
                />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>"Health Journey" Box Status Icon</Table.Cell>
              <Table.Cell>
                <Select
                clearable
                  name="dash_hj_box_status_icon"
                  options={
                    this.state.company.company_typographys && this.state.company.company_typographys.map((item) => {
                      return {text: item.title, value: item._id};
                    })}
                  value={this.state.style_data.dash_hj_box_status_icon}
                  onChange={(e, data) => {
                    this.setState({
                      style_data: { ...this.state.style_data, [data.name]: data.value },
                    });
                  }}
                />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>"Health Journey" Box Status Text</Table.Cell>
              <Table.Cell>
                <Select
                clearable
                  name="dash_hj_box_status_text"
                  options={
                    this.state.company.company_typographys && this.state.company.company_typographys.map((item) => {
                      return {text: item.title, value: item._id};
                    })}
                  value={this.state.style_data.dash_hj_box_status_text}
                  onChange={(e, data) => {
                    this.setState({
                      style_data: { ...this.state.style_data, [data.name]: data.value },
                    });
                  }}
                />
              </Table.Cell>
            </Table.Row>
            </Table.Body>
            </Table>
            <Table>
            <Table.Header>
              <Table.Row>
                <TableHeaderCell><Icon name="clipboard" />My Referrals</TableHeaderCell>
                <TableHeaderCell>Style</TableHeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
            <Table.Row>
              <Table.Cell>"My Referrals" Title</Table.Cell>
              <Table.Cell>
                <Select
                  clearable
                  name="dash_ref_title"
                  options={
                    this.state.company.company_typographys && this.state.company.company_typographys.map((item) => {
                      return {text: item.title, value: item._id};
                    })}
                  value={this.state.style_data.dash_ref_title}
                  onChange={(e, data) => {
                    this.setState({
                      style_data: { ...this.state.style_data, [data.name]: data.value },
                    });
                  }}
                />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>"My Referrals" See All Button</Table.Cell>
              <Table.Cell>
              <Select
                clearable
                  name="dash_ref_seeAll"
                  options={
                    this.state.company.company_typographys && this.state.company.company_typographys.map((item) => {
                      return {text: item.title, value: item._id};
                    })}
                  value={this.state.style_data.dash_ref_seeAll}
                  onChange={(e, data) => {
                    this.setState({
                      style_data: { ...this.state.style_data, [data.name]: data.value },
                    });
                  }}
                />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>"My Referrals" Box</Table.Cell>
              <Table.Cell>
              <Select
                clearable
                  name="dash_ref_box"
                  options={
                    this.state.company.company_typographys && this.state.company.company_typographys.map((item) => {
                      return {text: item.title, value: item._id};
                    })}
                  value={this.state.style_data.dash_ref_box}
                  onChange={(e, data) => {
                    this.setState({
                      style_data: { ...this.state.style_data, [data.name]: data.value },
                    });
                  }}
                />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>"My Referrals" Box Title</Table.Cell>
              <Table.Cell>
                <Select
                clearable
                  name="dash_ref_box_title"
                  options={
                    this.state.company.company_typographys && this.state.company.company_typographys.map((item) => {
                      return {text: item.title, value: item._id};
                    })}
                  value={this.state.style_data.dash_ref_box_title}
                  onChange={(e, data) => {
                    this.setState({
                      style_data: { ...this.state.style_data, [data.name]: data.value },
                    });
                  }}
                />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell >"My Referrals" Box Sub Title</Table.Cell>
              <Table.Cell>
                <Select
                clearable
                  name="dash_ref_box_sub_title"
                  options={
                    this.state.company.company_typographys && this.state.company.company_typographys.map((item) => {
                      return {text: item.title, value: item._id};
                    })}
                  value={this.state.style_data.dash_ref_box_sub_title}
                  onChange={(e, data) => {
                    this.setState({
                      style_data: { ...this.state.style_data, [data.name]: data.value },
                    });
                  }}
                />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>"My Referrals" Box Status Icon</Table.Cell>
              <Table.Cell>
                <Select
                clearable
                  name="dash_ref_box_status_icon"
                  options={
                    this.state.company.company_typographys && this.state.company.company_typographys.map((item) => {
                      return {text: item.title, value: item._id};
                    })}
                  value={this.state.style_data.dash_ref_box_status_icon}
                  onChange={(e, data) => {
                    this.setState({
                      style_data: { ...this.state.style_data, [data.name]: data.value },
                    });
                  }}
                />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>"My Referrals" Box Status Text</Table.Cell>
              <Table.Cell>
                <Select
                clearable
                  name="dash_ref_box_status_text"
                  options={
                    this.state.company.company_typographys && this.state.company.company_typographys.map((item) => {
                      return {text: item.title, value: item._id};
                    })}
                  value={this.state.style_data.dash_ref_box_status_text}
                  onChange={(e, data) => {
                    this.setState({
                      style_data: { ...this.state.style_data, [data.name]: data.value },
                    });
                  }}
                />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>"My Referrals" Box Date</Table.Cell>
              <Table.Cell>
                <Select
                clearable
                  name="dash_ref_box_date"
                  options={
                    this.state.company.company_typographys && this.state.company.company_typographys.map((item) => {
                      return {text: item.title, value: item._id};
                    })}
                  value={this.state.style_data.dash_ref_box_date}
                  onChange={(e, data) => {
                    this.setState({
                      style_data: { ...this.state.style_data, [data.name]: data.value },
                    });
                  }}
                />
              </Table.Cell>
            </Table.Row>
            </Table.Body>
            </Table>
            <Table>
            <Table.Header>
              <Table.Row>
                <TableHeaderCell><Icon name="clipboard list" />My Readings</TableHeaderCell>
                <TableHeaderCell>Style</TableHeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
            <Table.Row>
              <Table.Cell>"My Readings" Text</Table.Cell>
              <Table.Cell>
              <Select
                clearable
                  name="dash_readings_text"
                  options={
                    this.state.company.company_typographys && this.state.company.company_typographys.map((item) => {
                      return {text: item.title, value: item._id};
                    })}
                  value={this.state.style_data.dash_readings_text}
                  onChange={(e, data) => {
                    this.setState({
                      style_data: { ...this.state.style_data, [data.name]: data.value },
                    });
                  }}
                />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>"My Readings" See All Button</Table.Cell>
              <Table.Cell>
              <Select
                clearable
                  name="dash_readings_seeAll"
                  options={
                    this.state.company.company_typographys && this.state.company.company_typographys.map((item) => {
                      return {text: item.title, value: item._id};
                    })}
                  value={this.state.style_data.dash_readings_seeAll}
                  onChange={(e, data) => {
                    this.setState({
                      style_data: { ...this.state.style_data, [data.name]: data.value },
                    });
                  }}
                />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>"My Readings" Title</Table.Cell>
              <Table.Cell>
              <Select
                clearable
                  name="dash_readings_title"
                  options={
                    this.state.company.company_typographys && this.state.company.company_typographys.map((item) => {
                      return {text: item.title, value: item._id};
                    })}
                  value={this.state.style_data.dash_readings_title}
                  onChange={(e, data) => {
                    this.setState({
                      style_data: { ...this.state.style_data, [data.name]: data.value },
                    });
                  }}
                />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>"My Readings" Value</Table.Cell>
              <Table.Cell>
              <Select
                clearable
                  name="dash_readings_value"
                  options={
                    this.state.company.company_typographys && this.state.company.company_typographys.map((item) => {
                      return {text: item.title, value: item._id};
                    })}
                  value={this.state.style_data.dash_readings_value}
                  onChange={(e, data) => {
                    this.setState({
                      style_data: { ...this.state.style_data, [data.name]: data.value },
                    });
                  }}
                />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>"My Readings" Label</Table.Cell>
              <Table.Cell>
              <Select
                clearable
                  name="dash_readings_label"
                  options={
                    this.state.company.company_typographys && this.state.company.company_typographys.map((item) => {
                      return {text: item.title, value: item._id};
                    })}
                  value={this.state.style_data.dash_readings_label}
                  onChange={(e, data) => {
                    this.setState({
                      style_data: { ...this.state.style_data, [data.name]: data.value },
                    });
                  }}
                />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>"My Readings" Box</Table.Cell>
              <Table.Cell>
              <Select
                clearable
                  name="dash_readings_box"
                  options={
                    this.state.company.company_typographys && this.state.company.company_typographys.map((item) => {
                      return {text: item.title, value: item._id};
                    })}
                  value={this.state.style_data.dash_readings_box}
                  onChange={(e, data) => {
                    this.setState({
                      style_data: { ...this.state.style_data, [data.name]: data.value },
                    });
                  }}
                />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>"My Readings" BMI Icon</Table.Cell>
              <Table.Cell>
              <Select
                clearable
                  name="dash_readings_box_bmi_icon"
                  options={
                    this.state.company.company_typographys && this.state.company.company_typographys.map((item) => {
                      return {text: item.title, value: item._id};
                    })}
                  value={this.state.style_data.dash_readings_box_bmi_icon}
                  onChange={(e, data) => {
                    this.setState({
                      style_data: { ...this.state.style_data, [data.name]: data.value },
                    });
                  }}
                />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>"My Readings" Blood Pressure Icon</Table.Cell>
              <Table.Cell>
              <Select
                clearable
                  name="dash_readings_box_bp_icon"
                  options={
                    this.state.company.company_typographys && this.state.company.company_typographys.map((item) => {
                      return {text: item.title, value: item._id};
                    })}
                  value={this.state.style_data.dash_readings_box_bp_icon}
                  onChange={(e, data) => {
                    this.setState({
                      style_data: { ...this.state.style_data, [data.name]: data.value },
                    });
                  }}
                />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>"My Readings" Date</Table.Cell>
              <Table.Cell>
              <Select
                clearable
                  name="dash_readings_box_date"
                  options={
                    this.state.company.company_typographys && this.state.company.company_typographys.map((item) => {
                      return {text: item.title, value: item._id};
                    })}
                  value={this.state.style_data.dash_readings_box_date}
                  onChange={(e, data) => {
                    this.setState({
                      style_data: { ...this.state.style_data, [data.name]: data.value },
                    });
                  }}
                />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>"My Readings" Add BMI Button</Table.Cell>
              <Table.Cell>
              <Select
                clearable
                  name="dash_readings_add_bmi"
                  options={
                    this.state.company.company_typographys && this.state.company.company_typographys.map((item) => {
                      return {text: item.title, value: item._id};
                    })}
                  value={this.state.style_data.dash_readings_add_bmi}
                  onChange={(e, data) => {
                    this.setState({
                      style_data: { ...this.state.style_data, [data.name]: data.value },
                    });
                  }}
                />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>"My Readings" Add Blood Pressure Button</Table.Cell>
              <Table.Cell>
              <Select
                clearable
                  name="dash_readings_add_bp"
                  options={
                    this.state.company.company_typographys && this.state.company.company_typographys.map((item) => {
                      return {text: item.title, value: item._id};
                    })}
                  value={this.state.style_data.dash_readings_add_bp}
                  onChange={(e, data) => {
                    this.setState({
                      style_data: { ...this.state.style_data, [data.name]: data.value },
                    });
                  }}
                />
              </Table.Cell>
            </Table.Row>
            </Table.Body>
            </Table>
            <Table>
            <Table.Header>
              <Table.Row>
                <TableHeaderCell><Icon name="star" />Favorite Services</TableHeaderCell>
                <TableHeaderCell>Style</TableHeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
            <Table.Row>
              <Table.Cell>"Favourite Services" Title</Table.Cell>
              <Table.Cell>
              <Select
                clearable
                  name="dash_favourite_services_title"
                  options={
                    this.state.company.company_typographys && this.state.company.company_typographys.map((item) => {
                      return {text: item.title, value: item._id};
                    })}
                  value={this.state.style_data.dash_favourite_services_title}
                  onChange={(e, data) => {
                    this.setState({
                      style_data: { ...this.state.style_data, [data.name]: data.value },
                    });
                  }}
                />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>"Favourite Services" See All Button</Table.Cell>
              <Table.Cell>
              <Select
                clearable
                  name="dash_favourite_services_seeAll"
                  options={
                    this.state.company.company_typographys && this.state.company.company_typographys.map((item) => {
                      return {text: item.title, value: item._id};
                    })}
                  value={this.state.style_data.dash_favourite_services_seeAll}
                  onChange={(e, data) => {
                    this.setState({
                      style_data: { ...this.state.style_data, [data.name]: data.value },
                    });
                  }}
                />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>"Favourite Services" Card</Table.Cell>
              <Table.Cell>
              <Select
                clearable
                  name="dash_favourite_services_card"
                  options={
                    this.state.company.company_typographys && this.state.company.company_typographys.map((item) => {
                      return {text: item.title, value: item._id};
                    })}
                  value={this.state.style_data.dash_favourite_services_card}
                  onChange={(e, data) => {
                    this.setState({
                      style_data: { ...this.state.style_data, [data.name]: data.value },
                    });
                  }}
                />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>"Favourite Services" Header</Table.Cell>
              <Table.Cell>
              <Select
                clearable
                  name="dash_favourite_services_header"
                  options={
                    this.state.company.company_typographys && this.state.company.company_typographys.map((item) => {
                      return {text: item.title, value: item._id};
                    })}
                  value={this.state.style_data.dash_favourite_services_header}
                  onChange={(e, data) => {
                    this.setState({
                      style_data: { ...this.state.style_data, [data.name]: data.value },
                    });
                  }}
                />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>"Favourite Services" Text</Table.Cell>
              <Table.Cell>
              <Select
                clearable
                  name="dash_favourite_services_text"
                  options={
                    this.state.company.company_typographys && this.state.company.company_typographys.map((item) => {
                      return {text: item.title, value: item._id};
                    })}
                  value={this.state.style_data.dash_favourite_services_text}
                  onChange={(e, data) => {
                    this.setState({
                      style_data: { ...this.state.style_data, [data.name]: data.value },
                    });
                  }}
                />
              </Table.Cell>
            </Table.Row>
            </Table.Body>
            </Table>
            <Table>
            <Table.Header>
              <Table.Row>
                <TableHeaderCell><Icon name="group" />Group Notifications</TableHeaderCell>
                <TableHeaderCell>Style</TableHeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
            <Table.Row>
              <Table.Cell>"Group Notifications" Title</Table.Cell>
              <Table.Cell>
              <Select
                clearable
                  name="dash_group_notifications_title"
                  options={
                    this.state.company.company_typographys && this.state.company.company_typographys.map((item) => {
                      return {text: item.title, value: item._id};
                    })}
                  value={this.state.style_data.dash_group_notifications_title}
                  onChange={(e, data) => {
                    this.setState({
                      style_data: { ...this.state.style_data, [data.name]: data.value },
                    });
                  }}
                />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>"Group Notifications" See All Button</Table.Cell>
              <Table.Cell>
              <Select
                clearable
                  name="dash_group_notifications_seeAll"
                  options={
                    this.state.company.company_typographys && this.state.company.company_typographys.map((item) => {
                      return {text: item.title, value: item._id};
                    })}
                  value={this.state.style_data.dash_group_notifications_seeAll}
                  onChange={(e, data) => {
                    this.setState({
                      style_data: { ...this.state.style_data, [data.name]: data.value },
                    });
                  }}
                />
              </Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell>"Group Notifications" Card</Table.Cell>
              <Table.Cell>
              <Select
                clearable
                  name="dash_group_notifications_card"
                  options={
                    this.state.company.company_typographys && this.state.company.company_typographys.map((item) => {
                      return {text: item.title, value: item._id};
                    })}
                  value={this.state.style_data.dash_group_notifications_card}
                  onChange={(e, data) => {
                    this.setState({
                      style_data: { ...this.state.style_data, [data.name]: data.value },
                    });
                  }}
                />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>"Group Notifications" Date</Table.Cell>
              <Table.Cell>
              <Select
                clearable
                  name="dash_group_notifications_date"
                  options={
                    this.state.company.company_typographys && this.state.company.company_typographys.map((item) => {
                      return {text: item.title, value: item._id};
                    })}
                  value={this.state.style_data.dash_group_notifications_date}
                  onChange={(e, data) => {
                    this.setState({
                      style_data: { ...this.state.style_data, [data.name]: data.value },
                    });
                  }}
                />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>"Group Notifications" Text</Table.Cell>
              <Table.Cell>
              <Select
                clearable
                  name="dash_group_notifications_text"
                  options={
                    this.state.company.company_typographys && this.state.company.company_typographys.map((item) => {
                      return {text: item.title, value: item._id};
                    })}
                  value={this.state.style_data.dash_group_notifications_text}
                  onChange={(e, data) => {
                    this.setState({
                      style_data: { ...this.state.style_data, [data.name]: data.value },
                    });
                  }}
                />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>"Group Notifications" Mark as Read</Table.Cell>
              <Table.Cell>
              <Select
                clearable
                  name="dash_group_notifications_mark_read"
                  options={
                    this.state.company.company_typographys && this.state.company.company_typographys.map((item) => {
                      return {text: item.title, value: item._id};
                    })}
                  value={this.state.style_data.dash_group_notifications_mark_read}
                  onChange={(e, data) => {
                    this.setState({
                      style_data: { ...this.state.style_data, [data.name]: data.value },
                    });
                  }}
                />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>"Group Notifications" Description</Table.Cell>
              <Table.Cell>
              <Select
                clearable
                  name="dash_group_notifications_more_text"
                  options={
                    this.state.company.company_typographys && this.state.company.company_typographys.map((item) => {
                      return {text: item.title, value: item._id};
                    })}
                  value={this.state.style_data.dash_group_notifications_more_text}
                  onChange={(e, data) => {
                    this.setState({
                      style_data: { ...this.state.style_data, [data.name]: data.value },
                    });
                  }}
                />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>"Group Notifications" Icon</Table.Cell>
              <Table.Cell>
              <Select
                clearable
                  name="dash_group_notifications_icon"
                  options={
                    this.state.company.company_typographys && this.state.company.company_typographys.map((item) => {
                      return {text: item.title, value: item._id};
                    })}
                  value={this.state.style_data.dash_group_notifications_icon}
                  onChange={(e, data) => {
                    this.setState({
                      style_data: { ...this.state.style_data, [data.name]: data.value },
                    });
                  }}
                />
              </Table.Cell>
            </Table.Row>
            </Table.Body>
            </Table>
            <Table>
            <Table.Header>
              <Table.Row>
                <TableHeaderCell><Icon name="medkit" />Health Alerts</TableHeaderCell>
                <TableHeaderCell>Style</TableHeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
            <Table.Row>
              <Table.Cell>"Health Alerts" Title</Table.Cell>
              <Table.Cell>
              <Select
                clearable
                  name="dash_health_alerts_title"
                  options={
                    this.state.company.company_typographys && this.state.company.company_typographys.map((item) => {
                      return {text: item.title, value: item._id};
                    })}
                  value={this.state.style_data.dash_health_alerts_title}
                  onChange={(e, data) => {
                    this.setState({
                      style_data: { ...this.state.style_data, [data.name]: data.value },
                    });
                  }}
                />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>"Health Alerts" See All Button</Table.Cell>
              <Table.Cell>
              <Select
                clearable
                  name="dash_health_alerts_seeAll"
                  options={
                    this.state.company.company_typographys && this.state.company.company_typographys.map((item) => {
                      return {text: item.title, value: item._id};
                    })}
                  value={this.state.style_data.dash_health_alerts_seeAll}
                  onChange={(e, data) => {
                    this.setState({
                      style_data: { ...this.state.style_data, [data.name]: data.value },
                    });
                  }}
                />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>"Health Alerts" Card</Table.Cell>
              <Table.Cell>
              <Select
                clearable
                  name="dash_health_alerts_card"
                  options={
                    this.state.company.company_typographys && this.state.company.company_typographys.map((item) => {
                      return {text: item.title, value: item._id};
                    })}
                  value={this.state.style_data.dash_health_alerts_card}
                  onChange={(e, data) => {
                    this.setState({
                      style_data: { ...this.state.style_data, [data.name]: data.value },
                    });
                  }}
                />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>"Health Alerts" Header</Table.Cell>
              <Table.Cell>
              <Select
                clearable
                  name="dash_health_alerts_header"
                  options={
                    this.state.company.company_typographys && this.state.company.company_typographys.map((item) => {
                      return {text: item.title, value: item._id};
                    })}
                  value={this.state.style_data.dash_health_alerts_header}
                  onChange={(e, data) => {
                    this.setState({
                      style_data: { ...this.state.style_data, [data.name]: data.value },
                    });
                  }}
                />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>"Health Alerts" Icon</Table.Cell>
              <Table.Cell>
              <Select
                clearable
                  name="dash_health_alerts_icon"
                  options={
                    this.state.company.company_typographys && this.state.company.company_typographys.map((item) => {
                      return {text: item.title, value: item._id};
                    })}
                  value={this.state.style_data.dash_health_alerts_icon}
                  onChange={(e, data) => {
                    this.setState({
                      style_data: { ...this.state.style_data, [data.name]: data.value },
                    });
                  }}
                />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>"Health Alerts" Text</Table.Cell>
              <Table.Cell>
              <Select
                clearable
                  name="dash_health_alerts_text"
                  options={
                    this.state.company.company_typographys && this.state.company.company_typographys.map((item) => {
                      return {text: item.title, value: item._id};
                    })}
                  value={this.state.style_data.dash_health_alerts_text}
                  onChange={(e, data) => {
                    this.setState({
                      style_data: { ...this.state.style_data, [data.name]: data.value },
                    });
                  }}
                />
              </Table.Cell>
            </Table.Row>

          </Table.Body>
          <Table.Footer>
            <Table.Row>
              <Table.Cell colSpan={2}>
                <Button
                style={{"--hover-backgroundColor": "#efefef"}}
                  color="orange"
                  icon="disk"
                  content="Save"
                  onClick={() => {
                    this.handleSubmit({
                      style_data: this.state.style_data,
                    });
                  }}
                />
              </Table.Cell>
            </Table.Row>
          </Table.Footer>
        </Table></> : style_menu === "global" ? 
        
        <Table>
            <Table.Header>
            <Table.Row>
              <TableHeaderCell><Icon name="cogs" />Global Styling</TableHeaderCell>
              <TableHeaderCell>Style</TableHeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.Cell>Site Logo (Current: {this.state.company.site_logo ? "Has Logo Uploaded" : "No Logo uploaded"})</Table.Cell>
              <Table.Cell>
              <input
                    type="file"
                    id="site_logo"
                    name="site_logo"
                    ref={(input) => (this.fileInput = input)}
                    onDragOver={() => this.setState({ isHovered: true })}
                    onDragLeave={() => this.setState({ isHovered: false })}
                    onChange={(e) => {
                      if (e.currentTarget.files[0] === undefined) {
                        return;
                      }
                  
                      let file = e.currentTarget.files[0];
                      let allowedTypes = ["image/jpeg", "image/png"];
                  
                      let isAllowed = false;
                  
                      for (let i in allowedTypes) {
                        let type = allowedTypes[i];
                        if (file.type === type) {
                          isAllowed = true;
                        }
                      }
                  
                      if (!isAllowed) {
                        alert("Only Image files are allowed to be uploaded.");
                        return;
                      }

                      let formData = new FormData();

                      formData.append("logo", file);
                      api.company.editCompanyLogo(this.state.company._id, formData)

                      }
                    }
                  />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Overall Background Colour</Table.Cell>
              <Table.Cell>
              <Input
                  name="global_background_color"
                  placeholder="#Value"
                  value={this.state.style_data.global_background_color}
                  // icon={<Icon name="paint brush" link onClick={() => this.setState({branding_data: {...this.state.branding_data, color_picker: "color"}})}/>}
                  onChange={(event) => {
                    this.setState({
                      style_data: { ...this.state.style_data, global_background_color: event.target.value },
                    });
                  }}
                />
                {/* {this.state.branding_data.color_picker === "color" &&  */}
                <HexAlphaColorPicker color={this.state.style_data.global_background_color} onChange={(value) => {
                  this.setState({
                    style_data: {
                      ...this.state.style_data,
                      global_background_color: value
                    }
                  })
                }} />
                {/* } */}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Default No Data box</Table.Cell>
              <Table.Cell>
              <Select
                  clearable
                  name="default_no_options"
                  options={
                    this.state.company.company_typographys && this.state.company.company_typographys.map((item) => {
                      return {text: item.title, value: item._id};
                    })}
                  value={this.state.style_data.default_no_options}
                  onChange={(e, data) => {
                    this.setState({
                      style_data: { ...this.state.style_data, [data.name]: data.value },
                    });
                  }}
                />
              </Table.Cell>
            </Table.Row>
          </Table.Body>
          <Table.Footer>
            <Table.Row>
              <Table.Cell colSpan={2}>
                <Button
                  color="orange"
                  icon="disk"
                  content="Save"
                  onClick={() => {
                    this.handleSubmit({
                      style_data: this.state.style_data,
                    });
                  }}
                />
              </Table.Cell>
            </Table.Row>
          </Table.Footer>
        </Table>
        
        : style_menu === "my_profile" ? <>
        
        <Table>
            <Table.Header>
            <Table.Row>
              <TableHeaderCell><Icon name="mobile" />My Profile Styling</TableHeaderCell>
              <TableHeaderCell>Style</TableHeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.Cell>My Profile Title</Table.Cell>
              <Table.Cell>
              <Select
                  clearable
                  name="my_profile_title"
                  options={
                    this.state.company.company_typographys && this.state.company.company_typographys.map((item) => {
                      return {text: item.title, value: item._id};
                    })}
                  value={this.state.style_data.my_profile_title}
                  onChange={(e, data) => {
                    this.setState({
                      style_data: { ...this.state.style_data, [data.name]: data.value },
                    });
                  }}
                />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Profile Links Box</Table.Cell>
              <Table.Cell>
              <Select
                  clearable
                  name="my_profile_box"
                  options={
                    this.state.company.company_typographys && this.state.company.company_typographys.map((item) => {
                      return {text: item.title, value: item._id};
                    })}
                  value={this.state.style_data.my_profile_box}
                  onChange={(e, data) => {
                    this.setState({
                      style_data: { ...this.state.style_data, [data.name]: data.value },
                    });
                  }}
                />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Profile Links Box Icon</Table.Cell>
              <Table.Cell>
              <Select
                  clearable
                  name="my_profile_box_icon"
                  options={
                    this.state.company.company_typographys && this.state.company.company_typographys.map((item) => {
                      return {text: item.title, value: item._id};
                    })}
                  value={this.state.style_data.my_profile_box_icon}
                  onChange={(e, data) => {
                    this.setState({
                      style_data: { ...this.state.style_data, [data.name]: data.value },
                    });
                  }}
                />
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>

        <Table>
            <Table.Header>
            <Table.Row>
              <TableHeaderCell><Icon name="chart line" />My Profile Readings</TableHeaderCell>
              <TableHeaderCell>Style</TableHeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.Cell>Readings Title</Table.Cell>
              <Table.Cell>
              <Select
                  clearable
                  name="default_no_options"
                  options={
                    this.state.company.company_typographys && this.state.company.company_typographys.map((item) => {
                      return {text: item.title, value: item._id};
                    })}
                  value={this.state.style_data.default_no_options}
                  onChange={(e, data) => {
                    this.setState({
                      style_data: { ...this.state.style_data, [data.name]: data.value },
                    });
                  }}
                />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Readings Text</Table.Cell>
              <Table.Cell>
              Select
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Reading Box</Table.Cell>
              <Table.Cell>
              Select
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Reading Box Title</Table.Cell>
              <Table.Cell>
              Select
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Reading Box Icon</Table.Cell>
              <Table.Cell>
              Select
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Reading Box Date</Table.Cell>
              <Table.Cell>
              Select
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>

        <Table>
            <Table.Header>
            <Table.Row>
              <TableHeaderCell><Icon name="cogs" />Settings</TableHeaderCell>
              <TableHeaderCell>Style</TableHeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.Cell>Setting</Table.Cell>
              <Table.Cell>
              Select
              </Table.Cell>
            </Table.Row>
          </Table.Body>
          <Table.Footer>
            <Table.Row>
              <Table.Cell colSpan={2}>
                <Button
                  color="orange"
                  icon="disk"
                  content="Save"
                  onClick={() => {
                    this.handleSubmit({
                      style_data: this.state.style_data,
                    });
                  }}
                />
              </Table.Cell>
            </Table.Row>
          </Table.Footer>
        </Table></>
        
        : style_menu === "services" ? <>
 
        <Table>
            <Table.Header>
            <Table.Row>
              <TableHeaderCell><Icon name="cogs" />Services Styling</TableHeaderCell>
              <TableHeaderCell>Style</TableHeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.Cell>Services Title</Table.Cell>
              <Table.Cell>
              <Select
                  clearable
                  name="services_title"
                  options={
                    this.state.company.company_typographys && this.state.company.company_typographys.map((item) => {
                      return {text: item.title, value: item._id};
                    })}
                  value={this.state.style_data.services_title}
                  onChange={(e, data) => {
                    this.setState({
                      style_data: { ...this.state.style_data, [data.name]: data.value },
                    });
                  }}
                />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Services Search Box</Table.Cell>
              <Table.Cell>
              <Select
                  clearable
                  name="services_search_box"
                  options={
                    this.state.company.company_typographys && this.state.company.company_typographys.map((item) => {
                      return {text: item.title, value: item._id};
                    })}
                  value={this.state.style_data.services_search_box}
                  onChange={(e, data) => {
                    this.setState({
                      style_data: { ...this.state.style_data, [data.name]: data.value },
                    });
                  }}
                />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Services Tabs</Table.Cell>
              <Table.Cell>
              <Select
                  clearable
                  name="services_tabs"
                  options={
                    this.state.company.company_typographys && this.state.company.company_typographys.map((item) => {
                      return {text: item.title, value: item._id};
                    })}
                  value={this.state.style_data.services_tabs}
                  onChange={(e, data) => {
                    this.setState({
                      style_data: { ...this.state.style_data, [data.name]: data.value },
                    });
                  }}
                />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Services Box</Table.Cell>
              <Table.Cell>
              <Select
                  clearable
                  name="services_box"
                  options={
                    this.state.company.company_typographys && this.state.company.company_typographys.map((item) => {
                      return {text: item.title, value: item._id};
                    })}
                  value={this.state.style_data.services_box}
                  onChange={(e, data) => {
                    this.setState({
                      style_data: { ...this.state.style_data, [data.name]: data.value },
                    });
                  }}
                />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Services Box Title</Table.Cell>
              <Table.Cell>
              <Select
                  clearable
                  name="services_box_title"
                  options={
                    this.state.company.company_typographys && this.state.company.company_typographys.map((item) => {
                      return {text: item.title, value: item._id};
                    })}
                  value={this.state.style_data.services_box_title}
                  onChange={(e, data) => {
                    this.setState({
                      style_data: { ...this.state.style_data, [data.name]: data.value },
                    });
                  }}
                />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Services Box Checkmark Icon</Table.Cell>
              <Table.Cell>
              <Select
                  clearable
                  name="services_box_checkmark"
                  options={
                    this.state.company.company_typographys && this.state.company.company_typographys.map((item) => {
                      return {text: item.title, value: item._id};
                    })}
                  value={this.state.style_data.services_box_checkmark}
                  onChange={(e, data) => {
                    this.setState({
                      style_data: { ...this.state.style_data, [data.name]: data.value },
                    });
                  }}
                />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Services Box Favorite Icon</Table.Cell>
              <Table.Cell>
              <Select
                  clearable
                  name="services_box_fav"
                  options={
                    this.state.company.company_typographys && this.state.company.company_typographys.map((item) => {
                      return {text: item.title, value: item._id};
                    })}
                  value={this.state.style_data.services_box_fav}
                  onChange={(e, data) => {
                    this.setState({
                      style_data: { ...this.state.style_data, [data.name]: data.value },
                    });
                  }}
                />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Services View Title</Table.Cell>
              <Table.Cell>
              <Select
                  clearable
                  name="services_view_title"
                  options={
                    this.state.company.company_typographys && this.state.company.company_typographys.map((item) => {
                      return {text: item.title, value: item._id};
                    })}
                  value={this.state.style_data.services_view_title}
                  onChange={(e, data) => {
                    this.setState({
                      style_data: { ...this.state.style_data, [data.name]: data.value },
                    });
                  }}
                />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Services View Website Link</Table.Cell>
              <Table.Cell>
              <Select
                  clearable
                  name="services_view_website"
                  options={
                    this.state.company.company_typographys && this.state.company.company_typographys.map((item) => {
                      return {text: item.title, value: item._id};
                    })}
                  value={this.state.style_data.services_view_website}
                  onChange={(e, data) => {
                    this.setState({
                      style_data: { ...this.state.style_data, [data.name]: data.value },
                    });
                  }}
                />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Services View Back Navigation</Table.Cell>
              <Table.Cell>
              <Select
                  clearable
                  name="services_view_back_nav"
                  options={
                    this.state.company.company_typographys && this.state.company.company_typographys.map((item) => {
                      return {text: item.title, value: item._id};
                    })}
                  value={this.state.style_data.services_view_back_nav}
                  onChange={(e, data) => {
                    this.setState({
                      style_data: { ...this.state.style_data, [data.name]: data.value },
                    });
                  }}
                />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Services View Favorite Button</Table.Cell>
              <Table.Cell>
              <Select
                  clearable
                  name="services_view_fav_button"
                  options={
                    this.state.company.company_typographys && this.state.company.company_typographys.map((item) => {
                      return {text: item.title, value: item._id};
                    })}
                  value={this.state.style_data.services_view_fav_button}
                  onChange={(e, data) => {
                    this.setState({
                      style_data: { ...this.state.style_data, [data.name]: data.value },
                    });
                  }}
                />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Services View Favorite Button Click</Table.Cell>
              <Table.Cell>
              <Select
                  clearable
                  name="services_view_fav_button_clicked"
                  options={
                    this.state.company.company_typographys && this.state.company.company_typographys.map((item) => {
                      return {text: item.title, value: item._id};
                    })}
                  value={this.state.style_data.services_view_fav_button_clicked}
                  onChange={(e, data) => {
                    this.setState({
                      style_data: { ...this.state.style_data, [data.name]: data.value },
                    });
                  }}
                />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Services View Main Button</Table.Cell>
              <Table.Cell>
              <Select
                  clearable
                  name="services_view_main_button"
                  options={
                    this.state.company.company_typographys && this.state.company.company_typographys.map((item) => {
                      return {text: item.title, value: item._id};
                    })}
                  value={this.state.style_data.services_view_main_button}
                  onChange={(e, data) => {
                    this.setState({
                      style_data: { ...this.state.style_data, [data.name]: data.value },
                    });
                  }}
                />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Services View Description Title</Table.Cell>
              <Table.Cell>
              <Select
                  clearable
                  name="services_view_desc_title"
                  options={
                    this.state.company.company_typographys && this.state.company.company_typographys.map((item) => {
                      return {text: item.title, value: item._id};
                    })}
                  value={this.state.style_data.services_view_desc_title}
                  onChange={(e, data) => {
                    this.setState({
                      style_data: { ...this.state.style_data, [data.name]: data.value },
                    });
                  }}
                />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Services View Description Text</Table.Cell>
              <Table.Cell>
              <Select
                  clearable
                  name="services_view_desc_text"
                  options={
                    this.state.company.company_typographys && this.state.company.company_typographys.map((item) => {
                      return {text: item.title, value: item._id};
                    })}
                  value={this.state.style_data.services_view_desc_text}
                  onChange={(e, data) => {
                    this.setState({
                      style_data: { ...this.state.style_data, [data.name]: data.value },
                    });
                  }}
                />
              </Table.Cell>
            </Table.Row>
          </Table.Body>
          <Table.Footer>
            <Table.Row>
              <Table.Cell colSpan={2}>
                <Button
                  color="orange"
                  icon="disk"
                  content="Save"
                  onClick={() => {
                    this.handleSubmit({
                      style_data: this.state.style_data,
                    });
                  }}
                />
              </Table.Cell>
            </Table.Row>
          </Table.Footer>
        </Table></>
        
        : style_menu === "resources" ? <>
 
        <Table>
            <Table.Header>
            <Table.Row>
              <TableHeaderCell><Icon name="clipboard" />Resources Styling</TableHeaderCell>
              <TableHeaderCell>Style</TableHeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.Cell>Resources Title</Table.Cell>
              <Table.Cell>
              <Select
                  clearable
                  name="resources_title"
                  options={
                    this.state.company.company_typographys && this.state.company.company_typographys.map((item) => {
                      return {text: item.title, value: item._id};
                    })}
                  value={this.state.style_data.resources_title}
                  onChange={(e, data) => {
                    this.setState({
                      style_data: { ...this.state.style_data, [data.name]: data.value },
                    });
                  }}
                />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Resources Search Box</Table.Cell>
              <Table.Cell>
              <Select
                  clearable
                  name="resources_search_box"
                  options={
                    this.state.company.company_typographys && this.state.company.company_typographys.map((item) => {
                      return {text: item.title, value: item._id};
                    })}
                  value={this.state.style_data.resources_search_box}
                  onChange={(e, data) => {
                    this.setState({
                      style_data: { ...this.state.style_data, [data.name]: data.value },
                    });
                  }}
                />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Resources Category</Table.Cell>
              <Table.Cell>
              <Select
                  clearable
                  name="resources_category"
                  options={
                    this.state.company.company_typographys && this.state.company.company_typographys.map((item) => {
                      return {text: item.title, value: item._id};
                    })}
                  value={this.state.style_data.resources_category}
                  onChange={(e, data) => {
                    this.setState({
                      style_data: { ...this.state.style_data, [data.name]: data.value },
                    });
                  }}
                />
              </Table.Cell>
            </Table.Row>
            {/*  
            <Table.Row>
              <Table.Cell>Resources Category Selected</Table.Cell>
              <Table.Cell>
              <Select
                  clearable
                  name="resources_category_selected"
                  options={
                    this.state.company.company_typographys && this.state.company.company_typographys.map((item) => {
                      return {text: item.title, value: item._id};
                    })}
                  value={this.state.style_data.resources_category_selected}
                  onChange={(e, data) => {
                    this.setState({
                      style_data: { ...this.state.style_data, [data.name]: data.value },
                    });
                  }}
                />
              </Table.Cell>
            </Table.Row>*/}
            <Table.Row>
              <Table.Cell>Resource Box</Table.Cell>
              <Table.Cell>
              <Select
                  clearable
                  name="resource_box"
                  options={
                    this.state.company.company_typographys && this.state.company.company_typographys.map((item) => {
                      return {text: item.title, value: item._id};
                    })}
                  value={this.state.style_data.resource_box}
                  onChange={(e, data) => {
                    this.setState({
                      style_data: { ...this.state.style_data, [data.name]: data.value },
                    });
                  }}
                />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Resource Box Title</Table.Cell>
              <Table.Cell>
              <Select
                  clearable
                  name="resource_box_title"
                  options={
                    this.state.company.company_typographys && this.state.company.company_typographys.map((item) => {
                      return {text: item.title, value: item._id};
                    })}
                  value={this.state.style_data.resource_box_title}
                  onChange={(e, data) => {
                    this.setState({
                      style_data: { ...this.state.style_data, [data.name]: data.value },
                    });
                  }}
                />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Resource View Title</Table.Cell>
              <Table.Cell>
              <Select
                  clearable
                  name="resource_view_title"
                  options={
                    this.state.company.company_typographys && this.state.company.company_typographys.map((item) => {
                      return {text: item.title, value: item._id};
                    })}
                  value={this.state.style_data.resource_view_title}
                  onChange={(e, data) => {
                    this.setState({
                      style_data: { ...this.state.style_data, [data.name]: data.value },
                    });
                  }}
                />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Resource View Category</Table.Cell>
              <Table.Cell>
              <Select
                  clearable
                  name="resource_view_category"
                  options={
                    this.state.company.company_typographys && this.state.company.company_typographys.map((item) => {
                      return {text: item.title, value: item._id};
                    })}
                  value={this.state.style_data.resource_view_category}
                  onChange={(e, data) => {
                    this.setState({
                      style_data: { ...this.state.style_data, [data.name]: data.value },
                    });
                  }}
                />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Resource View Back Navigation</Table.Cell>
              <Table.Cell>
              <Select
                  clearable
                  name="resource_view_back_nav"
                  options={
                    this.state.company.company_typographys && this.state.company.company_typographys.map((item) => {
                      return {text: item.title, value: item._id};
                    })}
                  value={this.state.style_data.resource_view_back_nav}
                  onChange={(e, data) => {
                    this.setState({
                      style_data: { ...this.state.style_data, [data.name]: data.value },
                    });
                  }}
                />
              </Table.Cell>
            </Table.Row>
          </Table.Body>
          <Table.Footer>
            <Table.Row>
              <Table.Cell colSpan={2}>
                <Button
                  color="orange"
                  icon="disk"
                  content="Save"
                  onClick={() => {
                    this.handleSubmit({
                      style_data: this.state.style_data,
                    });
                  }}
                />
              </Table.Cell>
            </Table.Row>
          </Table.Footer>
        </Table></>
        
        : style_menu === "contact" ? <>
 
        <Table>
            <Table.Header>
            <Table.Row>
              <TableHeaderCell><Icon name="mail" />Contact Styling</TableHeaderCell>
              <TableHeaderCell>Style</TableHeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.Cell>Contact Title</Table.Cell>
              <Table.Cell>
              <Select
                  clearable
                  name="contact_title"
                  options={
                    this.state.company.company_typographys && this.state.company.company_typographys.map((item) => {
                      return {text: item.title, value: item._id};
                    })}
                  value={this.state.style_data.contact_title}
                  onChange={(e, data) => {
                    this.setState({
                      style_data: { ...this.state.style_data, [data.name]: data.value },
                    });
                  }}
                />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Contact Text</Table.Cell>
              <Table.Cell>
              <Select
                  clearable
                  name="contact_text"
                  options={
                    this.state.company.company_typographys && this.state.company.company_typographys.map((item) => {
                      return {text: item.title, value: item._id};
                    })}
                  value={this.state.style_data.contact_text}
                  onChange={(e, data) => {
                    this.setState({
                      style_data: { ...this.state.style_data, [data.name]: data.value },
                    });
                  }}
                />
              </Table.Cell>
            </Table.Row>
          </Table.Body>
          <Table.Footer>
            <Table.Row>
              <Table.Cell colSpan={2}>
                <Button
                  color="orange"
                  icon="disk"
                  content="Save"
                  onClick={() => {
                    this.handleSubmit({
                      style_data: this.state.style_data,
                    });
                  }}
                />
              </Table.Cell>
            </Table.Row>
          </Table.Footer>
        </Table></>
        
        : <></>}
      </Segment>
    );
  }

  renderBrandingTypography() {

    return(
      <Segment basic>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Branding Typography</Table.HeaderCell>
              {/* <Table.HeaderCell>Font Family</Table.HeaderCell> */}
              <Table.HeaderCell>Font</Table.HeaderCell>
              {/* <Table.HeaderCell>Weight</Table.HeaderCell> 
              <Table.HeaderCell>Size</Table.HeaderCell>*/}
              <Table.HeaderCell>Colors</Table.HeaderCell>
              {/* <Table.HeaderCell>Text Color</Table.HeaderCell> 
              <Table.HeaderCell>Background Color</Table.HeaderCell>
              <Table.HeaderCell>Border Color</Table.HeaderCell>*/}
              <Table.HeaderCell>Border</Table.HeaderCell>
              <Table.HeaderCell>Padding</Table.HeaderCell>
              <Table.HeaderCell>Margin</Table.HeaderCell>
              <Table.HeaderCell>
                <Button.Group style={{ paddingLeft: "15%", float: "right" }}>
                  <Button
                    content={`Show Archived`}
                    positive={this.state.show_archived_res_cats === true}
                    onClick={() =>
                      this.setState(
                        {
                          show_archived_res_cats: true,
                        },
                        () => {
                          this.getData();
                        }
                      )
                    }
                  />
                  <Button
                    content={`Hide Archived`}
                    negative={this.state.show_archived_res_cats === false}
                    onClick={() =>
                      this.setState(
                        {
                          show_archived_res_cats: false,
                        },
                        () => {
                          this.getData();
                        }
                      )
                    }
                  />
                </Button.Group>
              </Table.HeaderCell>
            </Table.Row>
            <Table.Row>
              <Table.HeaderCell>
                <Input
                  name="title"
                  placeholder="Title"
                  value={this.state.branding_data.title}
                  onChange={(event) => {
                    this.setState({
                      branding_data: {
                        ...this.state.branding_data,
                        title: event.target.value,
                      }
                    });
                  }}
                />
                {this.state.branding_data.edit &&
                <Checkbox
                    label="Archived"
                    name="archived"
                    checked={this.state.branding_data.archived}
                    onChange={() => {
                      this.setState({
                        branding_data: {
                          ...this.state.branding_data,
                          archived: !this.state.branding_data.archived,
                        },
                      });
                    }}
                  />}
              </Table.HeaderCell>
              <Table.HeaderCell>
                Font: <br/>
                <Select
                    name="fontFamily"
                    options={[
                      {text: "Default", value: "default"},
                      {text: "Times New Roman", value: "Times New Roman"},
                      {text: "Comic Sans", value: "Comic Sans"},
                      {text: "Sans Seriff", value: "Sans Seriff"},
                      {text: "Wingdings", value: "Wingdings"},
                    ]}
                    value={this.state.branding_data.fontFamily ? this.state.branding_data.fontFamily : "default"}
                    onChange={(e, data) => {
                      this.setState({
                        branding_data: { ...this.state.branding_data, [data.name]: data.value },
                      });
                    }}
                  />
                {/*  
              </Table.HeaderCell>
              <Table.HeaderCell>*/}
                Weight: <br/>
                <Select
                    name="fontWeight"
                    options={[
                      {text: "Normal", value: "400"},
                      {text: "Bold", value: "600"},
                      {text: "Very Bold", value: "800"},
                    ]}
                    value={this.state.branding_data.fontWeight ? this.state.branding_data.fontWeight : "400"}
                    onChange={(e, data) => {
                      this.setState({
                        branding_data: { ...this.state.branding_data, [data.name]: data.value },
                      });
                    }}
                  />
                  {/*  
              </Table.HeaderCell>
              <Table.HeaderCell>*/}
                Size: <br/>
                <Input
                  name="fontSize"
                  placeholder="Size"
                  label={{ basic: true, content: 'px' }}
                  labelPosition='right'
                  value={this.state.branding_data.fontSize}
                  onChange={(event) => {
                    this.setState({
                      branding_data: {
                        ...this.state.branding_data,
                        fontSize: event.target.value,
                      }
                    });
                  }}
                />
                Icon: <br/>
                <Input
                  name="icon"
                  placeholder="Icon"
                  value={this.state.branding_data.icon}
                  onChange={(event) => {
                    this.setState({
                      branding_data: {
                        ...this.state.branding_data,
                        icon: event.target.value,
                      }
                    });
                  }}
                />
              </Table.HeaderCell>
              <Table.HeaderCell>
              <Input
                  name="color"
                  label="Text"
                  placeholder="#Value"
                  value={this.state.branding_data.color}
                  icon={<Icon name="paint brush" link onClick={() => this.setState({branding_data: {...this.state.branding_data, color_picker: "color"}})}/>}
                  onChange={(event) => {
                    this.setState({
                      branding_data: {
                        ...this.state.branding_data,
                        color: event.target.value,
                      }
                    });
                  }}
                />
                {this.state.branding_data.color_picker === "color" && 
                <HexAlphaColorPicker color={this.state.branding_data.color} onChange={(value) => {
                  this.setState({
                    branding_data: {
                      ...this.state.branding_data,
                      color: value
                    }
                  })
                }} />}
                {/*  
              </Table.HeaderCell>
              <Table.HeaderCell>*/}
              <Input
                  name="backgroundColor"
                  label="Background"
                  placeholder="#Value"
                  value={this.state.branding_data.backgroundColor}
                  icon={<Icon name="paint brush" link onClick={() => this.setState({branding_data: {...this.state.branding_data, color_picker: "backgroundColor"}})}/>}
                  onChange={(event) => {
                    this.setState({
                      branding_data: {
                        ...this.state.branding_data,
                        backgroundColor: event.target.value,
                      }
                    });
                  }}
                />
              {this.state.branding_data.color_picker === "backgroundColor" && 
                <HexAlphaColorPicker color={this.state.branding_data.backgroundColor} onChange={(value) => {
                  this.setState({
                    branding_data: {
                      ...this.state.branding_data,
                      backgroundColor: value
                    }
                  })
                }} />}
                {/*  
              </Table.HeaderCell>
              <Table.HeaderCell>*/}
              <Input
                  label="Border"
                  name="borderColor"
                  placeholder="#Value"
                  value={this.state.branding_data.borderColor}
                  icon={<Icon name="paint brush" link onClick={() => this.setState({branding_data: {...this.state.branding_data, color_picker: "borderColor"}})}/>}
                  onChange={(event) => {
                    this.setState({
                      branding_data: {
                        ...this.state.branding_data,
                        borderColor: event.target.value,
                      }
                    });
                  }}
                />
              {this.state.branding_data.color_picker === "borderColor" && 
                <HexAlphaColorPicker color={this.state.branding_data.borderColor} onChange={(value) => {
                  this.setState({
                    branding_data: {
                      ...this.state.branding_data,
                      borderColor: value
                    }
                  })
                }} />} <br/>
                Hovered: <br/>

                <Input
                  name="hoverColor"
                  label="Text"
                  placeholder="#Value"
                  value={this.state.branding_data.hoverColor}
                  icon={<Icon name="paint brush" link onClick={() => this.setState({branding_data: {...this.state.branding_data, color_picker: "hoverColor"}})}/>}
                  onChange={(event) => {
                    this.setState({
                      branding_data: {
                        ...this.state.branding_data,
                        hoverColor: event.target.value,
                      }
                    });
                  }}
                />
                {this.state.branding_data.color_picker === "hoverColor" && 
                <HexAlphaColorPicker color={this.state.branding_data.hoverColor} onChange={(value) => {
                  this.setState({
                    branding_data: {
                      ...this.state.branding_data,
                      hoverColor: value
                    }
                  })
                }} />}

                <Input
                  name="hoverBackgroundColor"
                  label="Background"
                  placeholder="#Value"
                  value={this.state.branding_data.hoverBackgroundColor}
                  icon={<Icon name="paint brush" link onClick={() => this.setState({branding_data: {...this.state.branding_data, color_picker: "hoverBackgroundColor"}})}/>}
                  onChange={(event) => {
                    this.setState({
                      branding_data: {
                        ...this.state.branding_data,
                        hoverBackgroundColor: event.target.value,
                      }
                    });
                  }}
                />
              {this.state.branding_data.color_picker === "hoverBackgroundColor" && 
                <HexAlphaColorPicker color={this.state.branding_data.hoverBackgroundColor} onChange={(value) => {
                  this.setState({
                    branding_data: {
                      ...this.state.branding_data,
                      hoverBackgroundColor: value
                    }
                  })
                }} />}

                <Input
                  label="Border"
                  name="hoverBorderColor"
                  placeholder="#Value"
                  value={this.state.branding_data.hoverBorderColor}
                  icon={<Icon name="paint brush" link onClick={() => this.setState({branding_data: {...this.state.branding_data, color_picker: "hoverBorderColor"}})}/>}
                  onChange={(event) => {
                    this.setState({
                      branding_data: {
                        ...this.state.branding_data,
                        hoverBorderColor: event.target.value,
                      }
                    });
                  }}
                />
              {this.state.branding_data.color_picker === "hoverBorderColor" && 
                <HexAlphaColorPicker color={this.state.branding_data.hoverBorderColor} onChange={(value) => {
                  this.setState({
                    branding_data: {
                      ...this.state.branding_data,
                      hoverBorderColor: value
                    }
                  })
                }} />}

              </Table.HeaderCell>
              <Table.HeaderCell>
                <Input
                  name="borderLeft"
                  placeholder="left"
                  label={{ basic: true, content: 'px' }}
                    labelPosition='right'
                  value={this.state.branding_data.borderLeft}
                  onChange={(event) => {
                    this.setState({
                      branding_data: {
                        ...this.state.branding_data,
                        borderLeft: event.target.value,
                      }
                    });
                  }}
                />
                <Input
                  name="borderTop"
                  placeholder="top"
                  label={{ basic: true, content: 'px' }}
                    labelPosition='right'
                  value={this.state.branding_data.borderTop}
                  onChange={(event) => {
                    this.setState({
                      branding_data: {
                        ...this.state.branding_data,
                        borderTop: event.target.value,
                      }
                    });
                  }}
                />
                <Input
                  name="borderRight"
                  placeholder="right"
                  label={{ basic: true, content: 'px' }}
                    labelPosition='right'
                  value={this.state.branding_data.borderRight}
                  onChange={(event) => {
                    this.setState({
                      branding_data: {
                        ...this.state.branding_data,
                        borderRight: event.target.value,
                      }
                    });
                  }}
                />
                <Input
                  name="borderBottom"
                  placeholder="bottom"
                  label={{ basic: true, content: 'px' }}
                    labelPosition='right'
                  value={this.state.branding_data.borderBottom}
                  onChange={(event) => {
                    this.setState({
                      branding_data: {
                        ...this.state.branding_data,
                        borderBottom: event.target.value,
                      }
                    });
                  }}
                />
                <Input
                  name="borderRadius"
                  placeholder="Radius"
                  label={{ basic: true, content: 'px' }}
                    labelPosition='right'
                  value={this.state.branding_data.borderRadius}
                  onChange={(event) => {
                    this.setState({
                      branding_data: {
                        ...this.state.branding_data,
                        borderRadius: event.target.value,
                      }
                    });
                  }}
                />
              </Table.HeaderCell>
              <Table.HeaderCell>
              <Input
                    name="paddingLeft"
                    placeholder="left"
                    label={{ basic: true, content: 'px' }}
                    labelPosition='right'
                    value={this.state.branding_data.paddingLeft}
                    onChange={(event) => {
                      this.setState({
                        branding_data: {
                          ...this.state.branding_data,
                          paddingLeft: event.target.value,
                        }
                      });
                    }}
                  />
                <Input
                    name="paddingTop"
                    placeholder="top"
                    label={{ basic: true, content: 'px' }}
                    labelPosition='right'
                    value={this.state.branding_data.paddingTop}
                    onChange={(event) => {
                      this.setState({
                        branding_data: {
                          ...this.state.branding_data,
                          paddingTop: event.target.value,
                        }
                      });
                    }}
                  />
                <Input
                    name="paddingRight"
                    placeholder="right"
                    label={{ basic: true, content: 'px' }}
                    labelPosition='right'
                    value={this.state.branding_data.paddingRight}
                    onChange={(event) => {
                      this.setState({
                        branding_data: {
                          ...this.state.branding_data,
                          paddingRight: event.target.value,
                        }
                      });
                    }}
                  />
                <Input
                    name="paddingBottom"
                    placeholder="bottom"
                    label={{ basic: true, content: 'px' }}
                    labelPosition='right'
                    value={this.state.branding_data.paddingBottom}
                    onChange={(event) => {
                      this.setState({
                        branding_data: {
                          ...this.state.branding_data,
                          paddingBottom: event.target.value,
                        }
                      });
                    }}
                  />
              </Table.HeaderCell>
              <Table.HeaderCell>
              <Input
                    name="marginLeft"
                    placeholder="left"
                    label={{ basic: true, content: 'px' }}
                    labelPosition='right'
                    value={this.state.branding_data.marginLeft}
                    onChange={(event) => {
                      this.setState({
                        branding_data: {
                          ...this.state.branding_data,
                          marginLeft: event.target.value,
                        }
                      });
                    }}
                  />
                <Input
                    name="marginTop"
                    placeholder="top"
                    label={{ basic: true, content: 'px' }}
                    labelPosition='right'
                    value={this.state.branding_data.marginTop}
                    onChange={(event) => {
                      this.setState({
                        branding_data: {
                          ...this.state.branding_data,
                          marginTop: event.target.value,
                        }
                      });
                    }}
                  />
                <Input
                    name="marginRight"
                    placeholder="right"
                    label={{ basic: true, content: 'px' }}
                    labelPosition='right'
                    value={this.state.branding_data.marginRight}
                    onChange={(event) => {
                      this.setState({
                        branding_data: {
                          ...this.state.branding_data,
                          marginRight: event.target.value,
                        }
                      });
                    }}
                  />
                <Input
                    name="marginBottom"
                    placeholder="bottom"
                    label={{ basic: true, content: 'px' }}
                    labelPosition='right'
                    value={this.state.branding_data.marginBottom}
                    onChange={(event) => {
                      this.setState({
                        branding_data: {
                          ...this.state.branding_data,
                          marginBottom: event.target.value,
                        }
                      });
                    }}
                  />
              </Table.HeaderCell>
              <Table.HeaderCell>
                  <Button
                    disabled={!this.state.branding_data.title}
                    content={`${this.state.branding_data.edit ? "Edit" : "Add"} Typography`}
                    color={this.state.branding_data.edit ? "orange" : "green"}
                    icon={this.state.branding_data.edit ? "pencil" :"plus"}
                    onClick={() => {
                      if(this.state.branding_data.edit) {
                        api.company.editCompanyTypography(this.state.company._id,this.state.branding_data.edit, this.state.branding_data)
                          .then((res) => {
                            this.setState({
                              branding_data: {title: ""}
                            });
                            this.getData();
                            toast({
                              type: "success",
                              icon: "pencil",
                              title: <h3>Company Typography Edited</h3>,
                              description: (
                                <span>
                                  <p>Successfully edited Typography</p>
                                </span>
                              ),
                              time: 4000,
                            });
                          });
                      }else{
                        api.company.addCompanyTypography(
                          this.state.company._id,
                          this.state.branding_data
                        )
                          .then((res) => {
                            this.setState({
                              branding_data: {}
                            });
                            this.getData();
                            toast({
                              type: "success",
                              icon: "plus",
                              title: <h3>Company Typography Added</h3>,
                              description: (
                                <span>
                                  <p>Successfully added Typography</p>
                                </span>
                              ),
                              time: 4000,
                            });
                          });
                      }
                      
                    }}
                  />
                </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {this.state.company.company_typographys &&
              this.state.company.company_typographys
                .filter((f) => {
                  if (f.archived) {
                    if (this.state.show_archived_res_cats) {
                      return f;
                    }
                  } else {
                    return f;
                  }
                })
                .map((data) => {
                  return (
                    <Table.Row>
                      <Table.Cell>
                        {data.title}{" "}
                        {data.archived && (
                          <Label content="Archived" color="red" />
                        )}
                      </Table.Cell>
                      <Table.Cell>
                        Font: {data.fontFamily} <br/>
                        Weight: {data.fontWeight} <br/>
                        Size: {data.fontSize}px <br />
                        Icon: {data.icon}
                      </Table.Cell>
                      <Table.Cell>
                        Text: {data.color} (Hover: {data.backgroundColor}) <br/>
                        Background: {data.backgroundColor} (Hover: {data.hoverBackgroundColor})<br/>
                        Border: {data.borderColor} (Hover: {data.hoverBorderColor})<br/>
                      </Table.Cell>
                      <Table.Cell>
                        Left: {data.borderLeft}px <br/>
                        Top: {data.borderTop}px <br/>
                        Right: {data.borderRight}px <br/>
                        Bottom: {data.borderBottom}px <br/>
                        Radius: {data.borderRadius}px
                      </Table.Cell>
                      <Table.Cell>
                        Left: {data.paddingLeft}px <br/>
                        Top: {data.paddingTop}px <br/>
                        Right: {data.paddingRight}px <br/>
                        Bottom: {data.paddingBottom}px
                      </Table.Cell>
                      <Table.Cell>
                        Left: {data.marginLeft}px <br/>
                        Top: {data.marginTop}px <br/>
                        Right: {data.marginRight}px <br/>
                        Bottom: {data.marginBottom}px
                      </Table.Cell>
                      <Table.Cell>
                        <Button content="Edit" color="orange" icon="pencil" onClick={() => {
                          this.setState({
                              branding_data: {
                                ...data,
                              edit: data._id,
                            }
                          });
                        }} />
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
          </Table.Body>
        </Table>
      </Segment>
    );
  }
  
  renderUploadFile() {
    const close = () => {
      this.setState({ upload_file: false, submit_load: false });
    };

    const handleSubmit = (data) => {
      api.resources
        .uploadFile(this.props.match.params.id, {
          type: this.state.file_type,
          file: data.file,
          description: data.description,
          selectedFile: data.selectedFile
        })
        .then(() => {
          const errors = this.validate(this.state.data);
          this.setState({ errors: errors });
          if (Object.keys(errors).length === 0) {
            this.setState({ submit_load: true });
            api.resources
              .edit(this.props.match.params.id, {
                ...this.state.data,
                wysiwyg_data: this.state.data.wysiwyg_data
                  ? draftToHtml(
                      convertToRaw(
                        this.state.data.wysiwyg_data.getCurrentContent()
                      )
                    )
                  : "",
                resource_category:
                  this.state.data.resource_category === ""
                    ? null
                    : this.state.data.resource_category,
              })
              .then(() => {
                close();
                this._getData();
              })
              .catch(() => {
                close();
              });
          }
        })
        .catch((e) => {
          this.setState({ upload_file: false });
          console.log(e);
        });
    };

    return (
      <Modal
        open={this.state.upload_file === true}
        onClose={close}
        centered={false}
        size="large"
        closeOnDimmerClick={true}
      >
        <Header icon="upload" content="Upload File" />
        <Modal.Content>
          <UploadFileForm submit={handleSubmit} close={close} max_upload={10} />
        </Modal.Content>
      </Modal>
    );
  }

  renderResources() {
    return(
      <Segment basic>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Resource</Table.HeaderCell>
              <Table.HeaderCell>Short Description</Table.HeaderCell>
              <Table.HeaderCell>Category</Table.HeaderCell>
              <Table.HeaderCell>
                <Button.Group style={{ paddingLeft: "15%", float: "right" }}>
                  <Button
                    content={`Show Archived`}
                    positive={this.state.show_archived_res === true}
                    onClick={() =>
                      this.setState(
                        {
                          show_archived_res: true,
                        },
                        () => {
                          this.getData();
                        }
                      )
                    }
                  />
                  <Button
                    content={`Hide Archived`}
                    negative={this.state.show_archived_res === false}
                    onClick={() =>
                      this.setState(
                        {
                          show_archived_res: false,
                        },
                        () => {
                          this.getData();
                        }
                      )
                    }
                  />
                </Button.Group>
              </Table.HeaderCell>
            </Table.Row>
            <Table.Row>
              <Table.HeaderCell>
                <Input
                  name="title"
                  placeholder="Enter Title"
                  value={this.state.resource_data.title}
                  onChange={(event) => {
                    this.setState({
                      resource_data: {
                        ...this.state.resource_data,
                        title: event.target.value,
                      }
                    });
                  }}
                />
              </Table.HeaderCell>
              <Table.HeaderCell>
                <Input
                  name="description_short"
                  placeholder="Enter Short Description"
                  value={this.state.resource_data.description_short}
                  onChange={(event) => {
                    this.setState({
                      resource_data: {
                        ...this.state.resource_data,
                        description_short: event.target.value,
                      }
                    });
                  }}
                />
              </Table.HeaderCell>
              <Table.HeaderCell>
                <Select
                    clearable
                    name="resource_category"
                    options={this.state.company.resource_cats.map((category) => {
                      return {
                        text: category.title,
                        value: category._id
                      }
                    })}
                    value={this.state.resource_data.resource_category}
                    onChange={(e, data) => {
                      this.setState({
                        resource_data: { ...this.state.resource_data, [data.name]: data.value },
                      });
                    }}
                  />
              </Table.HeaderCell>
              <Table.HeaderCell>
                  <Button
                  disabled={!this.state.resource_data.title || !this.state.resource_data.description_short}
                    content="Add Resource"
                    color="green"
                    icon="plus"
                    onClick={() => {
                      api.resources.add({
                        ...this.state.resource_data,
                        company: this.state.company._id
                      })
                        .then((res) => {
                          this.setState({
                            resource_data: {}
                          });
                          this.getData();
                          toast({
                            type: "success",
                            icon: "plus",
                            title: <h3>Resource Added</h3>,
                            description: (
                              <span>
                                <p>Successfully added Resource</p>
                              </span>
                            ),
                            time: 4000,
                          });
                        });
                    }}
                  />
                </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {this.state.company.resources &&
              this.state.company.resources
                .filter((f) => {
                  if (f.archived) {
                    if (this.state.show_archived_res) {
                      return f;
                    }
                  } else {
                    return f;
                  }
                })
                .map((data) => {
                  let category = this.state.company.resource_cats && this.state.company.resource_cats.filter(e => e._id == data.resource_category);
                  return (
                    <Table.Row>
                      <Table.Cell>
                        {data.title}{" "}
                        {data.archived && (
                          <Label content="Archived" color="red" />
                        )}
                      </Table.Cell>
                      <Table.Cell>
                        {data.description_short}
                      </Table.Cell>
                      <Table.Cell>
                        {category && category[0] && category[0].title}
                      </Table.Cell>
                      <Table.Cell>
                        <Button color="orange" content="Edit" icon="pencil" onClick={() => this.props.history.push(`/company/${this.props.match.params.id}/settings/resource/${data._id}`)} />
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
          </Table.Body>
        </Table>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Associated Resources</Table.HeaderCell>
              <Table.HeaderCell/>
              <Table.HeaderCell>
                <Button.Group style={{ paddingLeft: "15%", float: "right" }}>
                  {/*  
                  <Button
                    content={`Show Archived`}
                    positive={this.state.show_archived_forms === true}
                    onClick={() =>
                      this.setState(
                        {
                          show_archived_forms: true,
                        },
                        () => {
                          this.getData();
                        }
                      )
                    }
                  />
                  <Button
                    content={`Hide Archived`}
                    negative={this.state.show_archived_forms === false}
                    onClick={() =>
                      this.setState(
                        {
                          show_archived_forms: false,
                        },
                        () => {
                          this.getData();
                        }
                      )
                    }
                  /> */}
                </Button.Group>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {this.state.company.associated_resources &&
              this.state.company.associated_resources.map((data) => {
                let category = this.state.company.associated_resource_cats && this.state.company.associated_resource_cats.filter(e => e._id == data.resource_category);
                return (
                  <Table.Row>
                    <Table.Cell>
                      {data.title}{" "}
                      {data.archived && (
                        <Label content="Archived" color="red" />
                      )}
                    </Table.Cell>
                    <Table.Cell>
                      {data.description_short}
                    </Table.Cell>
                    <Table.Cell>
                      {category && category[0] && category[0].title}
                    </Table.Cell>
                    <Table.Cell>
                      
                    </Table.Cell>
                </Table.Row>
                );
              })}
          </Table.Body>
        </Table>

        {/*  
        <Segment className="no-marg">
          <Header as={"h4"} content="Edit Resource" />
          {this.renderUploadFile()}
          <Form>
            <Form.Group widths="equal">
              <Form.Input
                name="title"
                label="Resource Name"
                value={this.state.data.title}
                onChange={this.handleChange}
                placeholder="Title"
                error={this.state.errors.title}
              />
              <Form.Dropdown
                name="resource_category"
                label="Resource Category"
                value={this.state.data.resource_category}
                onChange={this.handleDropdownChange}
                options={this.state.resource_categories}
                error={this.state.errors.resource_categories}
                selection
                placeholder="Resource Category"
                clearable
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.TextArea
                name="description_short"
                label="Description (Short)"
                value={this.state.data.description_short}
                onChange={this.handleChange}
                placeholder="Description (Short)"
                error={this.state.errors.description_short}
              />
              {this.state.data.display_type !== "WYSIWYG" && (
                <Form.TextArea
                  name="description_long"
                  label="Description (Long)"
                  value={this.state.data.description_long}
                  onChange={this.handleChange}
                  placeholder="Description (Long)"
                />
              )}
            </Form.Group>
            <Divider />
            <Grid>
              <Grid.Row columns="2">
                <Grid.Column>
                  <Header content="Icon Image" textAlign="center" />
                  {this.state.icon_url !== "" ? (
                    <Segment
                      basic
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <Image
                        src={AppConfig.api_server + this.state.icon_url}
                        size="medium"
                        className="pointer preview-image"
                        onClick={() => {
                          this.setState({
                            upload_file: true,
                            file_type: "Icon",
                          });
                        }}
                      />
                    </Segment>
                  ) : this.state.loading === false &&
                    this.state.icon_url === "" ? (
                    <Form.Button
                      fluid
                      color="green"
                      content="Upload Icon"
                      icon="image"
                      onClick={() => {
                        this.setState({ upload_file: true, file_type: "Icon" });
                      }}
                    />
                  ) : (
                    <Segment padded="very" basic>
                      <Loader active />
                    </Segment>
                  )}
                </Grid.Column>
                <Grid.Column>
                  <Header content="Banner Image" textAlign="center" />
                  {this.state.banner_url !== "" ? (
                    <Segment
                      basic
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <Image
                        src={AppConfig.api_server + this.state.banner_url}
                        size="medium"
                        className="pointer preview-image"
                        onClick={() => {
                          this.setState({
                            upload_file: true,
                            file_type: "Banner",
                          });
                        }}
                      />
                    </Segment>
                  ) : this.state.loading === false &&
                    this.state.banner_url === "" ? (
                    <Form.Button
                      fluid
                      color="green"
                      content="Upload Banner"
                      icon="image"
                      onClick={() => {
                        this.setState({
                          upload_file: true,
                          file_type: "Banner",
                        });
                      }}
                    />
                  ) : (
                    <Segment padded="very" basic>
                      <Loader active />
                    </Segment>
                  )}
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <Divider />
            <Form.Dropdown
              name="display_type"
              label="Additional Data Display Type"
              value={this.state.data.display_type}
              onChange={this.handleDropdownChange}
              options={[
                { text: "None", value: "None" },
                { text: "PDF", value: "PDF" },
                { text: "WYSIWYG", value: "WYSIWYG" },
              ]}
              error={this.state.errors.display_type}
              selection
              placeholder="Display Type"
            />
            {this.state.data.display_type === "PDF" ? (
              <React.Fragment>
                {this.state.pdf_url !== "" ? (
                  <React.Fragment>
                    <Form.Button
                      fluid
                      color="green"
                      content="Replace PDF"
                      icon="image"
                      onClick={() => {
                        this.setState({
                          upload_file: true,
                          file_type: "PDF",
                        });
                      }}
                    />
                    {this.state.loading === false && (
                      <embed
                        src={AppConfig.api_server + this.state.pdf_url}
                        width="100%"
                        height="800px"
                      />
                    )}
                  </React.Fragment>
                ) : this.state.loading === false &&
                  this.state.pdf_url === "" ? (
                  <Form.Button
                    fluid
                    color="green"
                    content="Upload PDF"
                    icon="image"
                    onClick={() => {
                      this.setState({ upload_file: true, file_type: "PDF" });
                    }}
                  />
                ) : (
                  <Segment padded="very" basic>
                    <Loader active />
                  </Segment>
                )}
              </React.Fragment>
            ) : this.state.data.display_type === "WYSIWYG" ? (
              <React.Fragment>
                <Editor
                  editorState={this.state.data.wysiwyg_data}
                  onEditorStateChange={(editorState) => {
                    this.setState({
                      data: {
                        ...this.state.data,
                        wysiwyg_data: editorState,
                      },
                      temporary_html: "",
                    });
                  }}
                  editorStyle={{
                    borderLeft: "1px solid #F1F1F1",
                    borderRight: "1px solid #F1F1F1",
                    borderBottom: "1px solid #F1F1F1",
                  }}
                  toolbarStyle={{ marginBottom: "0px" }}
                  toolbar={{
                    image: {
                      uploadCallback: this.handleWYSIWYGUpload,
                      previewImage: true,
                      urlEnabled: false,
                      alignmentEnabled: false,
                      defaultSize: { width: "100%", height: "100%" },
                    },
                  }}
                />
                <Divider hidden />
                <Header content="Raw HTML View" textAlign="center" />
                <p>
                  WARNING: If you don't know how to write HTML it is reccomended
                  that you use the WYSIWYG editor above as directly modifying
                  the data could break the code.
                </p>
                <Form.TextArea
                  rows="10"
                  value={
                    this.state.temporary_html === ""
                      ? this.state.data.wysiwyg_data
                        ? draftToHtml(
                            convertToRaw(
                              this.state.data.wysiwyg_data.getCurrentContent()
                            )
                          )
                        : ""
                      : this.state.temporary_html
                  }
                  onFocus={() => {
                    if (this.state.temporary_html === "") {
                      this.setState({
                        temporary_html: this.state.data.wysiwyg_data
                          ? draftToHtml(
                              convertToRaw(
                                this.state.data.wysiwyg_data.getCurrentContent()
                              )
                            )
                          : "",
                      });
                    }
                  }}
                  onChange={(e) => {
                    this.setState({ temporary_html: e.target.value });
                  }}
                />
                {this.state.temporary_html !== "" && (
                  <Form.Button
                    fluid
                    color="green"
                    icon="code"
                    content="Save Direct HTML Edit Changes"
                    onClick={() => {
                      const blocksFromHtml = htmlToDraft(
                        this.state.temporary_html
                      );
                      const { contentBlocks, entityMap } = blocksFromHtml;
                      const contentState = ContentState.createFromBlockArray(
                        contentBlocks,
                        entityMap
                      );

                      let converted_html =
                        EditorState.createWithContent(contentState);

                      this.setState({
                        data: {
                          ...this.state.data,
                          wysiwyg_data: converted_html,
                        },
                        temporary_html: "",
                      });
                    }}
                  />
                )}
              </React.Fragment>
            ) : (
              <React.Fragment />
            )}
            <Divider />
            <Form.Checkbox
              label="Archived"
              name="archived"
              checked={this.state.data.archived}
              onClick={() => {
                this.setState({
                  data: {
                    ...this.state.data,
                    archived: !this.state.data.archived,
                  },
                });
              }}
            />
            <Form.Group widths="equal">
              <Form.Button
                floated="left"
                color="red"
                onClick={() => this.props.history.push(`/settings/resources`)}
              >
                <Icon name="close" />
                Cancel
              </Form.Button>
              <Form.Button
                floated="right"
                color="green"
                onClick={() => this.handleSubmit()}
                loading={this.state.submit_load}
              >
                <Icon name="plus" />
                Submit
              </Form.Button>
            </Form.Group>
          </Form>
        </Segment>*/}

      </Segment>
    );
  }

  onFileLoad = (e) => {
    if (e.currentTarget.files[0] === undefined) {
      return;
    }

    let file = e.currentTarget.files[0];
    let allowedTypes = ["image/jpeg", "image/png"];

    let isAllowed = false;

    for (let i in allowedTypes) {
      let type = allowedTypes[i];
      if (file.type === type) {
        isAllowed = true;
      }
    }

    if (!isAllowed) {
      alert("Only Image files are allowed to be uploaded.");
      return;
    }

    this.setState(
      {
        splash_data: {
          ...this.state.splash_data,
          splash_image: file,
        },
        uploaded_file_link: null,
        preview_upload: URL.createObjectURL(file),
      },
      () => {
        URL.revokeObjectURL(file);
      }
    );
  };

  renderAppSplashScreens() {

    return(
      <Segment basic>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Title</Table.HeaderCell>
              <Table.HeaderCell>Description</Table.HeaderCell>
              <Table.HeaderCell>File</Table.HeaderCell>
              <Table.HeaderCell>
                <Button.Group style={{ paddingLeft: "15%", float: "right" }}>
                  <Button
                    content={`Show Archived`}
                    positive={this.state.show_archived_ss === true}
                    onClick={() =>
                      this.setState(
                        {
                          show_archived_ss: true,
                        },
                        () => {
                          this.getData();
                        }
                      )
                    }
                  />
                  <Button
                    content={`Hide Archived`}
                    negative={this.state.show_archived_ss === false}
                    onClick={() =>
                      this.setState(
                        {
                          show_archived_ss: false,
                        },
                        () => {
                          this.getData();
                        }
                      )
                    }
                  />
                </Button.Group>
              </Table.HeaderCell>
            </Table.Row>
            <Table.Row>
              <Table.HeaderCell>
                <Input
                  name="title"
                  placeholder="Enter Notice Title"
                  value={this.state.splash_data.title}
                  onChange={(event) => {
                    this.setState({
                      splash_data: {
                        ...this.state.splash_data,
                        title: event.target.value,
                      }
                    });
                  }}
                />
                {this.state.splash_data.edit && 
                <Checkbox
                  label="Archived"
                  name="archived"
                  checked={this.state.splash_data.archived}
                  onChange={() => {
                    this.setState({
                      splash_data: {
                        ...this.state.splash_data,
                        archived: !this.state.splash_data.archived,
                      },
                    });
                  }}
                />}
              </Table.HeaderCell>
              <Table.HeaderCell>
                <Input
                  name="description"
                  placeholder="Enter Description"
                  value={this.state.splash_data.description}
                  onChange={(event) => {
                    this.setState({
                      splash_data: {
                        ...this.state.splash_data,
                        description: event.target.value,
                      }
                    });
                  }}
                />
              </Table.HeaderCell>
              <Table.HeaderCell>
              <div
                  className={`draggable-container ${
                    this.state.isHovered && "draggable-container-hovered"
                  }`}
                >
                  <input
                    type="file"
                    id="file-browser-input"
                    name="file-browser-input"
                    ref={(input) => (this.fileInput = input)}
                    onChange={(e) => this.onFileLoad(e)}
                  />
                  {/*  
                  <div className="helper-text">
                    <p>
                      Drop Picture Here Or <br />
                      Click To Browse (<b>MAX 5MB</b>)
                    </p>
                  </div>*/}
                </div>
              </Table.HeaderCell>
              <Table.HeaderCell>
                  <Button
                    disable
                    content={`${this.state.splash_data.edit ? "Edit" : "Add"} Screen`}
                    color={this.state.splash_data.edit ? "orange" : "green"}
                    icon={this.state.splash_data.edit ? "pencil" : "plus"}
                    onClick={() => {

                      // const errors = this.validate(this.state.data);
                      const errors = [];
                      this.setState({ errors: errors });
                      if (Object.keys(errors).length === 0) {
                        this.setState({ loading: true });

                        let formData = new FormData();
                        let keys = Object.keys(this.state.splash_data);

                        for (let i in keys) {
                          formData.append(keys[i], this.state.splash_data[keys[i]]);    
                        }
                        formData.append("company", this.state.company._id);
                        console.log("🚀 ~ file: companySettings.js ~ line 1253 ~ CompanySettings ~ renderAppSplashScreens ~ formData", formData)

                        if(this.state.splash_data.edit){
                          api.mobile_splash_screens
                          .update(this.state.splash_data.edit,formData)
                          .then(() => {
                            this.setState({
                              splash_data: {title: "",description: ""}
                            });
                            this.getData();
                            toast({
                              type: "success",
                              icon: "pencil",
                              title: <h3>Splash Screen Edited</h3>,
                              description: (
                                <span>
                                  <p>Successfully edited Screen</p>
                                </span>
                              ),
                              time: 4000,
                            });
                          });
                        }else{
                          api.mobile_splash_screens
                          .add(formData)
                          .then(() => {
                            this.setState({
                              splash_data: {}
                            });
                            this.getData();
                            toast({
                              type: "success",
                              icon: "plus",
                              title: <h3>Notice Added</h3>,
                              description: (
                                <span>
                                  <p>Successfully added Form</p>
                                </span>
                              ),
                              time: 4000,
                            });
                          });
                        }
 
                      }

                    }}
                  />
                </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {this.state.company.app_splashs &&
              this.state.company.app_splashs
                .filter((f) => {
                  if (f.archived) {
                    if (this.state.show_archived_ss) {
                      return f;
                    }
                  } else {
                    return f;
                  }
                })
                .map((screen) => {
                  console.log("screen: ",screen);
                  return (
                    <Table.Row>
                      <Table.Cell>
                        {screen.title}{" "}
                        {screen.archived && (
                          <Label content="Archived" color="red" />
                        )}
                      </Table.Cell>
                      <Table.Cell>{screen.description}</Table.Cell>
                      
                      <Table.Cell>
                      <Image
                        size='small'
                        onClick={() => {
                          console.log("click");
                          /*
                          this.setState({
                            data: { ...this.state.data, splash_image: null },
                            uploaded_file_link: null,
                          });*/
                        }}
                        className="preview-image"
                        src={_appConfig.api_server + screen.splash_image}
                        /*
                        src={
                          this.state.uploaded_file_link !== null
                            ? AppConfig.api_server + this.state.uploaded_file_link
                            : this.state.preview_upload
                        }*/
                      />
                      </Table.Cell>
                      <Table.Cell><Button content="Edit" color="orange" icon="pencil" onClick={() => {
                        this.setState({
                          splash_data: {
                            title: screen.title,
                            archived: screen.archived,
                            description: screen.description,
                            edit: screen._id,
                            archived: screen.archived
                          }
                        })
                      }} /></Table.Cell>
                    </Table.Row>
                  );
                })}
          </Table.Body>
        </Table>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Associated Splash Screen</Table.HeaderCell>
              <Table.HeaderCell>Description</Table.HeaderCell>
              <Table.HeaderCell>Image</Table.HeaderCell>
              <Table.HeaderCell>
                <Button.Group style={{ paddingLeft: "15%", float: "right" }}>
                  {/*  
                  <Button
                    content={`Show Archived`}
                    positive={this.state.show_archived_forms === true}
                    onClick={() =>
                      this.setState(
                        {
                          show_archived_forms: true,
                        },
                        () => {
                          this.getData();
                        }
                      )
                    }
                  />
                  <Button
                    content={`Hide Archived`}
                    negative={this.state.show_archived_forms === false}
                    onClick={() =>
                      this.setState(
                        {
                          show_archived_forms: false,
                        },
                        () => {
                          this.getData();
                        }
                      )
                    }
                  /> */}
                </Button.Group>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {this.state.company.associated_app_splashs &&
              this.state.company.associated_app_splashs
                .filter((f) => {
                  if (f.archived) {
                    if (this.state.show_archived_splashs) {
                      return f;
                    }
                  } else {
                    return f;
                  }
                })
                .map((screen) => {
                  console.log("screen: ",screen);
                  return (
                    <Table.Row>
                      <Table.Cell>
                        {screen.title}{" "}
                        {screen.archived && (
                          <Label content="Archived" color="red" />
                        )}
                      </Table.Cell>
                      <Table.Cell>{screen.description}</Table.Cell>
                      <Table.Cell>{screen.file}</Table.Cell>
                      <Table.Cell>
                      <Image
                        size='small'
                        onClick={() => {
                          console.log("click");
                          /*
                          this.setState({
                            data: { ...this.state.data, splash_image: null },
                            uploaded_file_link: null,
                          });*/
                        }}
                        className="preview-image"
                        src={_appConfig.api_server + screen.splash_image}
                        /*
                        src={
                          this.state.uploaded_file_link !== null
                            ? AppConfig.api_server + this.state.uploaded_file_link
                            : this.state.preview_upload
                        }*/
                      />
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
          </Table.Body>
        </Table>
      </Segment>
    );
  }

  renderForms() {
    let associated_forms = this.state.company.forms
      ? false
      : this.state.company.associated_forms
      ? true
      : false;

    let form = null;

    if (this.state.associated_form) {
      form = this.state.company.associated_forms
        ? this.state.company.associated_forms.filter(
            (f) => f._id === this.state.selected_form
          )[0]
        : [];
    } else {
      form = this.state.company.forms
        ? this.state.company.forms.filter(
            (f) => f._id === this.state.selected_form
          )[0]
        : [];
    }

    let form_fields =
      form && form.fields
        ? form.fields.filter((item) => {
            if (item.archived) {
              if (this.state.show_archived) {
                return item;
              }
            } else {
              return item;
            }
          })
        : null;

    return this.state.selected_form ? (
      <Segment basic>
        <Button
          icon="arrow left"
          content="Select Form"
          onClick={() => {
            this.setState({
              selected_form: null,
              associated_form: null,
            });
          }}
        />
        <Button.Group style={{ paddingLeft: "15%", float: "right" }}>
          <Button
            content={`Show Archived`}
            positive={this.state.show_archived === true}
            onClick={() =>
              this.setState(
                {
                  show_archived: true,
                },
                () => {
                  this.getData();
                }
              )
            }
          />
          <Button
            content={`Hide Archived`}
            negative={this.state.show_archived === false}
            onClick={() =>
              this.setState(
                {
                  show_archived: false,
                },
                () => {
                  this.getData();
                }
              )
            }
          />
        </Button.Group>
        <Header as={"h4"}>
          {!associated_forms &&
            this.state.selected_form !== this.state.associated_form && (
              <>
                <Input
                  label="Title"
                  value={this.state.data.form_name}
                  onChange={(event) => {
                    this.setState({
                      data: {
                        ...this.state.data,
                        form_name: event.target.value,
                      },
                    });
                  }}
                />{" "}
                <Button
                  content="Update Title"
                  color="orange"
                  icon="pencil"
                  onClick={() => {
                    api.forms
                      .editCompanyForm(
                        this.props.match.params.id,
                        this.state.selected_form,
                        {
                          name: this.state.data.form_name,
                        }
                      )
                      .then((res) => {
                        this.getData();
                        toast({
                          type: "success",
                          icon: "pencil",
                          title: <h3>Updated Title</h3>,
                          description: (
                            <span>
                              <p>Successfully updated Form Title</p>
                            </span>
                          ),
                          time: 4000,
                        });
                      });
                  }}
                />
                <Button
                  content={`${
                    this.state.data.client_submission ? "Employee" : "Clinician"
                  } Submission`}
                  // color={!this.state.data.archived_form ? "red" : "orange"}
                  icon="clipboard"
                  onClick={() => {
                    api.forms
                      .editCompanyForm(
                        this.props.match.params.id,
                        this.state.selected_form,
                        {
                          client_submission: !this.state.data.client_submission,
                        }
                      )
                      .then((res) => {
                        this.setState({
                          data: {
                            ...this.state.data,
                            client_submission:
                              !this.state.data.client_submission,
                          },
                        });
                        this.getData();
                        toast({
                          type: "success",
                          icon: "clipboard",
                          title: <h3>Form Employee Submission Type Update</h3>,
                          description: (
                            <span>
                              <p>Successfully Updated Form</p>
                            </span>
                          ),
                          time: 4000,
                        });
                      });
                  }}
                />
                <Button
                  content={
                    !this.state.data.archived_form
                      ? "Archive Form"
                      : "UnArchive Form"
                  }
                  color={!this.state.data.archived_form ? "red" : "orange"}
                  icon="trash"
                  onClick={() => {
                    api.forms
                      .editCompanyForm(
                        this.props.match.params.id,
                        this.state.selected_form,
                        {
                          archived: !this.state.data.archived_form,
                        }
                      )
                      .then((res) => {
                        this.setState({
                          selected_form: null,
                          associated_form: null,
                          data: {},
                        });
                        this.getData();
                        toast({
                          type: "success",
                          icon: "trash",
                          title: (
                            <h3>
                              {!this.state.data.archived_form
                                ? "Archive"
                                : "UnArchive"}{" "}
                              Form
                            </h3>
                          ),
                          description: (
                            <span>
                              <p>
                                Successfully{" "}
                                {!this.state.data.archived_form
                                  ? "Archived"
                                  : "UnArchived"}{" "}
                                Form
                              </p>
                            </span>
                          ),
                          time: 4000,
                        });
                      });
                  }}
                />
                {this.state.data.archived_form && (
                  <>
                    <Button
                      color="red"
                      icon="trash"
                      content="Remove Form"
                      onClick={() =>
                        this.setState({ remove_form_confirm: true })
                      }
                    />
                    <Confirm
                      open={this.state.remove_form_confirm}
                      confirmButton={"Remove Form"}
                      header={`Remove Form - ${this.state.data.form_name}`}
                      content={
                        <Segment basic textAlign={"center"}>
                          <h4 style={{ color: "red" }}>
                            Warning: Removing this form will completely remove
                            all data on this form from the system. Once removed,
                            this cannot be reversed and all data on this form
                            will be lost.
                          </h4>
                        </Segment>
                      }
                      onCancel={() => {
                        this.setState({ remove_form_confirm: false });
                      }}
                      onConfirm={() => {
                        api.forms
                          .removeCompanyForm(
                            this.props.match.params.id,
                            this.state.selected_form
                          )
                          .then((res) => {
                            this.setState({
                              remove_form_confirm: false,
                              data: {},
                              selected_form: null,
                              associated_form: null,
                            });
                            this.getData();
                            toast({
                              type: "success",
                              icon: "pencil",
                              title: <h3>Removed Form</h3>,
                              description: (
                                <span>
                                  <p>
                                    Successfully removed Form &amp; Form Fields
                                  </p>
                                </span>
                              ),
                              time: 4000,
                            });
                          });
                      }}
                    />
                  </>
                )}
              </>
            )}

          {this.state.selected_form == this.state.associated_form &&
            this.state.data.form_name}

          {!associated_forms &&
            this.state.selected_form !== this.state.associated_form && (
              <div style={{ paddingLeft: "15%", float: "right" }}>
                <Form.Dropdown
                  label="Submission Notify Email: "
                  name={"email_notify"}
                  value={this.state.data.email_notify}
                  placeholder="Select Email Template"
                  onChange={(e, d) => {
                    api.forms
                      .editCompanyForm(
                        this.props.match.params.id,
                        this.state.selected_form,
                        {
                          [d.name]: d.value,
                        }
                      )
                      .then((res) => {
                        this.getData();
                        this.setState({
                          data: {
                            ...this.state.data,
                            [d.name]: d.value,
                          }
                        });
                        toast({
                          type: "success",
                          icon: "pencil",
                          title: <h3>Updated Email Notify</h3>,
                          description: (
                            <span>
                              <p>
                                Successfully updated Form Submission Email
                                Notification
                              </p>
                            </span>
                          ),
                          time: 4000,
                        });
                      });
                  }}
                  options={this.state.company.emails.map((email) => {
                    return {
                      text: email.template_name,
                      value: email._id,
                      key: email._id,
                    };
                  })}
                  selection
                  clearable
                />
              </div>
            )}
        </Header>
        <Header as={"h4"} content="Fields" />
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Order</Table.HeaderCell>
              <Table.HeaderCell>Title</Table.HeaderCell>
              <Table.HeaderCell>Form Field Name</Table.HeaderCell>
              <Table.HeaderCell>Form Type</Table.HeaderCell>
              <Table.HeaderCell>Extra Data</Table.HeaderCell>
              <Table.HeaderCell>Required Field</Table.HeaderCell>
              {!associated_forms &&
                this.state.selected_form !== this.state.associated_form && (
                  <Table.HeaderCell>
                    {form && (
                      <Button
                        positive
                        icon="plus"
                        content="Add"
                        onClick={() => {
                          let d = form_fields && form_fields[form_fields.length - 1];
                          this.setState({
                            addFieldModal: true,
                            editField: false,
                            data: {
                              order_number: d && d.order_number + 1
                            },
                            audit_points: {},
                            options: [],
                            headers: [],
                          })
                        }
                      }
                          
                      />
                    )}
                  </Table.HeaderCell>
                )}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {form && form_fields ? (
              form_fields.sort((a, b) => {
                return a.order_number - b.order_number;
              }).map((field, i) => {
                return (
                  <Table.Row
                    // <DraggableTableRow
                    key={i}
                    i={i}
                    // action={this.swap.bind(this)}
                  >
                    <Table.Cell>
                      {field.order_number}{" "}
                      <Button.Group vertical>
                        <Button
                          disabled={i == 0}
                          icon="angle up"
                          onClick={() => this.handlePositionChange(field, "up")}
                        />
                        <Button
                          disabled={i + 1 == form_fields.length }
                          icon="angle down"
                          onClick={() => this.handlePositionChange(field, "down")}
                        />
                      </Button.Group>
                      {/*  
                      <Button.Group vertical>
                        <Button
                          icon="angle up"
                          onClick={() => {
                            if (field.order_number !== 1) {
                              this.handlePositionChange(i, "up");
                            }
                          }}
                        />
                        <Button
                          icon="angle down"
                          onClick={() => {
                            if (field.order_number < form_fields.length) {
                              this.handlePositionChange(i, "down");
                            }
                          }}
                        />
                      </Button.Group>*/}
                    </Table.Cell>
                    <Table.Cell>{field.title}</Table.Cell>
                    <Table.Cell>{field.field_name}</Table.Cell>
                    <Table.Cell>{field.type && field.type.name}</Table.Cell>
                    <Table.Cell>
                      {field.archived && (
                        <Label content="Archived" color="red" />
                      )}
                      {field.type &&
                        field.type.name == "OptionSelect" &&
                        field.options &&
                        field.options.map((o) => <Label content={o} />)}
                      {field.type &&
                        (field.type.name == "AuditSelect" ||
                          field.type.name == "AuditTotal") &&
                        field.audit_group && (
                          <Label
                            color="blue"
                            content="Group"
                            detail={field.audit_group}
                          />
                        )}
                      {field.type &&
                        field.type.name == "AuditSelect" &&
                        field.options &&
                        field.options.map((o, index) => {
                          let audit_point =
                            field.audit_points &&
                            field.audit_points[0][`option_${index}_points`];

                          return <Label content={o} detail={audit_point} />;
                        })}
                    </Table.Cell>
                    {field.type && field.type.name !== "HTMLHeader" ? (
                      <Table.Cell>
                        {field.required ? (
                          <Icon name="check" />
                        ) : (
                          <Icon name="times" />
                        )}
                      </Table.Cell>
                    ) : (
                      <Table.Cell />
                    )}
                    {!associated_forms &&
                      this.state.selected_form !==
                        this.state.associated_form && (
                        <Table.Cell>
                          <Button
                            color="orange"
                            icon="pencil"
                            content="Edit"
                            onClick={() =>
                              this.setState({
                                addFieldModal: true,
                                editField: true,
                                editFieldID: field._id,
                                data: {
                                  required: field.required,
                                  archived: field.archived,
                                  order_number: field.order_number,
                                  title: field.title,
                                  field_name: field.field_name,
                                  type: field.type._id,
                                  audit_group: field.audit_group,
                                  audit_points:
                                    field.audit_points && field.audit_points[0],
                                  options: field.options,
                                  headers: field.headers,
                                  client_submission:
                                    this.state.data.client_submission,
                                },
                                audit_points:
                                  field.audit_points && field.audit_points[0],
                                options:
                                  field.options &&
                                  field.options.map((item) => {
                                    return { text: item, value: item };
                                  }),
                                headers:
                                  field.headers &&
                                  field.headers.map((item) => {
                                    return { text: item, value: item };
                                  }),
                              })
                            }
                          />
                        </Table.Cell>
                      )}
                    {/* </DraggableTableRow> */}
                  </Table.Row>
                );
              })
            ) : (
              <Table.Row>
                <Table.Cell colspan="7">No Entires</Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </Segment>
    ) : (
      <Segment basic>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Form</Table.HeaderCell>
              <Table.HeaderCell>Fields</Table.HeaderCell>
              <Table.HeaderCell>Submission By</Table.HeaderCell>
              <Table.HeaderCell>Created At</Table.HeaderCell>
              <Table.HeaderCell>Updated At</Table.HeaderCell>
              <Table.HeaderCell>
                <Button.Group style={{ paddingLeft: "15%", float: "right" }}>
                  <Button
                    content={`Show Archived`}
                    positive={this.state.show_archived_forms === true}
                    onClick={() =>
                      this.setState(
                        {
                          show_archived_forms: true,
                        },
                        () => {
                          this.getData();
                        }
                      )
                    }
                  />
                  <Button
                    content={`Hide Archived`}
                    negative={this.state.show_archived_forms === false}
                    onClick={() =>
                      this.setState(
                        {
                          show_archived_forms: false,
                        },
                        () => {
                          this.getData();
                        }
                      )
                    }
                  />
                </Button.Group>
              </Table.HeaderCell>
            </Table.Row>
            <Table.Row>
              <Table.HeaderCell>
                <Input
                  name="form_name"
                  placeholder="Enter form name"
                  value={this.state.form_create}
                  onChange={(event) => {
                    this.setState({
                      form_create: event.target.value,
                      // event.target.value !== "c1h" ? event.target.value : "",
                    });
                  }}
                />
                <Button
                  disabled={!this.state.form_create}
                  content="Add Form"
                  color="green"
                  icon="plus"
                  onClick={() => {
                    api.forms
                      .addCompanyForm(this.props.match.params.id, {
                        name: this.state.form_create,
                      })
                      .then((res) => {
                        this.getData();
                        toast({
                          type: "success",
                          icon: "plus",
                          title: <h3>Form Added</h3>,
                          description: (
                            <span>
                              <p>Successfully added Form</p>
                            </span>
                          ),
                          time: 4000,
                        });
                      });
                  }}
                />
              </Table.HeaderCell>
              <Table.HeaderCell colSpan="5" />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {this.state.company.forms &&
              this.state.company.forms
                .filter((f) => {
                  if (f.archived) {
                    if (this.state.show_archived_forms) {
                      return f;
                    }
                  } else {
                    return f;
                  }
                })
                .map((form) => {
                  console.log(
                    "🚀 ~ file: company.js ~ line 1050 ~ CompanyPage ~ {this.state.company.forms.map ~ form",
                    form
                  );
                  return (
                    <Table.Row>
                      <Table.Cell>
                        {form.name}{" "}
                        {form.archived && (
                          <Label content="Archived" color="red" />
                        )}
                      </Table.Cell>
                      <Table.Cell>{form.fields.length} fields</Table.Cell>
                      <Table.Cell>
                        {form.client_submission ? "Employee" : "Clinician"}
                      </Table.Cell>
                      <Table.Cell>
                        {moment(form.createdAt).format("DD/MM/YYYY HH:mm")}
                      </Table.Cell>
                      <Table.Cell>
                        {moment(form.updatedAt).format("DD/MM/YYYY HH:mm")}
                      </Table.Cell>
                      <Table.Cell>
                        <Button
                          content="Select"
                          color="blue"
                          icon="clipboard"
                          onClick={() => {
                            this.setState({
                              selected_form: form._id,
                              associated_form: null,
                              data: {
                                ...this.state.data,
                                form_name: form.name,
                                email_notify: form.email_notify,
                                archived_form: form.archived,
                                client_submission: form.client_submission,
                              },
                            });
                          }}
                        />
                        <Button
                          content="Preview Form"
                          color="blue"
                          icon="magnify"
                          onClick={() => {
                            this.setState({
                              view_form: form._id,
                              view_associatedform: "",
                              view_form_title: form.name,
                            });
                          }}
                        />
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
          </Table.Body>
        </Table>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Associated forms</Table.HeaderCell>
              <Table.HeaderCell>Fields</Table.HeaderCell>
              <Table.HeaderCell>Submission By</Table.HeaderCell>
              <Table.HeaderCell>Created At</Table.HeaderCell>
              <Table.HeaderCell>Updated At</Table.HeaderCell>
              <Table.HeaderCell>
                <Button.Group style={{ paddingLeft: "15%", float: "right" }}>
                  {/*  
                  <Button
                    content={`Show Archived`}
                    positive={this.state.show_archived_forms === true}
                    onClick={() =>
                      this.setState(
                        {
                          show_archived_forms: true,
                        },
                        () => {
                          this.getData();
                        }
                      )
                    }
                  />
                  <Button
                    content={`Hide Archived`}
                    negative={this.state.show_archived_forms === false}
                    onClick={() =>
                      this.setState(
                        {
                          show_archived_forms: false,
                        },
                        () => {
                          this.getData();
                        }
                      )
                    }
                  /> */}
                </Button.Group>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {this.state.company.associated_forms &&
              this.state.company.associated_forms.map((form) => {
                console.log(
                  "🚀 ~ file: company.js ~ line 1050 ~ CompanyPage ~ {this.state.company.forms.map ~ form",
                  form
                );
                return (
                  <Table.Row>
                    <Table.Cell>
                      {form.name}{" "}
                      {form.archived && (
                        <Label content="Archived" color="red" />
                      )}
                    </Table.Cell>
                    <Table.Cell>{form.fields.length} fields</Table.Cell>
                    <Table.Cell>
                      {form.client_submission ? "Employee" : "Clinician"}
                    </Table.Cell>
                    <Table.Cell>
                      {moment(form.createdAt).format("DD/MM/YYYY HH:mm")}
                    </Table.Cell>
                    <Table.Cell>
                      {moment(form.updatedAt).format("DD/MM/YYYY HH:mm")}
                    </Table.Cell>
                    <Table.Cell>
                      {/*  */}
                      <Button
                        content="Select"
                        color="blue"
                        icon="clipboard"
                        onClick={() => {
                          this.setState({
                            selected_form: form._id,
                            associated_form: form._id,
                            data: {
                              ...this.state.data,
                              form_name: form.name,
                              archived_form: form.archived,
                            },
                          });
                        }}
                      />
                      <Button
                        content="Preview Form"
                        color="blue"
                        icon="magnify"
                        onClick={() => {
                          this.setState({
                            view_associatedform: form._id,
                            view_form: "",
                            view_form_title: form.name,
                          });
                        }}
                      />
                    </Table.Cell>
                  </Table.Row>
                );
              })}
          </Table.Body>
        </Table>
      </Segment>
    );
  }


  handlePositionChange = (item, direction) => {

    if(item && direction){

      // this.setState({ refreshList: true });
        if (direction === "up" && item.order_number !== 1) {
          api.forms
            .adjustOrder(item._id, { adjust_direction: "up" })
            .then(() => {
              this.getData();
            })
            .catch((e) => {
              console.log(e);
            });
        } else if (direction === "down") {
          api.forms
            .adjustOrder(item._id, { adjust_direction: "down" })
            .then(() => {
              this.getData();
            })
            .catch((e) => {
              console.log(e);
            });
        }

    }

  };

  handleAppStepPositionChange = (item, direction) => {
    if(item && direction){
      // this.setState({ refreshList: true });
        if (direction === "up" && item.order_number !== 1) {
          api.company
          .editCompanyAPPStep(
              {id: item._id,adjust_direction: "up"  },
              this.props.match.params.id
            )
            .then(() => {
              this.getData();
            })
            .catch((e) => {
              console.log(e);
            });
        } else if (direction === "down") {
          api.company
          .editCompanyAPPStep(
              { id: item._id,adjust_direction: "down"  },
              this.props.match.params.id
            )
            .then(() => {
              this.getData();
            })
            .catch((e) => {
              console.log(e);
            });
        }

    }

  };

  renderAddFieldModal() {
    let form = this.state.company.forms
      ? this.state.company.forms.filter(
          (f) => f._id === this.state.selected_form
        )[0]
      : null;
    const closeModal = () => {
      this.setState({ addFieldModal: false, errors: {} });
    };

    let typeName = this.state.field_types.filter(
      (e) => e.value == this.state.data.type
    );

    return (
      <Modal
        open={this.state.addFieldModal}
        onClose={closeModal}
        centered={false}
      >
        <Modal.Header>
          {this.state.editField ? "Edit" : "Add"} Form Field{" "}
          {this.state.data.archived && "(Archived)"}
        </Modal.Header>
        <Modal.Content>
          <Segment textAlign="center">
            <Grid>
              <Grid.Row columns={2}>
                <Grid.Column>
                  <Input
                    style={{ width: "100%" }}
                    label="Title"
                    name="title"
                    onChange={this.handleChange}
                    value={this.state.data.title}
                    error={this.state.errors.title}
                    disabled={this.state.data.archived}
                  />
                  {this.state.errors.title !== undefined ? (
                    <Message
                      negative
                      icon="warning"
                      header={`${this.state.errors.title}`}
                    />
                  ) : (
                    <React.Fragment />
                  )}
                </Grid.Column>
                <Grid.Column>
                  <Input
                    label="Field Name"
                    name="field_name"
                    style={{ width: "100%" }}
                    onChange={this.handleChange}
                    value={this.state.data.field_name}
                    error={this.state.errors.field_name}
                    disabled={this.state.data.archived}
                  />
                  {this.state.errors.field_name !== undefined ? (
                    <Message
                      negative
                      icon="warning"
                      header={`${this.state.errors.field_name}`}
                    />
                  ) : (
                    <React.Fragment />
                  )}
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={2}>
                <Grid.Column>
                  <Input
                    label="Order Number"
                    name="order_number"
                    type="Number"
                    onChange={this.handleChange}
                    value={this.state.data.order_number}
                    disabled={true}
                  />
                </Grid.Column>
                <Grid.Column>
                  <Checkbox
                    label="Required Field"
                    name="required"
                    checked={this.state.data.required}
                    disabled={this.state.data.archived}
                    onChange={() => {
                      this.setState({
                        data: {
                          ...this.state.data,
                          required: !this.state.data.required,
                        },
                      });
                    }}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <Header as={"h4"} content="Type" />
                  <Select
                    name="type"
                    options={this.state.field_types}
                    value={this.state.data.type}
                    disabled={this.state.data.archived}
                    onChange={(e, data) => {
                      this.setState({
                        data: { ...this.state.data, [data.name]: data.value },
                      });
                    }}
                    error={this.state.errors.type}
                  />
                  {this.state.errors.type !== undefined ? (
                    <Message
                      negative
                      icon="warning"
                      header={`${this.state.errors.type}`}
                    />
                  ) : (
                    <React.Fragment />
                  )}
                </Grid.Column>
              </Grid.Row>
              {this.state.data.type &&
                typeName &&
                typeName[0].text == "BloodPressure" && (
                  <Grid.Row>
                    <Grid.Column>
                      <p>
                        <strong>Blood Pressure:</strong> No extra data is
                        required for this field, on the form this field will
                        show two number inputs of Systolic and Diastolic
                        messurements and stored on report data as an Object of
                        both values. <br /> The blood pressure messurements will
                        also be added to the employee's readings.
                      </p>
                    </Grid.Column>
                  </Grid.Row>
                )}
              {this.state.data.type && typeName && typeName[0].text == "BMI" && (
                <Grid.Row>
                  <Grid.Column>
                    <p>
                      <strong>BMI:</strong> No extra data is required for this
                      field, on the form this field will show text of the
                      current height from on Personal Details and an input field
                      for entering weight and calculates the BMI below <br />{" "}
                      The BMI messurement will also be added to the employee's
                      readings.
                    </p>
                  </Grid.Column>
                </Grid.Row>
              )}
              {this.state.data.type &&
                typeName &&
                typeName[0].text == "OptionSelect" && (
                  <Grid.Row>
                    <Grid.Column>
                      <p>
                        <strong>Option Select:</strong> Please enter in all the
                        values that can be selected.
                      </p>
                      <Dropdown
                        options={this.state.options}
                        placeholder="Add Options"
                        name="options"
                        search
                        selection
                        fluid
                        allowAdditions
                        multiple
                        value={this.state.data.options}
                        onAddItem={this.handleAddition}
                        onChange={this.handleDropdownChange}
                        error={this.state.errors.options}
                        disabled={this.state.data.archived}
                      />
                      {this.state.errors.options !== undefined ? (
                        <Message
                          negative
                          icon="warning"
                          header={`${this.state.errors.options}`}
                        />
                      ) : (
                        <React.Fragment />
                      )}
                    </Grid.Column>
                  </Grid.Row>
                )}
              {this.state.data.type &&
                typeName &&
                typeName[0].text == "AuditTotal" && (
                  <Grid.Row>
                    <Grid.Column>
                      <p>
                        <strong>Audit Total:</strong> Please enter in the Audit
                        Group to display totals for.
                      </p>
                      <Input
                        label="Audit Group"
                        name="audit_group"
                        type="Number"
                        onChange={this.handleChange}
                        value={this.state.data.audit_group}
                        error={this.state.errors.audit_group}
                        disabled={this.state.data.archived}
                      />
                      {this.state.errors.audit_group !== undefined ? (
                        <Message
                          negative
                          icon="warning"
                          header={`${this.state.errors.audit_group}`}
                        />
                      ) : (
                        <React.Fragment />
                      )}
                    </Grid.Column>
                  </Grid.Row>
                )}
              {this.state.data.type &&
                typeName &&
                typeName[0].text == "AuditSelect" && (
                  <Grid.Row columns={2}>
                    <Grid.Column>
                      <p>
                        <strong>Audit Options:</strong> Please enter in all the
                        values that can be selected and enter the audit values
                        in the table on the right hand side.
                      </p>
                      <p>
                        Please also enter in the Audit group number, this is
                        used to group all the values together for the AuditTotal
                        Option.
                      </p>
                      <Input
                        label="Audit Group"
                        name="audit_group"
                        type="Number"
                        onChange={this.handleChange}
                        value={this.state.data.audit_group}
                        error={this.state.errors.audit_group}
                        disabled={this.state.data.archived}
                      />
                      {this.state.errors.audit_group !== undefined ? (
                        <Message
                          negative
                          icon="warning"
                          header={`${this.state.errors.audit_group}`}
                        />
                      ) : (
                        <React.Fragment />
                      )}
                      <p>&nbsp;</p>
                      <Dropdown
                        options={this.state.options}
                        placeholder="Add Options"
                        name="options"
                        search
                        selection
                        fluid
                        allowAdditions
                        multiple
                        value={this.state.data.options}
                        onAddItem={this.handleAddition}
                        onChange={this.handleDropdownChange}
                        error={this.state.errors.options}
                        disabled={this.state.data.archived}
                      />
                      {this.state.errors.options !== undefined ? (
                        <Message
                          negative
                          icon="warning"
                          header={`${this.state.errors.options}`}
                        />
                      ) : (
                        <React.Fragment />
                      )}
                    </Grid.Column>
                    <Grid.Column>
                      <Table>
                        <Table.Header>
                          <Table.Row>
                            <Table.HeaderCell>Option</Table.HeaderCell>
                            <Table.HeaderCell>Audit Points</Table.HeaderCell>
                          </Table.Row>
                        </Table.Header>
                        <Table.Body>
                          {this.state.data.options &&
                            this.state.data.options.map((o) => {
                              let index = this.state.data.options.indexOf(o);
                              return (
                                <Table.Row>
                                  <Table.Cell>{o}</Table.Cell>
                                  <Table.Cell>
                                    <Input
                                      value={
                                        this.state.data.audit_points &&
                                        this.state.data.audit_points[
                                          `option_${index}_points`
                                        ]
                                      }
                                      type="Number"
                                      name={`option_${index}_points`}
                                      onChange={this.handlePointsChange}
                                      disabled={this.state.data.archived}
                                      error={
                                        this.state.errors[
                                          `option_${index}_points`
                                        ]
                                      }
                                    />
                                    {this.state.errors[
                                      `option_${index}_points`
                                    ] !== undefined ? (
                                      <Message
                                        negative
                                        icon="warning"
                                        header={`${
                                          this.state.errors[
                                            `option_${index}_points`
                                          ]
                                        }`}
                                      />
                                    ) : (
                                      <React.Fragment />
                                    )}
                                  </Table.Cell>
                                </Table.Row>
                              );
                            })}
                        </Table.Body>
                      </Table>
                    </Grid.Column>
                  </Grid.Row>
                )}
              {this.state.data.type &&
                typeName &&
                typeName[0].text == "TableChecklist" && (
                  <Grid.Row columns={2}>
                    <Grid.Column>
                      <p>
                        <strong>Table Checklist:</strong> Please enter the
                        values of the top headers with score values and the
                        options to pick from.
                      </p>
                      <p>&nbsp;</p>
                      <Dropdown
                        label="Headers"
                        options={this.state.headers}
                        placeholder="Add Headers"
                        name="headers"
                        search
                        selection
                        fluid
                        allowAdditions
                        multiple
                        value={this.state.data.headers}
                        onAddItem={this.handleAddition}
                        onChange={this.handleDropdownChange}
                        error={this.state.errors.headers}
                        disabled={this.state.data.archived}
                      />
                      {this.state.errors.headers !== undefined ? (
                        <Message
                          negative
                          icon="warning"
                          header={`${this.state.errors.headers}`}
                        />
                      ) : (
                        <React.Fragment />
                      )}
                      <p>&nbsp;</p>
                      <Dropdown
                        label="Options"
                        options={this.state.options}
                        placeholder="Add Options"
                        name="options"
                        search
                        selection
                        fluid
                        allowAdditions
                        multiple
                        value={this.state.data.options}
                        onAddItem={this.handleAddition}
                        onChange={this.handleDropdownChange}
                        error={this.state.errors.options}
                        disabled={this.state.data.archived}
                      />
                      {this.state.errors.options !== undefined ? (
                        <Message
                          negative
                          icon="warning"
                          header={`${this.state.errors.options}`}
                        />
                      ) : (
                        <React.Fragment />
                      )}
                    </Grid.Column>
                    <Grid.Column>
                      <Table>
                        <Table.Header>
                          <Table.Row>
                            <Table.HeaderCell>Headers</Table.HeaderCell>
                            <Table.HeaderCell>Score Points</Table.HeaderCell>
                          </Table.Row>
                        </Table.Header>
                        <Table.Body>
                          {this.state.headers &&
                            this.state.data.headers &&
                            this.state.data.headers.map((o) => {
                              let index = this.state.headers
                                .map((e) => e.text)
                                .indexOf(o);
                              return (
                                <Table.Row>
                                  <Table.Cell>{o}</Table.Cell>
                                  <Table.Cell>
                                    <Input
                                      value={
                                        this.state.data.audit_points &&
                                        this.state.data.audit_points[
                                          `option_${index}_points`
                                        ]
                                      }
                                      type="Number"
                                      name={`option_${index}_points`}
                                      onChange={this.handlePointsChange}
                                      disabled={this.state.data.archived}
                                      error={
                                        this.state.errors[
                                          `option_${index}_points`
                                        ]
                                      }
                                    />
                                    {this.state.errors[
                                      `option_${index}_points`
                                    ] !== undefined ? (
                                      <Message
                                        negative
                                        icon="warning"
                                        header={`${
                                          this.state.errors[
                                            `option_${index}_points`
                                          ]
                                        }`}
                                      />
                                    ) : (
                                      <React.Fragment />
                                    )}
                                  </Table.Cell>
                                </Table.Row>
                              );
                            })}
                        </Table.Body>
                      </Table>
                      <Table>
                        <Table.Header>
                          <Table.Row>
                            <Table.HeaderCell>Options</Table.HeaderCell>
                          </Table.Row>
                        </Table.Header>
                        <Table.Body>
                          {this.state.data.options &&
                            this.state.data.options.map((o) => {
                              return (
                                <Table.Row>
                                  <Table.Cell>{o}</Table.Cell>
                                </Table.Row>
                              );
                            })}
                        </Table.Body>
                      </Table>
                    </Grid.Column>
                  </Grid.Row>
                )}
              <Grid.Row>
                <Grid.Column>
                  {this.state.data.archived ? (
                    <>
                      <Button
                        content="Un-Archive"
                        icon="redo"
                        color="orange"
                        onClick={() => {
                          this.archiveFormField(
                            form._id,
                            this.state.editFieldID,
                            false
                          );
                        }}
                      />
                      <Button
                        content="Delete"
                        icon="trash"
                        color="red"
                        onClick={() =>
                          this.deleteArchiveFormField(
                            form._id,
                            this.state.editFieldID
                          )
                        }
                      />
                    </>
                  ) : (
                    <Button
                      content={this.state.editField ? "Edit" : "Add"}
                      icon={this.state.editField ? "pencil" : "plus"}
                      color={this.state.editField ? "orange" : "green"}
                      onClick={() =>
                        this.state.editField
                          ? this.editFormField(form._id, this.state.editFieldID)
                          : this.addFormField(form._id)
                      }
                    />
                  )}
                  {this.state.editField && !this.state.data.archived && (
                    <Button
                      content="Archive"
                      icon="trash"
                      color="red"
                      onClick={() =>
                        this.archiveFormField(
                          form._id,
                          this.state.editFieldID,
                          true
                        )
                      }
                    />
                  )}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
        </Modal.Content>
      </Modal>
    );
  }

  renderHealthJourneySettings() {
    return (
      <Grid stackable className="no-marg">
        <Grid.Row columns="1">
          <Grid.Column>
            <Segment>
              <SettingsListAssessmentStatuses
                editable={
                  this.state.company.company_manager ||
                  !this.state.company.managed_by
                }
                submit={this.handleSubmit}
                delete={this.handleDelete}
                data={
                  this.state.company.assessmentStatuses
                    ? this.state.company.assessmentStatuses
                    : []
                }
                placeholder="Enter Health Journey Status"
                title="Health Journey Statuses"
                field="assessmentStatuses"
              />
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }

  renderTestSettings() {
    return (
      <Grid stackable className="no-marg">
        <Grid.Row columns="2">
          <Grid.Column>
            <Segment>
              <Segment>
                <Header>Test Type</Header>
                <i>Pulled from Circler1 API</i>
                <Divider />
                <Table stackable className={"settingTable"}>
                  <Table.Body>
                    {this.state.company.sampleTypes &&
                    this.state.company.sampleTypes.length > 0 ? (
                      this.state.company.sampleTypes.map((i, index) => (
                        <Table.Row key={"specific_" + index}>
                          <Table.Cell>{i.name}</Table.Cell>
                        </Table.Row>
                      ))
                    ) : (
                      <Table.Row>
                        <Table.Cell>No Test Types</Table.Cell>
                      </Table.Row>
                    )}
                  </Table.Body>
                </Table>
              </Segment>

              {/*  
                <SettingsList
                  submit={this.handleSubmit}
                  delete={this.handleDelete}
                  data={
                    this.state.company.sampleTypes
                      ? this.state.company.sampleTypes
                      : []
                  }
                  placeholder="Enter Specimen Type"
                  title="Specimen Type"
                  field="sampleTypes"
                />*/}
            </Segment>
          </Grid.Column>

          <Grid.Column>
            <Segment>
              <Segment>
                <Header>Specimen Type</Header>
                <i>Pulled from Circler1 API</i>
                <Divider />
                <Table stackable className={"settingTable"}>
                  <Table.Body>
                    {this.state.company.specifics &&
                    this.state.company.specifics.length > 0 ? (
                      this.state.company.specifics.map((i, index) => (
                        <Table.Row key={"specific_" + index}>
                          <Table.Cell>{i.name}</Table.Cell>
                        </Table.Row>
                      ))
                    ) : (
                      <Table.Row>
                        <Table.Cell>No Specimen Types</Table.Cell>
                      </Table.Row>
                    )}
                  </Table.Body>
                </Table>
              </Segment>

              {/*  
                <SettingsList
                  submit={this.handleSubmit}
                  delete={this.handleDelete}
                  data={
                    this.state.company.specifics ? this.state.company.specifics : []
                  }
                  placeholder="Enter Specimen"
                  title="Specimen Type"
                  field="specifics"
                /> */}
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }

  renderEmails() {
    return (
      <Grid stackable className="no-marg">
        <Grid.Row columns="2">
          <Grid.Column>
            <Segment>
              <Segment>
                <Header>Email Templates</Header>
                {this.state.company.managed_by && (
                  <i>Emails: Managed by {this.state.company.managed_by} </i>
                )}
                {(this.state.company.company_manager ||
                  !this.state.company.managed_by) && (
                  <Segment basic>
                    <Form
                      onSubmit={() => {
                        api.emails
                          .addEmail({
                            company: this.props.match.params.id,
                            template_name: this.state.new_template_name,
                          })
                          .then((res) => {
                            this.setState({ new_template_name: "" });
                            this.getData();
                            toast({
                              type: "success",
                              icon: "mail",
                              title: <h3>Company Email Template Added</h3>,
                              description: (
                                <span>
                                  <p>
                                    Successfully added new Email Template for
                                    Company
                                  </p>
                                </span>
                              ),
                              time: 4000,
                            });
                          });
                      }}
                    >
                      <Input
                        action={{ icon: "plus", color: "green" }}
                        onChange={(e) => {
                          this.setState({
                            new_template_name: e.target.value,
                          });
                        }}
                        value={this.state.textvalue}
                        placeholder={this.props.placeholder}
                        fluid
                      />
                    </Form>
                  </Segment>
                )}
                <Divider />
                <Button.Group
                  style={{
                    paddingLeft: "15%",
                    marginBottom: "10px",
                    float: "right",
                  }}
                >
                  <Button
                    content={`Show Archived`}
                    positive={this.state.show_archived_emails === true}
                    onClick={() =>
                      this.setState({
                        show_archived_emails: true,
                      })
                    }
                  />
                  <Button
                    content={`Hide Archived`}
                    negative={this.state.show_archived_emails === false}
                    onClick={() =>
                      this.setState({
                        show_archived_emails: false,
                      })
                    }
                  />
                </Button.Group>
                <Table stackable className={"settingTable"}>
                  <Table.Body>
                    {this.state.company.emails &&
                    this.state.company.emails.length > 0 ? (
                      this.state.company.emails
                        .filter((f) => {
                          if (f.archived) {
                            if (this.state.show_archived_emails) {
                              return f;
                            }
                          } else {
                            return f;
                          }
                        })
                        .map((i, index) => (
                          <Table.Row key={"email_" + index}>
                            <Table.Cell>
                              {i.template_name}{" "}
                              {i.archived && (
                                <Label color="red" content="Archived" />
                              )}
                            </Table.Cell>
                            {(this.state.company.company_manager ||
                              !this.state.company.managed_by) && (
                              <Table.Cell>
                                <Button
                                  color="orange"
                                  icon="pencil"
                                  content="Edit"
                                  onClick={() => {
                                    this.setState({
                                      editEmailTemplateModal: true,
                                      editEmailTemplate: {
                                        ...i,
                                        body_data: i.content
                                          ? this.getHtml(i.content)
                                          : "",
                                        footer_data: i.footer_content
                                          ? this.getHtml(i.footer_content)
                                          : "",
                                      },
                                    });
                                  }}
                                />
                              </Table.Cell>
                            )}
                          </Table.Row>
                        ))
                    ) : (
                      <Table.Row>
                        <Table.Cell>No Email Templates</Table.Cell>
                      </Table.Row>
                    )}
                  </Table.Body>
                </Table>
              </Segment>
            </Segment>
          </Grid.Column>
          <Grid.Column>
            <Segment>
              <Segment>
                <Header>Email Automations</Header>
                {this.state.company.managed_by && (
                  <i>
                    Automations: Managed by {this.state.company.managed_by}{" "}
                  </i>
                )}
                <Divider />
                <Table stackable className={"settingTable"}>
                  <Table.Body>
                    {this.state.company.automations &&
                    this.state.company.automations.length > 0 ? (
                      this.state.company.automations.map((i, index) => (
                        <Table.Row key={"automations_" + index}>
                          <Table.Cell>
                            {i.code} -{" "}
                            {i.template
                              ? i.template.template_name
                              : "NO TEMPLATE SELECTED"}
                          </Table.Cell>
                          {this.state.company.company_manager && (
                            <Table.Cell>
                              <Form.Dropdown
                                // name={`automations_${i.code}_template`}
                                name={i.code}
                                value={
                                  this.state.automation_templates[i.code]
                                    ? this.state.automation_templates[i.code]
                                    : i.template
                                    ? i.template._id
                                    : ""
                                }
                                placeholder="Select Email Template"
                                onChange={(e, data) => {
                                  this.setState({
                                    automation_templates: {
                                      ...this.state.automation_templates,
                                      [data.name]: data.value,
                                    },
                                  });
                                }}
                                options={this.state.company.emails
                                  .filter((e) => !e.archived)
                                  .map((e) => {
                                    return {
                                      text: e.template_name,
                                      value: e._id,
                                      key: e._id,
                                    };
                                  })}
                                selection
                              />
                            </Table.Cell>
                          )}
                        </Table.Row>
                      ))
                    ) : (
                      <Table.Row>
                        <Table.Cell>No Automations</Table.Cell>
                      </Table.Row>
                    )}
                  </Table.Body>
                  {this.state.company.company_manager && (
                    <Table.Footer>
                      <Table.Row>
                        <Table.Cell>
                          <Button
                            color="orange"
                            icon="disk"
                            content="Save"
                            onClick={() => {
                              this.handleSubmit({
                                automations: this.state.automation_templates,
                              });
                            }}
                          />
                        </Table.Cell>
                      </Table.Row>
                    </Table.Footer>
                  )}
                </Table>
              </Segment>
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }

  renderAPPSteps() {
    let associated_steps = this.state.company.associated_steps ? true : false;
    return (
      <Segment basic>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Health Journey Stage</Table.HeaderCell>
              <Table.HeaderCell>Action</Table.HeaderCell>
              <Table.HeaderCell>
                <Button.Group style={{ paddingLeft: "15%", float: "right" }}>
                  <Button
                    content={`Show Archived`}
                    positive={this.state.show_archived_steps === true}
                    onClick={() =>
                      this.setState(
                        {
                          show_archived_steps: true,
                        },
                        () => {
                          this.getData();
                        }
                      )
                    }
                  />
                  <Button
                    content={`Hide Archived`}
                    negative={this.state.show_archived_steps === false}
                    onClick={() =>
                      this.setState(
                        {
                          show_archived_steps: false,
                        },
                        () => {
                          this.getData();
                        }
                      )
                    }
                  />
                </Button.Group>
              </Table.HeaderCell>
            </Table.Row>
            {!associated_steps && (
              <Table.Row>
                <Table.HeaderCell>
                  <Input
                    name="title"
                    label="Stage Title"
                    value={this.state.step_add.title}
                    onChange={(event) => {
                      this.setState({
                        step_add: {
                          ...this.state.step_add,
                          [event.target.name]: event.target.value,
                        },
                      });
                    }}
                  />

                  <Checkbox
                    disabled={!this.state.APPSteps.length > 1}
                    name="locked"
                    label="Locked Stage (Locked till previous Stage)"
                    onClick={() =>
                      this.setState({
                        step_add: {
                          ...this.state.step_add,
                          locked: !this.state.step_add.locked,
                        },
                      })
                    }
                  />
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <Select
                    label="Type"
                    name="type"
                    value={this.state.step_add.type}
                    options={[
                      { text: "Form", value: "form" },
                      // { text: "Url", value: "url" },
                      { text: "Inline Frame", value: "iframe" },
                      { text: "Booking", value: "booking" },
                    ]}
                    onChange={(e, data) => {
                      this.setState({
                        step_add: {
                          ...this.state.step_add,
                          [data.name]: data.value,
                          url: "",
                          form: "",
                        },
                      });
                    }}
                  />
                  {this.state.step_add.type === "url" ||
                    (this.state.step_add.type === "iframe" && (
                      <Input
                        name="url"
                        value={this.state.step_add.url}
                        onChange={(event) => {
                          this.setState({
                            step_add: {
                              ...this.state.step_add,
                              [event.target.name]: event.target.value,
                            },
                          });
                        }}
                      />
                    ))}
                  {this.state.step_add.type === "booking" && 
                    <Select
                      // name="serviceId"
                      name="businessId"
                      options={
                        this.state.bookingServices &&
                        this.state.bookingServices.map((f) => {
                          return { text: f.displayName, value: f.id };
                        })
                      }
                      value={this.state.step_add.businessId}
                      onChange={(e, data) => {
                        this.setState({
                          step_add: {
                            ...this.state.step_add,
                            // serviceId: data.value,
                            businessId: data.value,
                          },
                        });
                      }}
                    />
                  }
                  {this.state.step_add.type === "form" && (
                    <Select
                      label="Form"
                      name="form"
                      options={this.state.company.forms
                        .filter((f) => f.archived !== true)
                        .map((f) => {
                          return { text: f.name, value: f._id };
                        })}
                      value={this.state.step_add.form}
                      onChange={(e, data) => {
                        this.setState({
                          step_add: {
                            ...this.state.step_add,
                            [data.name]: data.value,
                          },
                        });
                      }}
                    />
                  )}
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <Button
                    content="Add Health Journey Stage"
                    color="green"
                    icon="plus"
                    disabled={
                      !this.state.step_add.type ||
                      !this.state.step_add.title ||
                      (this.state.step_add.type == "form" &&
                        !this.state.step_add.form) ||
                      this.state.step_add.type == "url" ||
                      (this.state.step_add.type == "iframe" &&
                        !this.state.step_add.url)
                    }
                    onClick={() => {
                      api.company
                        .addCompanyAPPStep(
                          this.state.step_add,
                          this.props.match.params.id
                        )
                        .then(() => {
                          this.setState({
                            step_add: {
                              locked: false,
                              title: "",
                              type: "",
                              form: "",
                              url: "",
                              businessId: "",
                            },
                          });
                          this.getData();
                          toast({
                            type: "success",
                            icon: "plus",
                            title: <h3>Added Step</h3>,
                            description: (
                              <span>
                                <p>Successfully added Step</p>
                              </span>
                            ),
                            time: 4000,
                          });
                        });
                    }}
                  />
                </Table.HeaderCell>
              </Table.Row>
            )}
          </Table.Header>
          {associated_steps ? (
            <Table.Body>
              {this.state.company.associated_steps &&
              this.state.company.associated_steps.length > 0 ? (
                this.state.company.associated_steps.map((step, index) => {
                  let form = this.state.company.associated_forms
                    ? this.state.company.associated_forms.filter(
                        (f) => f._id === step.form
                      )[0]
                    : null;
                  return (
                    <Table.Row>
                      <Table.Cell>
                        {step.title} {step.locked && <Icon name="lock" />}
                        {step.requires_test && <Icon name="clipboard" />}
                        {step.org_admin && <Icon name="user" />}
                        {step.archived && (
                          <Label content="Archived" color="red" />
                        )}
                      </Table.Cell>
                      <Table.Cell>
                        {step.type}:{" "}
                        {step.type == "url" || step.type == "iframe"
                          ? step.url
                          : step.type == "form" && form
                          ? form.name
                          : ""}{" "}
                      </Table.Cell>
                      <Table.Cell />
                    </Table.Row>
                  );
                })
              ) : (
                <Table.Row>
                  <Table.Cell colSpan="3">No Health Journey Stages</Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          ) : (
            <Table.Body>
              {this.state.APPSteps && this.state.APPSteps.length > 0 ? (
                this.state.APPSteps.filter((f) => {
                  if (f.archived) {
                    if (this.state.show_archived_steps) {
                      return f;
                    }
                  } else {
                    return f;
                  }
                }).sort((a, b) => {
                  return a.order_number - b.order_number;
                }).map((step, index) => {
                  let form = this.state.company.forms
                    ? this.state.company.forms.filter(
                        (f) => f._id === step.form
                      )[0]
                    : null;
                  return (
                    <Table.Row>
                      <Table.Cell>
                      {step.order_number}{" "}
                      <Button.Group vertical>
                        <Button
                          disabled={index == 0}
                          icon="angle up"
                          onClick={() => this.handleAppStepPositionChange(step, "up")}
                        />
                        <Button
                          disabled={index + 1 == this.state.APPSteps.length }
                          icon="angle down"
                          onClick={() => this.handleAppStepPositionChange(step, "down")}
                        />
                      </Button.Group>
                        {step.title} {step.locked && <Icon name="lock" />}
                        {step.requires_test && <Icon name="clipboard" />}
                        {step.org_admin && <Icon name="user" />}
                        {step.archived && (
                          <Label content="Archived" color="red" />
                        )}
                      </Table.Cell>
                      <Table.Cell>
                        {step.type == "form" ? "Form" : step.type == "booking" ? "Booking" : step.type}:{" "}
                        {step.type == "url" || step.type == "iframe"
                          ? step.url
                          : step.type == "form" && form
                          ? form.name
                          : ""}
                        {step.type == "booking" && step.booking_form && step.booking_form.businessId}
                      </Table.Cell>
                      <Table.Cell>
                        <Button
                          content="Edit"
                          color="orange"
                          icon="pencil"
                          onClick={() =>
                            this.setState({
                              editStepModal: true,
                              step_edit: {
                                id: step._id,
                                title: step.title,
                                type: step.type,
                                url: step.url,
                                form: step.form,
                                archived: step.archived,
                                locked: step.locked,
                                requires_test: step.requires_test,
                                org_admin: step.org_admin,
                                // serviceId: step.booking_form ? step.booking_form.serviceId : "",
                                businessId: step.booking_form
                                  ? step.booking_form.businessId
                                  : "",
                              },
                            })
                          }
                        />
                      </Table.Cell>
                    </Table.Row>
                  );
                })
              ) : (
                <Table.Row>
                  <Table.Cell colSpan="3">No Health Journey Stages</Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          )}
        </Table>
      </Segment>
    );
  }

  renderEditStepModal() {
    let form = this.state.company.forms
      ? this.state.company.forms.filter(
          (f) => f._id === this.state.selected_form
        )[0]
      : null;
    const closeModal = () => {
      this.setState({ editStepModal: false, errors: {} });
    };

    return (
      <Modal
        open={this.state.editStepModal}
        onClose={closeModal}
        centered={false}
      >
        <Modal.Header>
          Edit Step
          {this.state.step_edit.archived && "(Archived)"}
        </Modal.Header>
        <Modal.Content>
          <Segment textAlign="center">
            <Grid>
              <Grid.Row columns={2}>
                <Grid.Column>
                  <Input
                    style={{ width: "100%" }}
                    label="Title"
                    name="title"
                    onChange={(event) => {
                      this.setState({
                        step_edit: {
                          ...this.state.step_edit,
                          [event.target.name]: event.target.value,
                        },
                      });
                    }}
                    value={this.state.step_edit.title}
                    error={this.state.errors.title}
                    disabled={this.state.step_edit.archived}
                  />
                  {this.state.errors.title !== undefined ? (
                    <Message
                      negative
                      icon="warning"
                      header={`${this.state.errors.title}`}
                    />
                  ) : (
                    <React.Fragment />
                  )}
                </Grid.Column>
                <Grid.Column>
                  <Select
                    label="Type"
                    name="type"
                    value={this.state.step_edit.type}
                    options={[
                      { text: "Form", value: "form" },
                      { text: "Inline Frame", value: "iframe" },
                      { text: "Booking", value: "booking" },
                    ]}
                    disabled={this.state.step_edit.archived}
                    onChange={(e, data) => {
                      this.setState({
                        step_edit: {
                          ...this.state.step_edit,
                          [data.name]: data.value,
                          url: "",
                          form: "",
                        },
                      });
                    }}
                  />
                  {this.state.errors.field_name !== undefined ? (
                    <Message
                      negative
                      icon="warning"
                      header={`${this.state.errors.field_name}`}
                    />
                  ) : (
                    <React.Fragment />
                  )}
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  {/* {this.state.step_edit.index > 0 && ( */}
                  
                  {/* )} */}
                  <br />
                  {this.state.step_edit.type == "booking" && (
                    <>
                      Microsoft Booking Connection
                      <Select
                        // name="serviceId"
                        name="businessId"
                        options={
                          this.state.bookingServices &&
                          this.state.bookingServices.map((f) => {
                            return { text: f.displayName, value: f.id };
                          })
                        }
                        value={this.state.step_edit.businessId}
                        onChange={(e, data) => {
                          this.setState({
                            step_edit: {
                              ...this.state.step_edit,
                              // serviceId: data.value,
                              businessId: data.value,
                            },
                          });
                        }}
                      />
                      <p>&nbsp;</p>
                    </>
                  )}
                  {(this.state.step_edit.type == "iframe" || this.state.step_edit.type == "booking") && (
                    <Input
                      style={{ width: "100%" }}
                      label={"iFrame Url"}
                      name={"url"}
                      onChange={(event) => {
                        this.setState({
                          step_edit: {
                            ...this.state.step_edit,
                            [event.target.name]: event.target.value,
                          },
                        });
                      }}
                      value={this.state.step_edit.url}
                      error={this.state.errors.title}
                      disabled={this.state.step_edit.archived}
                    />
                  )} 
                  
                  {this.state.step_edit.type == "booking" && <p><br/><b>Note:</b> iFrame is currently replacing the booking modal for the APP screen only! Admin side will need to enter in the details from the booking to save into the system.</p>}

                   {this.state.step_edit.type == "form" && (
                    <>
                    Select form:
                    <Select
                      label="Form"
                      name="form"
                      options={
                        this.state.company.forms &&
                        this.state.company.forms.map((f) => {
                          return { text: f.name, value: f._id };
                        })
                      }
                      value={this.state.step_edit.form}
                      onChange={(e, data) => {
                        this.setState({
                          step_edit: {
                            ...this.state.step_edit,
                            [data.name]: data.value,
                          },
                        });
                      }}
                    />
                    </>
                    
                  )}
                  
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns="equal">
                <Grid.Column>
                  <Checkbox
                      name="locked"
                      label="Locked Stage (Locked till previous Stage)"
                      disabled={this.state.step_edit.archived}
                      checked={this.state.step_edit.locked}
                      onChange={() =>
                        this.setState({
                          step_edit: {
                            ...this.state.step_edit,
                            locked: !this.state.step_edit.locked,
                          },
                        })
                      }
                    />
                </Grid.Column>
                <Grid.Column>
                  <Checkbox
                      name="org_admin"
                      label="Allow Admin Access"
                      disabled={this.state.step_edit.archived}
                      checked={this.state.step_edit.org_admin}
                      onChange={() =>
                        this.setState({
                          step_edit: {
                            ...this.state.step_edit,
                            org_admin: !this.state.step_edit.org_admin,
                          },
                        })
                      }
                    />
                </Grid.Column>
                <Grid.Column>
                  <Checkbox
                      name="requires_test"
                      label="Requires a test result"
                      disabled={this.state.step_edit.archived}
                      checked={this.state.step_edit.requires_test}
                      onChange={() =>
                        this.setState({
                          step_edit: {
                            ...this.state.step_edit,
                            requires_test: !this.state.step_edit.requires_test,
                          },
                        })
                      }
                    />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  {this.state.step_edit.archived ? (
                    <>
                      <Button
                        content="Un-Archive"
                        icon="redo"
                        color="orange"
                        onClick={() => {
                          /*
                          let steps = this.state.company.APPSteps;
                          steps[this.state.step_edit.index].archived = false; */

                          api.company
                            .editCompanyAPPStep(
                              { ...this.state.step_edit, archived: false },
                              this.props.match.params.id
                            )
                            .then(() => {
                              closeModal();
                              this.getData();
                              toast({
                                type: "success",
                                icon: "redo",
                                title: <h3>Step Un-Archived</h3>,
                                description: (
                                  <span>
                                    <p>Successfully un-archived Step</p>
                                  </span>
                                ),
                                time: 4000,
                              });
                            });
                        }}
                      />
                      <Button
                        content="Delete"
                        icon="trash"
                        color="red"
                        onClick={() => {
                          /*
                          let steps = this.state.company.APPSteps;
                          steps[this.state.step_edit.index].remove = true; */
                          api.company
                            .editCompanyAPPStep(
                              this.state.step_edit,
                              this.props.match.params.id
                            )
                            .then(() => {
                              closeModal();
                              this.getData();
                              toast({
                                type: "danger",
                                icon: "trash",
                                title: <h3>Step Removed</h3>,
                                description: (
                                  <span>
                                    <p>Step has been Removed</p>
                                  </span>
                                ),
                                time: 4000,
                              });
                            });
                        }}
                      />
                    </>
                  ) : (
                    <Button
                      content={"Edit"}
                      icon={"pencil"}
                      color={"orange"}
                      onClick={() => {
                        /*let steps = this.state.company.APPSteps;
                        console.log(
                          "🚀 ~ file: company.js ~ line 2622 ~ CompanyPage ~ renderEditStepModal ~ steps",
                          steps
                        );
                        if (
                          steps[this.state.step_edit.index].archived ==
                          undefined
                        ) {
                          steps[this.state.step_edit.index].archived = false;
                        }

                        steps[this.state.step_edit.index] =
                          this.state.step_edit; */

                        api.company
                          .editCompanyAPPStep(
                            this.state.step_edit,
                            this.props.match.params.id
                          )
                          .then(() => {
                            closeModal();
                            toast({
                              type: "success",
                              icon: "pencil",
                              title: <h3>Updated Step</h3>,
                              description: (
                                <span>
                                  <p>Successfully updated Step</p>
                                </span>
                              ),
                              time: 4000,
                            });
                            this.getData();
                          });
                      }}
                    />
                  )}
                  {!this.state.step_edit.archived && (
                    <Button
                      content="Archive"
                      icon="trash"
                      color="red"
                      onClick={() => {
                        /*
                        let steps = this.state.company.APPSteps;
                        steps[this.state.step_edit.index].archived = true; */

                        api.company
                          .editCompanyAPPStep(
                            { ...this.state.step_edit, archived: true },
                            this.props.match.params.id
                          )
                          .then(() => {
                            closeModal();
                            this.getData();
                            toast({
                              type: "success",
                              icon: "pencil",
                              title: <h3>Step Archived</h3>,
                              description: (
                                <span>
                                  <p>Successfully archived Step</p>
                                </span>
                              ),
                              time: 4000,
                            });
                          });
                      }}
                    />
                  )}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
        </Modal.Content>
      </Modal>
    );
  }

  editFormField = (form, field) => {
    const errors = this.validate(this.state.data);

    this.setState({ errors: errors });
    if (Object.keys(errors).length === 0) {
      api.forms.editField(form, field, this.state.data).then(() => {
        this.setState({
          addFieldModal: false,
          editField: false,
          data: {
            order_number: parseInt(this.state.data.order_number) + 1,
            title: "",
            form_field: "",
            type: "",
            required: false,
          },
          options: [],
          errors: {},
        });
        this.getData();
        toast({
          type: "success",
          icon: "pencil",
          title: <h3>Updated Field</h3>,
          description: (
            <span>
              <p>Successfully updated Form Field</p>
            </span>
          ),
          time: 4000,
        });
      });
    }
  };

  archiveFormField = (form, field, archive) => {
    api.forms.editField(form, field, { archived: archive }).then(() => {
      this.setState({
        addFieldModal: false,
        editField: false,
        data: {
          order_number: parseInt(this.state.data.order_number) + 1,
          title: "",
          form_field: "",
          type: "",
          required: false,
        },
        options: [],
      });
      this.getData();
      toast({
        type: "success",
        icon: "trash",
        title: <h3>Archived Field</h3>,
        description: (
          <span>
            <p>Successfully archived Form Field</p>
          </span>
        ),
        time: 4000,
      });
    });
  };

  deleteArchiveFormField = (form, field) => {
    api.forms.removeField(form, field).then(() => {
      this.setState({
        addFieldModal: false,
        editField: false,
        data: {
          order_number: parseInt(this.state.data.order_number) + 1,
          title: "",
          form_field: "",
          type: "",
          required: false,
        },
        options: [],
      });
      this.getData();
      toast({
        type: "danger",
        icon: "trash",
        title: <h3>Archived Field Removed</h3>,
        description: (
          <span>
            <p>Archived Field has been removed</p>
          </span>
        ),
        time: 4000,
      });
    });
  };

  validate = (data) => {
    let typeName = this.state.field_types.filter(
      (e) => e.value == this.state.data.type
    );

    const errors = {};
    if (!data.title || data.title === "")
      errors.title = "Title Can't Be Empty!";

    if (!data.field_name || data.field_name === "")
      errors.field_name = "Field Name Can't Be Empty!";

    if (!data.type || data.type === "") errors.type = "Type Must Be Selected!";

    if (
      data.type &&
      typeName &&
      (typeName[0].text == "OptionSelect" ||
        typeName[0].text == "AuditSelect" ||
        typeName[0].text == "TableChecklist")
    ) {
      if (!data.options || data.options.length === 0) {
        errors.options = "Field Options required!";
      }
    }

    if (data.type && typeName && typeName[0].text == "TableChecklist") {
      if (!data.headers || data.headers.length === 0) {
        errors.headers = "Table Headers required!";
      }

      if (this.state.headers && data.headers) {
        data.headers.map((o) => {
          let index = this.state.headers.map((e) => e.text).indexOf(o);

          if (
            !data.audit_points ||
            !data.audit_points[`option_${index}_points`] ||
            data.audit_points[`option_${index}_points`] == undefined ||
            data.audit_points[`option_${index}_points`] === ""
          ) {
            errors[`option_${index}_points`] = "Required Header Score Point!";
          }
        });
      }
    }
    if (data.type && typeName && typeName[0].text == "AuditSelect") {
      if (this.state.options && data.options) {
        data.options.map((o) => {
          let index = this.state.options.map((e) => e.text).indexOf(o);

          if (
            !data.audit_points ||
            !data.audit_points[`option_${index}_points`] ||
            data.audit_points[`option_${index}_points`] == undefined ||
            data.audit_points[`option_${index}_points`] === ""
          ) {
            errors[`option_${index}_points`] = "Required Option Score Point!";
          }
        });
      }
    }

    if (
      data.type &&
      typeName &&
      (typeName[0].text == "AuditSelect" || typeName[0].text == "AuditTotal")
    ) {
      if (!data.audit_group || data.audit_group === "")
        errors.audit_group = "Audit Group must be entered for Totals!";
    }

    return errors;
  };

  addFormField = (form) => {
    const errors = this.validate(this.state.data);

    this.setState({ errors: errors });
    if (Object.keys(errors).length === 0) {
      api.forms.addField(form, this.state.data).then(() => {
        this.setState({
          addFieldModal: false,
          editField: false,
          data: {
            order_number: parseInt(this.state.data.order_number) + 1,
            title: "",
            form_field: "",
            type: "",
            required: false,
            client_submission: this.state.data.client_submission,
          },
          options: [],
          errors: {},
        });
        this.getData();
        toast({
          type: "success",
          icon: "plus",
          title: <h3>Added Field</h3>,
          description: (
            <span>
              <p>Successfully added Form Field</p>
            </span>
          ),
          time: 4000,
        });
      });
    }
  };

  handleAddition = (e, { value, name }) => {
    let options = this.state[name];

    options.push({ text: value, value });

    this.setState(() => ({
      [name]: options,
    }));
  };

  renderNotice() {
    const handleClose = () => {
      if (this.state.current_notice === null) {
        this.setState({ addNotice: false });
      } else {
        this.setState({ current_notice: null });
      }
    };

    const handleSubmit = (data) => {
      this.setState({ reload: true });
      if (this.state.current_notice === null) {
        api.company.notices
          .add(this.props.match.params.id, data)
          .then(() => {
            this.getData();
            this.setState({
              addNotice: false,
            });
          })
          .finally(() => this.setState({ reload: false }));
      } else {
        api.company.notices
          .edit(this.props.match.params.id, data._id, data)
          .then(() => {
            this.getData();
            this.setState({
              current_notice: null,
            });
          })
          .finally(() => this.setState({ reload: false }));
      }
    };

    return (
      <Modal
        open={this.state.addNotice || this.state.current_notice !== null}
        onClose={handleClose}
        centered={false}
        closeOnDimmerClick={true}
      >
        <Header
          icon="user"
          content={`${
            this.state.current_notice === null ? "Add" : "Edit"
          } Notice`}
        />
        <Modal.Content>
          <NoticeForm
            submit={handleSubmit}
            close={handleClose}
            data={this.state.current_notice}
          />
        </Modal.Content>
      </Modal>
    );
  }

  handlePointsChange = (event) => {
    this.setState({
      data: {
        ...this.state.data,
        audit_points: {
          ...this.state.data.audit_points,
          [event.target.name]: event.target.value,
        },
      },
    });
  };

  swap(a, b) {
    let form = this.state.company.forms
      ? this.state.company.forms.filter(
          (f) => f._id === this.state.selected_form
        )[0]
      : null;

    if (form) {
      let fields = form.fields;

      let tempOrder = fields[b].order_number;
      fields[b].order_number = fields[a].order_number;
      fields[a].order_number = tempOrder;

      fields[a] = fields.splice(b, 1, fields[a])[0];

      this.setState({
        form: {
          ...this.state.form,
          fields,
        },
      });
    }
  }

  handleChange = (event) => {
    let data_blob = event.target.value
      .toLowerCase()
      .replace(/[^a-zA-Z ]/g, "")
      .replace(/[0-9]/g, "")
      .replace(/\s/g, "_")
      .substr(0, 25);

    this.setState({
      data: {
        ...this.state.data,
        [event.target.name]:
          event.target.name === "field_name" ? data_blob : event.target.value,
      },
    });

    if (event.target.name === "title") {
      this.setState({
        data: {
          ...this.state.data,
          title: event.target.value,
          field_name: data_blob,
        },
      });
    }
  };

  getLegalDocs = (pageNum, pageSize, search, filters) => {
    return api.company.legal_docs.allPaged(
      this.state.company.managed_by
        ? this.state.company.managed_by_id
        : this.props.match.params.id,
      {
        pageNum,
        pageSize,
        search,
        filters,
      }
    );
  };

  renderAddDocModal() {
    const handleClose = () => this.setState({ addDoc: false });

    const handleAdd = (data) => {
      this.setState({ refreshList: true });
      api.company.legal_docs
        .add(data, this.props.match.params.id)
        .then(() => {
          this.setState({
            addDoc: false,
            refreshList: false,
          });
          this.getData();
        })
        .catch((e) => {
          this.setState({ refreshList: false });
        });
    };

    return (
      <Modal
        open={this.state.addDoc}
        onClose={handleClose}
        centered={false}
        size="large"
        closeOnDimmerClick={true}
      >
        <Header icon="upload" content="Add System Legal Document" />
        <Modal.Content>
          <AddForm submit={handleAdd} close={handleClose} />
        </Modal.Content>
      </Modal>
    );
  }

  renderPolicies() {
    return (
      <Segment>
        <Header as={"h4"}>Company Legal Document Management</Header>
        {!this.state.company.managed_by && (
          <Segment>
            <Header as={"h2"} textAlign="center" className="pageSubTitle">
              System Document(s)
            </Header>
            {(this.state.current_files.privacy_policy.file !== null ||
              this.state.current_files.terms_and_conditions.file !== null) &&
            this.state.loadingRecent === false ? (
              <Segment basic className="no-vmarg">
                <Card.Group itemsPerRow="2">
                  {this.state.current_files.privacy_policy.file !== null ? (
                    <Card
                      onClick={() => {
                        window.open(
                          AppConfig.api_server +
                            this.state.current_files.privacy_policy.file,
                          "_blank"
                        );
                      }}
                    >
                      <Card.Content>
                        <Card.Header>Privacy Policy</Card.Header>
                        <Card.Meta>Click To Download</Card.Meta>
                        <Card.Description>
                          {this.state.current_files.privacy_policy.name}
                        </Card.Description>
                      </Card.Content>
                    </Card>
                  ) : (
                    <React.Fragment />
                  )}
                  {this.state.current_files.terms_and_conditions.file !==
                  null ? (
                    <Card
                      onClick={() => {
                        window.open(
                          AppConfig.api_server +
                            this.state.current_files.terms_and_conditions.file,
                          "_blank"
                        );
                      }}
                    >
                      <Card.Content>
                        <Card.Header>Terms And Conditions</Card.Header>
                        <Card.Meta>Click To Download</Card.Meta>
                        <Card.Description>
                          {this.state.current_files.terms_and_conditions.name}
                        </Card.Description>
                      </Card.Content>
                    </Card>
                  ) : (
                    <React.Fragment />
                  )}
                </Card.Group>
              </Segment>
            ) : this.state.loadingRecent === true ? (
              <Segment basic padded="very">
                <Loader active />
              </Segment>
            ) : (
              <React.Fragment />
            )}
          </Segment>
        )}
        <Segment>
          <Header as={"h2"} textAlign="center" className="pageSubTitle">
            {this.state.company.managed_by} Company Document(s)
          </Header>
          {((this.state.company.privacy_policy &&
            this.state.company.privacy_policy.file !== null) ||
            (this.state.company.terms_and_conditions &&
              this.state.company.terms_and_conditions.file !== null)) &&
          this.state.loadingRecent === false ? (
            <Segment basic className="no-vmarg">
              <Card.Group itemsPerRow="2">
                {this.state.company.privacy_policy.file !== null ? (
                  <Card
                    onClick={() => {
                      window.open(
                        AppConfig.api_server +
                          this.state.company.privacy_policy.file,
                        "_blank"
                      );
                    }}
                  >
                    <Card.Content>
                      <Card.Header>Privacy Policy</Card.Header>
                      <Card.Meta>Click To Download</Card.Meta>
                      <Card.Description>
                        {this.state.company.privacy_policy.name}
                      </Card.Description>
                    </Card.Content>
                  </Card>
                ) : (
                  <React.Fragment />
                )}
                {this.state.company.terms_and_conditions.file !== null ? (
                  <Card
                    onClick={() => {
                      window.open(
                        AppConfig.api_server +
                          this.state.company.terms_and_conditions.file,
                        "_blank"
                      );
                    }}
                  >
                    <Card.Content>
                      <Card.Header>Terms And Conditions</Card.Header>
                      <Card.Meta>Click To Download</Card.Meta>
                      <Card.Description>
                        {this.state.company.terms_and_conditions.name}
                      </Card.Description>
                    </Card.Content>
                  </Card>
                ) : (
                  <React.Fragment />
                )}
              </Card.Group>
            </Segment>
          ) : this.state.loadingRecent === true ? (
            <Segment basic padded="very">
              <Loader active />
            </Segment>
          ) : (
            <Message>
              <Segment textAlign="center" basic>
                <p>No Company Legal Documents To Display</p>
                <p>
                  <i>Note: The Most Recent Upload(s) Will Be Displayed here</i>
                </p>
              </Segment>
            </Message>
          )}
        </Segment>
        {!this.state.company.managed_by && (
          <Segment>
            <Header as={"h2"} textAlign="center" className="pageSubTitle">
              Previous Upload List
            </Header>
            {this.state.refreshList === false ? (
              <PaginateWrapper
                dataQuery={this.getLegalDocs}
                render={(items) => (
                  <SystemLegalDocsList
                    data={items}
                    add={() => this.setState({ addDoc: true })}
                    allowAdd={true}
                  />
                )}
              />
            ) : (
              <Segment basic>
                <Loader active />
              </Segment>
            )}
          </Segment>
        )}
      </Segment>
    );
  }

  renderFormModal() {
    const close = () => {
      this.setState({
        view_form: "",
        view_associatedform: "",
      });
    };

    let form = null;

    if (this.state.view_associatedform == "") {
      form = this.state.company.forms
        ? this.state.company.forms.filter((f) => f._id === this.state.view_form)
        : null;
    } else {
      form = this.state.company.associated_forms
        ? this.state.company.associated_forms.filter(
            (f) => f._id === this.state.view_associatedform
          )
        : null;
    }

    return (
      <Modal
        dimmer
        open={
          this.state.view_form !== "" || this.state.view_associatedform !== ""
        }
        onClose={close}
        centered={true}
        closeIcon
        size="large"
        closeOnDimmerClick={true}
      >
        <Modal.Header>{this.state.view_form_title}</Modal.Header>
        {form && form[0] ? (
          <Modal.Content>
            <Segment>
              <Form>
                {form[0].fields
                  .filter((e) => !e.archived)
                  .sort((a, b) => {
                    return a.order_number - b.order_number;
                  })
                  .map((field) => {
                    switch (field.type.name) {
                      case "TextField":
                      case "NumberField":
                        return (
                          <Form.Input
                            type={
                              field.type.name == "NumberField"
                                ? "Number"
                                : "Text"
                            }
                            label={field.title}
                            name={field.field_name}
                            required={field.required}
                          />
                        );
                      case "DateField":
                        return (
                          <DateInput
                            label={field.title}
                            name={field.field_name}
                            required={field.required}
                            autoComplete={"off"}
                            animation="off"
                            iconPosition="left"
                            placeholder="Select Date"
                            closable
                            dateFormat={"DD-MM-YYYY"}
                          />
                        );
                      case "HTMLHeader":
                        return <Header as={"h4"} content={field.title} />;
                      case "BloodPressure":
                        return (
                          <React.Fragment>
                            <p>
                              <strong>
                                {field.title} {field.required && "*"}
                              </strong>
                            </p>
                            <Form.Input
                              name={`${field.field_name}_diastolic`}
                              label="Diastolic"
                              required={field.required}
                              type="Number"
                            />
                            <Form.Input
                              name={`${field.field_name}_systolic`}
                              label="Systolic"
                              required={field.required}
                              type="Number"
                            />
                          </React.Fragment>
                        );
                      case "BMI":
                        let height = 0;
                        return (
                          <React.Fragment>
                            <p>
                              <strong>
                                {field.title} {field.required && "*"}
                              </strong>
                            </p>
                            <p>
                              Height(m): <strong>{height}</strong>
                              <br />
                              <i>Note: Height pulled from Personal Details </i>
                            </p>
                            <Form.Input
                              label="Weight(kg)"
                              name={`${field.field_name}_weight`}
                              required={field.required}
                            />
                            <Form.Input
                              label="BMI (generated)"
                              name={`${field.field_name}_bmi`}
                              disabled
                            />
                          </React.Fragment>
                        );
                      case "BMIManual":
                        let theHeight = this.state.form_data ? this.state.form_data[
                          `${field.field_name}_height`
                        ]
                          ? this.state.form_data[`${field.field_name}_height`]
                          : 0 : 0;
                        return (
                          <React.Fragment>
                            <p>
                              <strong>
                                {field.title} {field.required && "*"}
                              </strong>
                            </p>
                            <Form.Input
                              label="Height(m)"
                              name={`${field.field_name}_height`}
                              required={field.required}
                              value={
                                this.state.form_data ? this.state.form_data[
                                  `${field.field_name}_height`
                                ]
                                  ? this.state.form_data[
                                      `${field.field_name}_height`
                                    ]
                                  : "" : ""
                              }
                              error={
                                this.state.form_errors && this.state.form_errors[
                                  `${field.field_name}_height`
                                ]
                              }
                              onChange={(event, { name, value }) => {
                                this.setState({
                                  form_data: {
                                    ...this.state.form_data,
                                    [name]: value,
                                    [`${field.field_name}_bmi`]:
                                      value / (theHeight * theHeight),
                                  },
                                });
                              }}
                            />
                            <Form.Input
                              label="Weight(kg)"
                              name={`${field.field_name}_weight`}
                              required={field.required}
                              value={
                                this.state.form_data ? this.state.form_data[
                                  `${field.field_name}_weight`
                                ]
                                  ? this.state.form_data[
                                      `${field.field_name}_weight`
                                    ]
                                  : "" : ""
                              }
                              error={
                                this.state.form_errors && this.state.form_errors[
                                  `${field.field_name}_weight`
                                ]
                              }
                              onChange={(event, { name, value }) => {
                                this.setState({
                                  form_data: {
                                    ...this.state.form_data,
                                    [name]: value,
                                    [`${field.field_name}_bmi`]:
                                      value / (theHeight * theHeight),
                                  },
                                });
                              }}
                            />
                            <Form.Input
                              label="BMI (generated)"
                              name={`${field.field_name}_bmi`}
                              value={
                                this.state.form_data ? this.state.form_data[`${field.field_name}_bmi`]
                                  ? this.state.form_data[
                                      `${field.field_name}_bmi`
                                    ].toFixed(0)
                                  : this.state.form_data[
                                      `${field.field_name}_weight`
                                    ]
                                  ? (
                                      this.state.form_data[
                                        `${field.field_name}_weight`
                                      ] /
                                      (theHeight * theHeight)
                                    ).toFixed(0)
                                  : "" : ""
                              }
                              disabled
                            />
                          </React.Fragment>
                        );

                      case "OptionSelect":
                        return (
                          <Form.Select
                            label={field.title}
                            name={field.field_name}
                            required={field.required}
                            options={field.options.map((o) => {
                              return { text: o, value: o };
                            })}
                          />
                        );
                      case "AuditSelect":
                        return (
                          <Form.Select
                            label={field.title}
                            required={field.required}
                            options={field.options.map((o, index) => {
                              let audit_point =
                                field.audit_points &&
                                field.audit_points[0][`option_${index}_points`];
                              return {
                                // text: o + " (" + audit_point + ")",
                                text: o,
                                value: o,
                              };
                            })}
                            name={field.field_name}
                          />
                        );
                      case "AuditTotal":
                        return (
                          <React.Fragment>
                            <p>
                              <strong>Total: </strong>
                            </p>
                          </React.Fragment>
                        );
                      case "TableChecklist":
                        return (
                          <React.Fragment>
                            <Table>
                              <Table.Header>
                                <Table.Row>
                                  <Table.HeaderCell>
                                    {field.title}
                                  </Table.HeaderCell>
                                  {field.headers &&
                                    field.headers.map((item, index) => {
                                      let audit_point =
                                        field.audit_points &&
                                        field.audit_points[0][
                                          `option_${index}_points`
                                        ];
                                      return (
                                        <Table.HeaderCell
                                          key={`${field.field_name}_header_${index}`}
                                        >
                                          {/* {item} ({audit_point}) */}
                                          {item}
                                        </Table.HeaderCell>
                                      );
                                    })}
                                </Table.Row>
                              </Table.Header>
                              <Table.Body>
                                {field.options &&
                                  field.options.map((item, index) => {
                                    let data_blob = item
                                      .toLowerCase()
                                      .replace(/[^a-zA-Z ]/g, "")
                                      .replace(/[0-9]/g, "")
                                      .replace(/\s/g, "_")
                                      .substr(0, 25);

                                    return (
                                      <Table.Row
                                        key={`${field.field_name}_row_${index}`}
                                      >
                                        <Table.Cell>{item}</Table.Cell>
                                        {field.headers &&
                                          field.headers.map((header, index) => {
                                            let audit_point =
                                              field.audit_points &&
                                              field.audit_points[0][
                                                `option_${index}_points`
                                              ];
                                            return (
                                              <Table.Cell
                                                key={`${field.field_name}_cell_${index}`}
                                              >
                                                <Radio
                                                  value={header}
                                                  name={data_blob}
                                                />
                                              </Table.Cell>
                                            );
                                          })}
                                      </Table.Row>
                                    );
                                  })}
                              </Table.Body>
                              <Table.Footer>
                                <Table.Row>
                                  <Table.HeaderCell
                                    colSpan={field.headers.length + 1}
                                  >
                                    Total:
                                  </Table.HeaderCell>
                                </Table.Row>
                              </Table.Footer>
                            </Table>
                          </React.Fragment>
                        );
                    }
                  })}
              </Form>

              <br />

              <Button onClick={() => close()}>Close</Button>
            </Segment>
          </Modal.Content>
        ) : (
          <Modal.Content>No Form Found!</Modal.Content>
        )}
        <Modal.Actions></Modal.Actions>
      </Modal>
    );
  }

  render() {
    const { activeItem } = this.state;
    return (
      <Segment basic className="no-pad">
        {this.renderFormModal()}
        {this.renderEmailModal()}
        {this.renderEditStepModal()}
        {this.renderAddFieldModal()}
        {this.renderAddDocModal()}
        <Header as={"h1"} textAlign="center" className="no-marg pageTitle ">
          {this.state.company.name} - Settings
        </Header>
        <Segment basic className="no-hpad">
          {this.renderHeader()}
          <Segment className="no-pad">
            {activeItem === "dash" && this.renderDash()}
            {activeItem === "tests" && this.renderTestSettings()}
            {activeItem === "health_journey" &&
              this.renderHealthJourneySettings()}
            {activeItem === "emails" && this.renderEmails()}
            {activeItem === "app_actions" && this.renderAPPSteps()}
            {activeItem === "health_notices" && this.renderHealthNotices()}
            {activeItem === "resources" && this.renderResources()}
            {activeItem === "branding" && this.renderBrandingTypography()}
            {activeItem === "style_global" && this.renderAppStyling()}
            {activeItem === "resource_category" && this.renderResourceCategories()}
            {activeItem === "app_splash" && this.renderAppSplashScreens()}
            {activeItem === "forms" && this.renderForms()}
            {activeItem === "policies" && this.renderPolicies()}
          </Segment>
          {activeItem === "dash" && this.state.company.company_manager && (
            <Segment>
              <Header as={"h4"}>
                API Sync for Genders, Specimen and Speciment Types
              </Header>
              <p>
                <i>Last Updated: {this.state.c1hApiUpdated}</i>
              </p>
              {this.state.returned && (
                <>
                  <p>
                    <b>
                      Returned API Arrays, Companies have been updated with the
                      following if not already existing
                    </b>
                  </p>
                  <p>Genders: {this.state.genders.join(", ")}</p>
                  <p>Sample Types: {this.state.sampleTypes.join(", ")}</p>
                  <p>Specifics: {this.state.specifics.join(", ")}</p>
                </>
              )}

              <Button
                disabled={this.state.syncing}
                onClick={() => {
                  this.setState({ syncing: true, returned: false });
                  api.settings.syncC1().then((res) => {
                    //todo: pass through the company ID and move to own company so only managed companies from this company are updated
                    this.setState({ syncing: false });
                    if (res.success) {
                      this.setState({
                        genders: res.genders,
                        sampleTypes: res.sampleTypes,
                        specifics: res.specifics,
                        returned: true,
                      });
                      this.getData();
                      toast({
                        type: "success",
                        icon: "refresh",
                        title: <h3>API Sync Successfully</h3>,
                        description: (
                          <span>
                            <p>Successfully updated Company Circular1 Data</p>
                          </span>
                        ),
                        time: 4000,
                      });
                    }
                  });
                }}
              >
                <Icon name="refresh" loading={this.state.syncing} />
                Sync with API
              </Button>
            </Segment>
          )}
        </Segment>
      </Segment>
    );
  }
}
